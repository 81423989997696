import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, color, width = 20, sx, ...other }, ref) => {
  const theme = useTheme();
  return (
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{
        width,
        height: width,
        color: color || 'inherit',
        path: { strokeWidth: 1 },
        ...sx,
      }}
      {...other}
    />
  );
});

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  color: PropTypes.string,
};

export default Iconify;
