import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const mimeType = 'video/webm; codecs="opus,vp8"';

export const useMediaRecorder = ({
  audio,
  onVideoStop,
  liveVideoFeed,
  videoChunks,
  setVideoChunks,
}) => {
  // refs
  const mediaRecorder = useRef(null);

  // states
  const [permission, setPermission] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);

  // functions
  const getMediaPermission = useCallback(async () => {
    setRecordedVideo(null);

    if ('MediaRecorder' in window) {
      try {
        const displayStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            displaySurface: 'monitor',
            frameRate: 12,
          },
          audio: true,
        });
        const userMedia = await navigator.mediaDevices.getUserMedia({
          audio,
        });

        const combinedStream = new MediaStream([
          ...displayStream.getTracks(),
          ...userMedia.getTracks(),
        ]);

        setPermission(true);
        setStream(combinedStream);

        if (liveVideoFeed.current) {
          liveVideoFeed.current.srcObject = combinedStream;
        }
      } catch (err) {
        console.log(err.message);
        setPermission(false);
      }
    } else {
      console.log('The MediaRecorder API is not supported in your browser.');
    }
  }, [audio, liveVideoFeed]);

  const startRecording = useCallback(
    async currentQuestionIndex => {
      if (!stream) {
        console.log('No stream available for recording');
        return;
      }
      setRecordingStatus('recording');
      const media = new MediaRecorder(stream);
      mediaRecorder.current = media;
      mediaRecorder.current.start(1000);

      mediaRecorder.current.ondataavailable = event => {
        if (typeof event.data === 'undefined') return;
        if (event.data.size === 0) return;

        setVideoChunks(prevChunks => [
          ...prevChunks,
          { id: currentQuestionIndex, chunk: event.data },
        ]);
      };
    },
    [setVideoChunks, stream],
  );

  const stopRecording = useCallback(
    (currentQuestionIndex, isPartialSubmit = false) => {
      setPermission(false);
      setRecordingStatus('inactive');
      mediaRecorder.current.stop();

      mediaRecorder.current.onstop = () => {
        const videoChunksWithId = videoChunks.filter(
          chunk => chunk.id === currentQuestionIndex,
        );

        const videoBlob = new Blob(
          videoChunksWithId.map(chunk => chunk.chunk),
          {
            type: mimeType,
          },
        );

        const videoUrl = URL.createObjectURL(videoBlob);
        setRecordedVideo(videoUrl);

        setVideoChunks(prevChunks =>
          prevChunks.filter(chunk => chunk.id !== currentQuestionIndex),
        );

        if(isPartialSubmit) {
          startRecording(currentQuestionIndex);
        }

        onVideoStop(videoBlob, currentQuestionIndex, isPartialSubmit);
      };
    },
    [onVideoStop, setVideoChunks, startRecording, videoChunks],
  );

  return {
    getMediaPermission,
    permission,
    recordingStatus,
    recordedVideo,
    startRecording,
    stopRecording,
  };
};

useMediaRecorder.propTypes = {
  audio: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onVideoStop: PropTypes.func,
  liveVideoFeed: PropTypes.object,
  videoChunks: PropTypes.array,
  setVideoChunks: PropTypes.func,
};
