import { useEffect, useState, useRef } from 'react';

const useWebSocket = (wsUrl) => {
    const [webSocket, setWebSocket] = useState(null);
    const [webSocketStatus, setWebSocketStatus] = useState('DISCONNECTED');
    const [socketMessage, setSocketMessage] = useState(null);
    const [noMessage, setNoMessage] = useState(false);
    const timeoutRef = useRef(null);

    useEffect(() => {
        const openWebSocket = () => {
            if (webSocketStatus === 'DISCONNECTED' && wsUrl) {
                const newWebSocket = new WebSocket(wsUrl);

                newWebSocket.onopen = () => {
                    console.log("web socket connection opened..")
                    setWebSocket(newWebSocket);
                    setWebSocketStatus('CONNECTED');
                    // Timeout to close WebSocket after 60 seconds
                    timeoutRef.current = setTimeout(() => {
                        if (newWebSocket) {
                            if (socketMessage === null) setNoMessage(true);
                            newWebSocket.close();
                        }
                    }, 45000);
                };

                newWebSocket.onclose = () => {
                    console.log("web socket connection closed..")
                    setWebSocket(null);
                    setWebSocketStatus('DISCONNECTED');
                    clearTimeout(timeoutRef.current);
                };

                newWebSocket.onmessage = (event) => {
                    // Incoming messages
                    const lastJsonMessage = JSON.parse(event.data);
                    console.log("last - ", lastJsonMessage)
                    setSocketMessage(lastJsonMessage);
                };
            }
        };

        openWebSocket();

        return () => {
            // Cleanup
            if (webSocketStatus === 'CONNECTED') {
                webSocket.close();
                clearTimeout(timeoutRef.current);
            }
        };
    }, [wsUrl]);

    const sendWebSocketMessage = (message) => {
        if (webSocket && webSocketStatus === 'CONNECTED') {
            webSocket.send(JSON.stringify(message));
        }
    };

    return { socketMessage, noMessage, sendWebSocketMessage };
};

export default useWebSocket;