import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState,useContext } from 'react';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import classes from './styles.module.css';
import ReactPlayer from 'react-player';
import {
  getInterviewDetails,
  postStartInterviewData,
} from 'src/services/interview.service';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_INTERVIEW_LOAD_ERROR,
  EVENT_INTERVIEW_PERMISSION_DENIED,
} from 'src/analytics/trackevents';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingScreen } from 'src/components/loading-screen';
import { setQuestions } from 'src/store/questionSlice';
import { useQuery } from 'src/hooks/use-query';
import Scrollbar from 'src/components/scrollbar';
import { INTERVIEW_PAGE_INSTRUCTION, INTERVIEW_PAGE_PRESCREEN } from './view/interview-view';
import { InterviewContext } from './view/interview-context';

const LaunchPage = ({ setPageState}) => {
  console.log("LaunchPage Component loading...");
  const settings = useSettingsContext();
  var isFirefox = typeof InstallTrigger !== 'undefined';
  const [startBtnDisabled, setStartBntDisabled] = useState(false);
  const [interviewMsg, setInterviewMsg] = useState(
    isFirefox
      ? 'Interview is not compatible with firefox browser, please use Google Chrome for best Experience.'
      : '',
  );
  const [loader, setLoader] = useState(false);
  const {interviewId,enableScreenRecording,prescreeningSkipped}=useContext(InterviewContext);
  console.log("prescreen skip is ",prescreeningSkipped);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // hooks
  const params = useQuery();

  // variables
  const tokenId = params.get('token');

  const postStartInterviewCaller = async() => {
    console.log("start interview caller for Interview ID: ",interviewId)
    postStartInterviewData(interviewId, tokenId)
      .then(data => {
        console.log('questions = ', data);
        setLoader(false);
        if (data.success === true) {
          dispatch(setQuestions(data.body));
          setInterviewMsg('');
          let doScreenRecord = enableScreenRecording?'YES':'NO';
          navigate(
            process.env.REACT_APP_ENVIRONMENT === 'PROD'
              ? `/interview-client/${interviewId}/run2?token=${tokenId}&SR=${doScreenRecord}`
              : `/interview-client/${interviewId}/run2?token=${tokenId}&SR=${doScreenRecord}`,
          );
        } else {
          setLoader(false);
          enqueueSnackbar({
            message: data.message,
            variant: 'info',
          });
          setInterviewMsg(data.message);
          setStartBntDisabled(true);
        }
      })
      .catch(err => {
        setInterviewMsg('Error loading Interview');
        console.log(err);
        enqueueSnackbar({
          message: 'Error loading Interview',
          variant: 'error',
        });
        trackEvent(EVENT_INTERVIEW_LOAD_ERROR, {'error':err.message});
      });
  };

  const handleStartInterview = async () => {
    try {
      setLoader(true);
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
        if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
          navigator.getUserMedia(
            {
              audio: true,
              video: true,
            },
            stream => {
              setStartBntDisabled(false);
              console.log('launch interview');
              console.log('Loading questions');
              setInterviewMsg('');
              postStartInterviewCaller();
            },
            err => {
              console.log('Premissions denied');
              console.log(err);
              setStartBntDisabled(true);
              setInterviewMsg(
                'Pease give permissions for using camera and microphone.',
              );
              setLoader(false);
              trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, {'err':err.message});
            },
          ); 
        }
        else
        {
          navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .then(stream => {
            setStartBntDisabled(false);
            console.log('launch interview');
            console.log('Loading questions');
            setInterviewMsg('');
            postStartInterviewCaller();
          })
          .catch(err => {
            console.log('Premissions denied');
            console.log(err);
            setStartBntDisabled(true);
            setInterviewMsg(
              'Pease give permissions for using camera and microphone.',
            );
            setLoader(false);
            trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, {'err':err.message});
          });
        }
    } catch (error) {
      setLoader(false);
      console.log(error);
      setInterviewMsg(
        'Error launching interview'
      );
      trackEvent(EVENT_INTERVIEW_LOAD_ERROR, {'error':error.message});
    }
  };

  useEffect(() => {
      handleStartInterview(); 
  }, [interviewId]);

  return (
    <>
      {loader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            height: '90%',
          }}
        >
          <div className={classes.interview_getting_ready}>
            <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
              Your interview is getting ready ...
            </h2>
            <LoadingScreen />
          </div>
        </div>
      )}
      {!loader && (
        <Stack sx={{ height: '100%', justifyContent: 'center' }}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
            maxWidth={settings.themeStretch ? false : 'lg'}
          >
            <Stack
              component={Card}
              className={classes.demoVideoDiv}
              spacing={1}
              sx={{ minHeight: '88vh', height: '82vh' }}
            >
              <Scrollbar>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    mb: { xs: 1, md: 1 },
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ mb: '1rem' }}>
                      <Typography variant="h4">AI Interview</Typography>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          if (prescreeningSkipped)
                          { 
                            setPageState(INTERVIEW_PAGE_INSTRUCTION);
                          }
                          else
                          {
                            setPageState(INTERVIEW_PAGE_PRESCREEN);
                          } 
                        }}
                        startIcon={
                          <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                        }
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Stack>
                <Stack
                
                  alignItems="center"
                  sx={{
                    mb: { xs: 1, md: 1 },
                  }}
                >
                
                  <Typography
                    sx={{ fontSize: '1rem', marginTop: '1rem' }}
                    variant="body2"
                  >
                    {interviewMsg}
                  </Typography>
                  <Button
                    type="primary"
                    variant="contained"
                    sx={{ width: '6rem', margin: 'auto', marginTop: '2.5rem' }}
                    disabled={startBtnDisabled === true || isFirefox === true}
                    onClick={()=>handleStartInterview()}
                  >
                    Continue
                  </Button>
                </Stack>
              </Scrollbar>
            </Stack>
          </Container>
        </Stack>
      )}
    </>
  );
};

export default LaunchPage;
