import React from 'react';
import PropTypes from 'prop-types';
import PermissionChecksLayout from './layout';

const CameraPermission = ({ askedPermission, permissionAllowed }) => (
  <PermissionChecksLayout
    description="Camera permission is required to record your video responses."
    title="Camera"
    icon="tabler:video"
    success={permissionAllowed}
    isLoading={!askedPermission}
  />
);

export default CameraPermission;

CameraPermission.propTypes = {
  askedPermission: PropTypes.bool,
  permissionAllowed: PropTypes.bool,
};
