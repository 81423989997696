import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getLinkDetails } from 'src/services/role.service';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_SHORTLINK_LOAD_ERROR } from 'src/analytics/trackevents';
import Page500 from '../500';

const RedirectPublicPage = () => {
  // hooks
  const params = useParams();
  const navigate = useNavigate();

  // states
  const [page500, setPage500] = useState(false);

  // effects
  useEffect(() => {
    const caller = async () => {
      try {
        const res = await getLinkDetails(params.id);
        if (res.success) {
          const navigateUrl = res.body.redirect_link;
          navigate(navigateUrl);
        } else {
          trackEvent(EVENT_SHORTLINK_LOAD_ERROR, {});
          setPage500(true);
        }
      } catch (error) {
        trackEvent(EVENT_SHORTLINK_LOAD_ERROR, { error: error.message });
        setPage500(true);
      }
    };
    if (params) caller();
  }, [navigate, params]);

  return <>{page500 && <Page500 />}</>;
};

export default RedirectPublicPage;
