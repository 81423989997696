import React, { useEffect, useRef } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_PERMISSION_PAGE_START } from 'src/analytics/trackevents';
import Image from 'src/components/image';
import CameraPermission from '../permission-checks/camera-permission';
import MicrophonePermissions from '../permission-checks/microphone-permission';
import useCameraMicrophone from '../hooks/use-camera-mic';
import InterviewHeader from './interview-header';
import { TIMER_PAGE } from '../constants';
import ScreenRecordingPermissions from '../permission-checks/screen-recording-permission';
import useCodingInterviewContext from '../hooks/use-coding-interview-context';

const PermissionsPage = ({ companyLogo, setPageState }) => {
  // refs
  const liveVideoFeed = useRef(null);

  // hooks
  const {
    permissionAllowed,
    askedPermission,
    errors: mediaStateErrors,
    setErrors: setMediaStateErrors,
    setAskedPermission,
    setPermissionAllowed,
  } = useCameraMicrophone();

  const { permission: screenRecordingPermission, setLiveVideoFeed } =
    useCodingInterviewContext();

  const mdUp = useResponsive('up', 'md');

  // functions
  const getPermissionErrorText = () => {
    const errorText = [];
    if (!permissionAllowed?.audio) {
      errorText.push('mic');
    }
    if (!permissionAllowed?.video) {
      errorText.push('camera');
    }
    if (!permissionAllowed?.screen) {
      errorText.push('screen');
    }
    if (errorText.length === 1) {
      return errorText[0];
    }
    if (errorText.length === 2) {
      return `${errorText[0]} and ${errorText[1]}`;
    }
    return `${errorText.slice(0, -1).join(', ')} and ${errorText.slice(-1)}`;
  };

  // effects
  useEffect(() => {
    if (screenRecordingPermission !== null) {
      if (screenRecordingPermission) {
        setPermissionAllowed(_allowed => ({
          ..._allowed,
          screen: screenRecordingPermission,
        }));
      } else {
        setPermissionAllowed(_allowed => ({
          ..._allowed,
          screen: screenRecordingPermission,
        }));
        setMediaStateErrors(_errors => ({
          ..._errors,
          screen: true,
        }));
      }

      setAskedPermission(_askedPermission => ({
        ..._askedPermission,
        screen: true,
      }));
    }
  }, [
    screenRecordingPermission,
    setAskedPermission,
    setMediaStateErrors,
    setPermissionAllowed,
  ]);

  useEffect(() => {
    if (liveVideoFeed.current) {
      setLiveVideoFeed(liveVideoFeed);
    }
  }, [setLiveVideoFeed]);

  return (
    <>
      <InterviewHeader companyLogo={companyLogo} />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        marginTop={{ xs: '60px', md: '80px' }}
        width="100%"
        height={{ xs: 'calc(100vh - 80px - 68px)', md: 'calc(100vh - 80px)' }}
        maxHeight={{
          xs: 'calc(100vh - 80px - 68px)',
          md: 'calc(100vh - 80px)',
        }}
        spacing="22px"
        paddingX={{
          xs: '16px',
          md: '0px',
        }}
        paddingTop={{
          xs: '24px',
          md: '0px',
        }}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Typography variant="subheading2" display={{ xs: 'flex', md: 'none' }}>
          Permissions
          {/* permissionAllowed = <pre>{JSON.stringify(mediaState, null, 2)}</pre> */}
        </Typography>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'center', md: 'center' }}
          justifyContent={{ xs: 'flex-start', md: 'flex-start' }}
          spacing={{ xs: '12px', md: '22px' }}
          width="100%"
          height="100%"
        >
          <Stack
            sx={{
              minHeight: '260px',
              overflow: 'hidden',
              width: { xs: `100%`, md: '50%' },
              alignItems: 'center',
              justifyContent: 'center',
              height: { xs: '50%', md: '100%' },
              position: 'relative',
              borderRadius: { xs: '14.2px', md: '0px' },
              bgcolor: '#00000033',
            }}
          >
            {Object.values(permissionAllowed).every(
              permission => permission === false,
            ) && (
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing="8px"
                alignItems={{ xs: 'start', md: 'baseline' }}
                position="absolute"
                top="20px"
                left="10%"
              >
                <Image
                  src={
                    mdUp
                      ? '/assets/icons/interview-page/site-settings-icon.svg'
                      : '/assets/icons/interview-page/site-settings-icon-mobile.svg'
                  }
                  alt="site-settings-icon"
                  sx={{
                    height: { xs: '140px', md: '140px' },
                  }}
                />
                <Stack
                  py="12px"
                  px="20px"
                  borderRadius="8px"
                  width={{
                    xs: '247px',
                    md: '303px',
                  }}
                  color={{ xs: 'content.accent1', md: 'content.primaryWhite' }}
                  bgcolor={{
                    xs: 'background.primaryAccent2',
                    md: 'background.primaryAccent1',
                  }}
                >
                  <Typography variant="paragraph">
                    Click here to allow
                  </Typography>
                  <Typography variant="paragraph">
                    &quot;Camera&quot; and &quot;MIcrophone&quot;
                  </Typography>
                </Stack>
              </Stack>
            )}
            {Object.values(permissionAllowed).every(
              permission => permission === false,
            ) && (
              <Iconify color="#A0A0A0" icon="tabler:user-filled" width={200} />
            )}

            <video
              ref={liveVideoFeed}
              autoPlay
              muted
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                backgroundColor: '#FBFBFB',
              }}
            />
          </Stack>
          <Stack
            width={{ xs: '100%', md: '50%' }}
            marginRight={{ md: '20px' }}
            paddingX={{
              xs: '0px',
              md: '20px',
            }}
            paddingTop={{
              xs: '12px',
              md: '0px',
            }}
          >
            <Typography
              variant="h3"
              display={{ xs: 'none', md: 'block' }}
              mb="20px"
            >
              Permissions
            </Typography>
            <Stack spacing={{ xs: '12px', md: '16px' }}>
              <Stack spacing={{ xs: '8px', md: '12px' }}>
                <CameraPermission
                  permissionAllowed={permissionAllowed?.video}
                  askedPermission={askedPermission.video}
                />
                <MicrophonePermissions
                  permissionAllowed={permissionAllowed?.audio}
                  askedPermission={askedPermission.audio}
                />
                <ScreenRecordingPermissions
                  permissionAllowed={permissionAllowed?.screen}
                  askedPermission={askedPermission.screen}
                />
              </Stack>

              <Stack
                direction="row"
                visibility={!mediaStateErrors ? 'hidden' : 'visible'}
                spacing="8px"
                alignItems="center"
                padding={{
                  xs: '13px 8px',
                  md: '18px 12px',
                }}
                bgcolor="background.primaryRed2"
                borderRadius="6px"
                color="content.primaryRed1"
              >
                <Iconify icon="tabler:alert-triangle" />
                <Typography
                  variant={mdUp ? 'paragraph' : 'caption'}
                  fontWeight={{ xs: 400, md: 600 }}
                >
                  Give permission to {getPermissionErrorText()}
                </Typography>
              </Stack>
            </Stack>
            {mdUp && (
              <Stack mt={{ xs: '20px', md: '48px' }}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    setPageState(TIMER_PAGE);
                    trackEvent(EVENT_INTERVIEW_PERMISSION_PAGE_START, {});
                  }}
                  disabled={Object.values(permissionAllowed).some(
                    permission => permission === false,
                  )}
                >
                  Start Interview
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {!mdUp && (
        <Stack
          px="16px"
          pb="20px"
          pt="12px"
          width="100%"
          position="fixed"
          bottom="0"
        >
          <Button
            fullWidth
            size="medium"
            variant="contained"
            onClick={() => {
              setPageState(TIMER_PAGE);
              trackEvent(EVENT_INTERVIEW_PERMISSION_PAGE_START, {});
            }}
            disabled={Object.values(permissionAllowed).some(
              permission => permission === false,
            )}
          >
            Start Interview
          </Button>
        </Stack>
      )}
    </>
  );
};

export default PermissionsPage;

PermissionsPage.propTypes = {
  companyLogo: PropTypes.string.isRequired,
  setPageState: PropTypes.func.isRequired,
  liveVideoFeed: PropTypes.object,
};
