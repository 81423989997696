import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_EXPIRED } from 'src/analytics/trackevents';
import InterviewHeader from './interview-header';

export default function InterviewExpired({
  companyLogo,
  jobRole,
  location,
  company,
}) {
  // hooks
  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    trackEvent(EVENT_INTERVIEW_EXPIRED, {});
  }, [location]);

  return (
    <>
      {companyLogo && <InterviewHeader companyLogo={companyLogo} />}
      <Stack
        height="100vh"
        direction="row"
        alignItems="center"
        justifyContent="center"
        paddingX={{ xs: '15px', md: '40px' }}
        spacing="22px"
        width="100%"
      >
        <Stack
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: '60px',
            width: '50%',
          }}
        >
          <Iconify icon="tabler:calendar-cancel" width={200} />
        </Stack>
        <Stack
          spacing="36px"
          height="100%"
          justifyContent="center"
          width={{
            xs: '100%',
            md: '50%',
          }}
        >
          <Stack spacing="8px">
            <Typography variant="subheading1">{jobRole}</Typography>
            {company && (
              <Stack direction="row" spacing="6px" alignItems="center">
                <Iconify
                  icon="tabler:briefcase-2"
                  color="content.primaryBlack3"
                />
                <Typography
                  variant={mdUp ? 'paragraph' : 'input'}
                  color="content.primaryBlack3"
                >
                  {company}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack spacing="16px">
            <Typography variant={mdUp ? 'h1' : 'h4'}>
              Interview Expired
            </Typography>
            <Typography
              variant="subheading2"
              fontWeight={400}
              color="content.primaryBlack3"
            >
              For further assistance contact us - info@hireintel.ai
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

InterviewExpired.propTypes = {
  companyLogo: PropTypes.string,
  jobRole: PropTypes.string,
  location: PropTypes.string,
  company: PropTypes.string,
};
