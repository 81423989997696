import { detect } from 'detect-browser';
import { useEffect, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';

export default function useOSCheck({ deviceType }) {
  // variables
  const browser = detect();

  // states
  const [osCompatible, setOSCompatible] = useState(false);
  const isOSCompatibilityChecking = useBoolean(true);
  const isOSCompatibilityChecked = useBoolean(false);

  // effects
  useEffect(() => {
    if (!isOSCompatibilityChecked.value) {
      if (deviceType === 'mobile') {
          setOSCompatible(true);
      } else if (deviceType === 'desktop') {
          setOSCompatible(true);
      }
      setOSCompatible(true);
      setTimeout(() => {
        isOSCompatibilityChecking.onFalse();
        isOSCompatibilityChecked.onTrue();
      }, 1000);
    }
  }, [deviceType]);

  return {
    osCompatible,
    setOSCompatible,
    isOSCompatibilityChecking,
    isOSCompatibilityChecked,
  };
}
