import React from 'react';
import PropTypes from 'prop-types';
import CompatibilityCheckLayout from './layout';

export default function OSCheck({ osCompatible, isOSCompatibilityChecking }) {
  return (
    <CompatibilityCheckLayout
      icon="tabler:device-imac"
      title="OS compatibility"
      description="Checking if your operating system is compatible for the interview or not."
      isLoading={isOSCompatibilityChecking}
      success={osCompatible}
      errorMessage="We support Mac & Windows or you can try on Android or iOS phone."
    />
  );
}

OSCheck.propTypes = {
  osCompatible: PropTypes.bool,
  isOSCompatibilityChecking: PropTypes.bool,
};
