import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  Button,
  Card,
  Container,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { setQuestions } from 'src/store/questionSlice';
import { LoadingScreen } from 'src/components/loading-screen';
import {
  getInterviewDetails,
  postStartInterviewData,
} from 'src/services/interview.service';
import { useQuery } from 'src/hooks/use-query';
import { useSettingsContext } from 'src/components/settings';
import classes from './styles.module.css';
import { uploadVideoToS3 } from 'src/services/aws.service';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_PERMISSION_DENIED, EVENT_INTERVIEW_START_BUTTON_CLICKED } from 'src/analytics/trackevents';

const StartInterview = ({ interview_id }) => {
  var isFirefox = typeof InstallTrigger !== 'undefined';

  // states
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);
  const [company, setCompany] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [startBtnDisabled, setStartBntDisabled] = useState(false);
  const [interviewMsg, setInterviewMsg] = useState(isFirefox ? 'Interview is not compatible with firefox browser, please use Google Chrome for best Experience.' : '');
  const [companyLogo, setCompanyLogo] = useState('');

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useQuery();

  // variables
  const token_id = params.get('token');

  // context
  const settings = useSettingsContext();

  // functions

  const postStartInterviewCaller = (interview_id, token_id) => {
    postStartInterviewData(interview_id, token_id)
      .then(data => {
        console.log('questions = ', data);
        setLoader(false);
        if (data.success === true) {
          dispatch(setQuestions(data.body));
          setSuccess(true);
          setMessage('');
          navigate(
            process.env.REACT_APP_ENVIRONMENT==="PROD"?`/interview-client/${interview_id}/run2?token=${token_id}`:`/interview-client/${interview_id}/run2?token=${token_id}`,
          );
        } else {
          setLoader(false);
          enqueueSnackbar({
            message: data.message,
            variant: 'info',
          });
          setMessage(data.message);
        }
      })
      .catch(err => {
        setMessage('Error loading Interview');
        console.log(err)
        enqueueSnackbar({
          message: 'Error loading Interview',
          variant: 'error',
        });
      });
  }

  const handleStartInterview = async () => {
    try {
      setLoader(true);

      navigator.getUserMedia = (
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia
      );
      if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {

        navigator.getUserMedia({
          audio: true, video: true
        }, stream => {
          setStartBntDisabled(false);
          console.log('launch interview');
          console.log('Loading questions');
          setMessage('');
          setSuccess(false);
          postStartInterviewCaller(interview_id, token_id);

        }, err => {
          console.log('Premissions denied');
          setStartBntDisabled(true);
          setInterviewMsg(
            'Pease give permissions for using camera and microphone.',
          );
          setLoader(false);
          trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED,{});
        });
      } else {
        navigator.mediaDevices.getUserMedia({
          audio: true, video: true
        }).then(stream => {
          setStartBntDisabled(false);
          console.log('launch interview');
          console.log('Loading questions');
          setMessage('');
          setSuccess(false);
          postStartInterviewCaller(interview_id, token_id);
        }).catch(err => {
          console.log('Premissions denied');
          setStartBntDisabled(true);
          setInterviewMsg(
            'Pease give permissions for using camera and microphone.',
          );
          setLoader(false);
          trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED,{});
        });
      }

    } catch (error) {
      console.log(error);
    }
    trackEvent(EVENT_INTERVIEW_START_BUTTON_CLICKED,{});
  };

  // effects
  useEffect(() => {
    const callerFunc = async () => {
      try {
        const res = await getInterviewDetails(interview_id, token_id);
        console.log(res);
        if (res.success) {
          setCompany(res.body.org_name);
          setCompanyLogo(res.body.org_logo_url);
          setCandidateName(res.body.candidate_name);
          setJobRole(res.body.job_name);
          setLoader(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    callerFunc();
  }, [interview_id, token_id]);

  return (
    <main className={classes.container}>
      <div className={classes.innerContainer}>
        {loader && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              height: '90%',
            }}
          >
            <div className={classes.interview_getting_ready}>
              <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
                Your interview is getting ready ...
              </h2>
              <LoadingScreen />
            </div>
          </div>
        )}
        {!loader && (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            maxWidth={settings.themeStretch ? false : 'lg'}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                left: { md: 24 },
                bottom: { md: 24 },
                zIndex: { md: 10 },
                padding: '2rem',
                mb: '1rem',
              }}
            >
              <Avatar
                src={companyLogo}
                // alt={"Company Logo"}
                sx={{
                  mx: 'auto',
                  width: { xs: 64, md: 128 },
                  height: { xs: 64, md: 128 },
                }}
              />
              <ListItemText
                sx={{
                  mt: 3,
                  ml: { md: 3 },
                  textAlign: { xs: 'center', md: 'unset' },
                }}
                primary={company}
                secondary={jobRole}
                primaryTypographyProps={{
                  typography: 'h3',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  color: 'inherit',
                  component: 'span',
                  // typography: 'body1',
                  sx: { opacity: 0.48, fontWeight: 'bold', fontSize: '1rem' },
                }}
              />
            </Stack>
            <Stack spacing={3} className={classes.instructionsDiv}>
              <Typography variant="h6">Dear candidate,</Typography>

              <Typography variant="h6">
                Please follow the instructions below for taking the interview.
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography varient="h6">
                  1. Click the "Start" button when you're ready to begin the interview.
                </Typography>
                <Typography varient="h6">
                  2. Grant permissions for the camera and microphone to record the video.
                </Typography>
                <Typography varient="h6">
                  3. Allow screen sharing to capture the entire screen during your interview.
                </Typography>
                <Typography varient="h6">
                  4. Click the "Next" button to move to the next interview question.
                </Typography>
                <Typography varient="h6">
                  5. Your responses will be automatically recorded since audio recording is enabled throughout the interview.
                </Typography>
              </div>
            </Stack>
            <Button
              type="primary"
              variant="contained"
              sx={{ width: '6rem', margin: 'auto', marginTop: '2.5rem' }}
              disabled={startBtnDisabled === true || isFirefox === true}
              onClick={handleStartInterview}
            >
              Start
            </Button>
            <Typography
              sx={{ fontSize: '1rem', color: 'red', marginTop: '1rem' }}
              variant="body2"
            >
              {interviewMsg}
            </Typography>
          </Container>
        )}
      </div>
    </main>
  );
};

export default StartInterview;
