import { Stack, Typography, Box } from '@mui/material';
import Iconify from 'src/components/iconify';
import Loader from 'src/components/loader';
import PropTypes from 'prop-types';

export default function CompatibilityCheckLayout({
  title,
  description,
  icon,
  isLoading,
  success,
  errorMessage,
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="background.primaryGrey1"
      minWidth={{ xs: '0px', md: '940px' }}
      spacing="18px"
      padding={{
        xs: '12px 12px',
        md: '54px 40px',
      }}
    >
      <Stack
        direction="row"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        spacing={{
          xs: '8px',
          md: '24px',
        }}
      >
        <Iconify
          icon={icon}
          width={{
            xs: '24px',
            md: '44px',
          }}
        />
        <Stack spacing="4px">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">{description}</Typography>
        </Stack>
      </Stack>
      {isLoading && (
        <Stack direction="row" alignItems="center" spacing="20px">
          <Typography
            display={{
              xs: 'none',
              md: 'block',
            }}
            variant="paragraph"
            color="content.primaryBlack3"
            fontWeight={600}
          >
            Checking
          </Typography>
          <Loader height={24} width={24} />
        </Stack>
      )}
      {!isLoading && (
        <Stack
          direction="row"
          alignItems="center"
          spacing="20px"
          sx={{
            color: theme =>
              theme.palette.background[
                success ? 'primaryGreen1' : 'primaryRed1'
              ],
          }}
        >
          {success ? (
            <Typography
              display={{
                xs: 'none',
                md: 'block',
              }}
              variant="paragraph"
              fontWeight={600}
            >
              Compatible
            </Typography>
          ) : (
            <Box
              sx={{
                padding: '8px',
                bgcolor: 'background.primaryRed2',
                color: 'content.primaryBlack1',
                width: '260px',
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
            >
              {errorMessage && typeof errorMessage === 'string' ? (
                <Typography variant="input">{errorMessage}</Typography>
              ) : (
                errorMessage
              )}
            </Box>
          )}
          <Iconify
            width={24}
            height={24}
            icon={
              success ? 'tabler:circle-check-filled' : 'tabler:circle-x-filled'
            }
          />
        </Stack>
      )}
    </Stack>
  );
}

CompatibilityCheckLayout.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
