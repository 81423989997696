import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import Image from '../image';
import SvgColor from '../svg-color';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 75,
        height: 75,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        version="1"
        viewBox="0 0 128 128"
      >
        <path
          d="M638 1124c-37-20-58-63-58-120 0-39 5-51 33-79 39-39 72-50 121-41 74 14 122 101 95 173-28 72-122 105-191 67zM540 827c-19-9-26-22-28-50-3-37-3-37 23-25 21 9 39 8 96-7 68-17 73-17 149 0 74 17 80 17 104 1 26-16 26-16 26 14 0 19-9 40-25 55-23 24-28 25-172 25-99 0-156-4-173-13zM532 718c-7-7-12-19-12-29 0-15 4-15 48-2 79 25 79 25 52 33-39 12-75 11-88-2z"
          transform="matrix(.1 0 0 -.1 0 128)"
        ></path>
        <path
          d="M688 691c-75-21-144-43-152-51-31-25-13-80 26-80 20 0 272 66 306 80 25 11 31 57 10 78-17 17-38 15-190-27zM850 603c-19-6-43-12-52-12-34-2-18-19 23-26 44-8 74 9 67 36-2 12-9 12-38 2zM635 545c-22-8-51-14-65-14-20-1-29-10-44-47-49-119-214-135-288-28-19 28-19 28-13 4 24-86 89-164 171-205 51-25 71-29 144-30 73 0 93 4 141 28 95 46 166 139 183 240 6 34 5 37-16 37-13 0-45 7-72 15-58 18-87 18-141 0z"
          transform="matrix(.1 0 0 -.1 0 128)"
        ></path>
        <text
          style={{ whiteSpace: 'pre' }}
          x="86.311"
          y="111.89"
          fill="#333"
          fontFamily="Arial, sans-serif"
          fontSize="13"
          fontWeight="700"
        >
          BETA
        </text>
        <text
          style={{ whiteSpace: 'pre' }}
          x="135.802"
          y="97.87"
          fill="#333"
          fontFamily="Arial, sans-serif"
          fontSize="5.1"
        ></text>
        <path
          fill="#D8D8D8"
          fillOpacity="0.8"
          stroke="#000"
          d="M84.945 100.557H122.323V113.71000000000001H84.945z"
          opacity="0.2"
        ></path>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}
      >
        <SvgColor sx={{ width: '100%', height: '100%' }} src="/logo/logo.svg" />
      </Box>
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
