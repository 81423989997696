import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFUpload } from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
import { createInterviewLinkPublic, getPublicJobDetails } from 'src/services/role.service';
import classes from "../styles.module.css";
import * as Yup from 'yup';
import { useCallback } from 'react';
import { uploadResumeToS3 } from 'src/services/aws.service';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import ResumeParsingFailed from '../resume-parsing-failed';
import { Icon } from '@iconify/react';


const InterviewScreeningView = ({ orgId, jobId }) => {
    const [loader, setLoader] = useState(true);
    const [company, setCompany] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [jobDescription, setJobDescription] = useState("");
    const [jobPublished, setJobPublished] = useState(false);
    const [eligible, setEligible] = useState(null);
    const [interviewUrl, setInterviewUrl] = useState("");
    const [open, setOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [resumeUrl, setResumeUrl] = useState("");
    const [resumeBlob, setResumeBlob] = useState(null);



    const settings = useSettingsContext();
    const navigate = useNavigate();

    const Schema = Yup.object().shape({
        coverUrl: Yup.mixed().nullable().required('Resume is required'),
    });

    const defaultValues = useMemo(
        () => ({
            coverUrl: null,
        }),
        []
    );

    const methods = useForm({
        resolver: yupResolver(Schema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = methods;

    const values = watch();

    const onSubmit = handleSubmit(async (data, event) => {
        console.log(data);
        try {
            setLoader(true);
            const fileName = resumeBlob?.name;
            const fileExtension = fileName.split('.').pop();
            const resumeUrl = await uploadResumeToS3(data.coverUrl, resumeBlob?.type, fileExtension);
            console.log(resumeUrl);
            if (resumeUrl && resumeUrl.length>0) {
                const payload = {
                    resume_url: resumeUrl,
                    "disable_screening": true
                }
                const res = await createInterviewLinkPublic(payload, jobId);
                console.log(res);
                setResumeUrl(resumeUrl);

                if (res?.success) {
                    setLoader(false);
                    if (res?.body?.interview_url) {
                        console.log(process.env.REACT_APP_INTERVIEW_CLIENT_URL + res.body.interview_url);
                        window.location.href = process.env.REACT_APP_INTERVIEW_CLIENT_URL + res.body.interview_url
                        setEligible("eligible");
                        setInterviewUrl(process.env.REACT_APP_INTERVIEW_CLIENT_URL + res.body.interview_url);
                    }
                }
                else {
                    setLoader(false);
                    setEligible("ineligible");
                }
            }
            else{
                enqueueSnackbar({message:"Error while uploading file", variant:"error"});
            }

        } catch (error) {
            console.error(error);
        }
    });

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            setResumeBlob(file);
            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('coverUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleRemoveFile = useCallback(() => {
        setValue('coverUrl', null);
    }, [setValue]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopyClick = (publicUrl) => {
        navigator.clipboard.writeText(publicUrl).then(
            () => {
                enqueueSnackbar('URL copied to clipboard');
                setCopySuccess(true);

                setTimeout(() => {
                    setCopySuccess(false);
                }, 2000);
            },
            () => {
                enqueueSnackbar({ message: 'Failed to copy URL', variant: "error" });
            },
        );
    };



    useEffect(() => {
        const callerFunc = async () => {
            try {
                const res = await getPublicJobDetails(orgId, jobId);
                console.log(res);
                if (res.success) {
                    setCompany(res.body.organization_details.name);
                    setCompanyLogo(res.body.organization_details.logo_url);
                    setJobRole(res.body.job_opening_details.name);
                    setJobDescription(res.body.job_opening_details.job_description);
                    setJobPublished(res.body.job_opening_details.public_apply_link_enabled);
                    setLoader(false);
                }
            } catch (error) {
                console.log(error);
            }
        };
        callerFunc();
    }, []);

    useEffect(() => {

    }, [eligible]);

    return (
        <>
            {/* <main className={classes.container}>
            <div className={classes.innerContainer}> */}
            {loader && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        alignItems: 'center',
                        height: '90%',
                    }}
                >
                    <div style={{ width: "60vw" }}>
                        <LoadingScreen />
                    </div>
                </div>
            )}
            {!loader && (
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minHeight: "100vh"
                    }}
                    maxWidth={settings.themeStretch ? false : 'lg'}
                >
                    <Stack justifyContent="space-between" alignItems="center" sx={{ width: "100%", height: "100vh" }}>
                        <Stack alignItems="center" sx={{ width: "100%", }}>
                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Take later</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        You can access the interview through the link sent to your email, or you can copy the interview link from here and take the assessment at your convenience.
                                    </DialogContentText>
                                    <Stack flexDirection="row" spacing="1rem" sx={{ justifyContent: "center", gap: "1rem", marginTop: "1.5rem" }}>
                                        <Stack sx={{ border: "2px solid grey", color: "#0a2ff1", margin: "auto 0px", borderRadius: "2px", padding: "0px 10px", width: { xs: "12rem", md: "100%" }, overflow: { xs: "hidden", md: "" } }}>{interviewUrl.slice(0, 50)}...</Stack>
                                        <Button
                                            sx={{ padding: "0px", color: "white", minWidth: "3rem", width: "3rem", height: "30px" }}
                                            variant="contained"
                                            disabled={copySuccess}
                                            onClick={() =>
                                                handleCopyClick(interviewUrl)
                                            }
                                        >
                                            {copySuccess ? 'Copied' : 'Copy'}
                                        </Button>
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Stack sx={{ margin: "0px auto" }}>
                                        <Button sx={{ minWidth: "84px", marginTop: "1.8rem" }} variant="contained" onClick={handleClose}>Close</Button>
                                    </Stack>
                                </DialogActions>
                            </Dialog>

                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                sx={{
                                    left: { md: 24 },
                                    bottom: { md: 24 },
                                    zIndex: { md: 10 },
                                    padding: '2rem',
                                    mb: '1rem',
                                }}
                            >
                                <Avatar
                                    src={companyLogo}
                                    // alt={"Company Logo"}
                                    sx={{
                                        mx: 'auto',
                                        width: { xs: 64, md: 128 },
                                        height: { xs: 64, md: 128 },
                                    }}
                                />
                                <ListItemText
                                    sx={{
                                        mt: 3,
                                        ml: { md: 3 },
                                        textAlign: { xs: 'center', md: 'unset' },
                                    }}
                                    primary={company}
                                    secondary={jobRole}
                                    primaryTypographyProps={{
                                        typography: 'h3',
                                    }}
                                    secondaryTypographyProps={{
                                        mt: 0.5,
                                        color: 'inherit',
                                        component: 'span',
                                        // typography: 'body1',
                                        sx: { opacity: 0.48, fontWeight: 'bold', fontSize: '1rem' },
                                    }}
                                />
                            </Stack>
                            {!jobPublished && (
                                <Stack sx={{ height: "55vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <Typography variant="h4">The job is no longer available...</Typography>
                                </Stack>
                            )}
                            {jobPublished && (
                                <>
                                    {eligible === null && (
                                        <>
                                            <Stack sx={{ width: "100%" }}>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <Typography variant="h6">Job Description</Typography>
                                                    <Button sx={{ minWidth: "4rem" }} variant="contained" onClick={() => { window.scrollTo(0, document.body.scrollHeight) }}>Start</Button>
                                                </Stack>
                                                <Typography sx={{ display: "inline" }} varient="h6"
                                                    dangerouslySetInnerHTML={{ __html: jobDescription }}
                                                >
                                                </Typography>
                                            </Stack>
                                            <Stack sx={{ width: "80%", marginTop: "5rem" }}>
                                                <FormProvider methods={methods} onSubmit={onSubmit}>
                                                    <Stack>
                                                        <RHFUpload
                                                            name="coverUrl"
                                                            maxSize={5000000}
                                                            multiple={false}
                                                            onDrop={handleDrop}
                                                            onDelete={handleRemoveFile}
                                                            accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], 'application/officedocument.wordprocessingml.document': ['.docx'] }}
                                                        />
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{ width: '6rem', margin: 'auto', marginTop: '2.5rem', marginBottom: "2.5rem" }}
                                                        >
                                                            Continue
                                                        </Button>
                                                    </Stack>
                                                </FormProvider>
                                            </Stack>
                                        </>
                                    )}
                                    {eligible === "eligible" && (
                                        {/* <Stack alignItems="center" justifyContent="center" spacing={{ md: 5, xs: 0 }} sx={{ width: "100%", marginTop: { md: "2rem", xs: 0 } }}>
                                            <Typography variant="h4">Assessment Round</Typography>
                                            <Typography sx={{ marginTop: "1rem", fontSize: "1.1rem", textAlign: { xs: "center", md: "" } }} variant="body1">Before proceeding to the interview stage, you must first successfully complete an assessment test, as it is a prerequisite for qualification.</Typography>
                                            <Stack direction="row" sx={{ margin: "auto", gap: { md: "4rem", xs: "1rem" } }}>
                                                <Button
                                                    variant="outlined"
                                                    sx={{ border: "1.6px solid grey", marginTop: "8rem", width: "8rem" }}
                                                    onClick={handleClickOpen}
                                                >
                                                    Take Later
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{ marginTop: "8rem", width: "8rem" }}
                                                    onClick={() => {
                                                        window.location.href = interviewUrl;
                                                    }}
                                                >
                                                    Take Now
                                                </Button>
                                            </Stack>
                                        </Stack> */}
                                    )}
                                    {eligible === "ineligible" && (
                                        <ResumeParsingFailed setInterviewUrl={setInterviewUrl} jobId={jobId} setEligible={setEligible} resumeUrl={resumeUrl} />
                                    )}
                                </>
                            )}
                        </Stack>
                        <Stack alignItems="center" justifyContent="center">
                            <Typography sx={{ textAlign: "center", padding: "0.5rem", fontSize: "0.8rem" }} variant="p">© Copyright {new Date().getFullYear()} <a href="https://hireintel.ai">HireIntel</a>. All rights reserved.</Typography>
                        </Stack>
                    </Stack>
                </Container>
            )}
            {/* </div>
        </main > */}
        </>
    )
}

export default InterviewScreeningView