export function link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  };
}
