import { Container, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import Jobheader from '../job-header';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop:"5rem",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "0.5rem",
        minHeight: "86vh",
        maxWidth:"800px",
        [theme.breakpoints.down("sm")]:{
            marginTop:"2rem"
        }
    },
    mainDiv: {
        minHeight:"88vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        gap: "3rem"
    },
    children: {
        minHeight:"55vh",
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginTop: "2rem",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0rem",
            minHeight:"65vh"
        }
    },
}))

const Layout = ({ companyLogo, children }) => {
    const classes = useStyles();
    return (
        <>
            <Jobheader companyLogo={companyLogo} />
            <Container className={classes.root}>
                <Stack className={classes.mainDiv}>
                    <Stack className={classes.children}>
                        {children}
                    </Stack>
                    {/* <Stack className={classes.copyrightDiv}>
                        <Typography className={classes.copyright} variant="p">© Copyright {new Date().getFullYear()} <a href="https://hireintel.ai">HireIntel</a>. All rights reserved.</Typography>
                    </Stack> */}
                </Stack>
            </Container>
        </>
    )
}

export default Layout;