import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import {
  partialSubmitCodingInterview,
  submitCodingInterview,
} from 'src/services/coding-interview.service';
import { uploadVideoToS3 } from 'src/services/aws.service';
import CodingInterviewContext from './coding-interview-context';
import { useMediaRecorder } from '../hooks/use-media-recorder';
import { CONGRATULATIONS_PAGE, JOB_DETAILS_PAGE } from '../constants';

const initialState = {
  codingInterviewId: '',
  tokenId: '',
  code: '',
  questions: [],
  language: '',
  languages: [],
  currentQuestionIndex: 0,
  isSubmitting: false,
  timeTakenSeconds: 0,
  screenRecordingUrl: '',
  screenRecordings: [],
  liveVideoFeed: null,
  pageState: JOB_DETAILS_PAGE,
  permissionAllowed: {
    audio: null,
    video: null,
    screen: null,
  },
  permissionErrors: null,
  askedPermission: {
    audio: false,
    video: false,
    screen: false,
  },
};

const reducer = (state, action) => {
  if (action.type === 'SET_CODING_INTERVIEW_ID') {
    return {
      ...state,
      codingInterviewId: action.payload,
    };
  }
  if (action.type === 'SET_PAGE_STATE') {
    return {
      ...state,
      pageState: action.payload,
    };
  }
  if (action.type === 'SET_TOKEN_ID') {
    return {
      ...state,
      tokenId: action.payload,
    };
  }
  if (action.type === 'SET_CODE') {
    return {
      ...state,
      code: action.payload,
    };
  }
  if (action.type === 'SET_QUESTIONS') {
    return {
      ...state,
      questions: action.payload,
    };
  }
  if (action.type === 'SET_IS_SUBMITTING') {
    return {
      ...state,
      isSubmitting: action.payload,
    };
  }
  if (action.type === 'SET_CURRENT_QUESTION_INDEX') {
    return {
      ...state,
      currentQuestionIndex: action.payload,
    };
  }
  if (action.type === 'SET_TIME_TAKEN_SECONDS') {
    return {
      ...state,
      timeTakenSeconds: action.payload,
    };
  }
  if (action.type === 'SET_LANGUAGE') {
    return {
      ...state,
      language: action.payload,
    };
  }
  if (action.type === 'SET_SCREEN_RECORDING_URL') {
    return {
      ...state,
      screenRecordingUrl: action.payload,
    };
  }
  if (action.type === 'SET_SCREEN_RECORDINGS') {
    return {
      ...state,
      screenRecordings: action.payload,
    };
  }
  if (action.type === 'ADD_SCREEN_RECORDING') {
    return {
      ...state,
      screenRecordings: [...state.screenRecordings, action.payload],
    };
  }
  if (action.type === 'SET_LANGUAGES') {
    return {
      ...state,
      languages: action.payload,
    };
  }
  if (action.type === 'SET_LIVE_VIDEO_FEED') {
    return {
      ...state,
      liveVideoFeed: action.payload,
    };
  }
  if (action.type === 'RESET') {
    return initialState;
  }
  if (action.type === 'SET_PERMISSION_ALLOWED') {
    return {
      ...state,
      permissionAllowed: action.payload,
    };
  }
  if (action.type === 'SET_PERMISSION_ERRORS') {
    return {
      ...state,
      permissionErrors: action.payload,
    };
  }
  if (action.type === 'SET_ASKED_PERMISSION') {
    return {
      ...state,
      askedPermission: action.payload,
    };
  }

  return state;
};

const CodingInterviewProvider = ({ children }) => {
  // state
  const [state, dispatch] = useReducer(reducer, initialState);
  const [videoChunks, setVideoChunks] = useState([]);

  // reducer functions
  const setCodingInterviewId = useCallback(id => {
    dispatch({ type: 'SET_CODING_INTERVIEW_ID', payload: id });
  }, []);

  const setTokenId = useCallback(id => {
    dispatch({ type: 'SET_TOKEN_ID', payload: id });
  }, []);

  const setPageState = useCallback(pageState => {
    dispatch({ type: 'SET_PAGE_STATE', payload: pageState });
  }, []);

  const setCode = useCallback(code => {
    dispatch({ type: 'SET_CODE', payload: code });
  }, []);

  const setQuestions = useCallback(questions => {
    dispatch({ type: 'SET_QUESTIONS', payload: questions });
  }, []);

  const setIsSubmitting = useCallback(isSubmitting => {
    dispatch({ type: 'SET_IS_SUBMITTING', payload: isSubmitting });
  }, []);

  const setCurrentQuestionIndex = useCallback(index => {
    dispatch({ type: 'SET_CURRENT_QUESTION_INDEX', payload: index });
  }, []);

  const setTimeTakenSeconds = useCallback(seconds => {
    dispatch({ type: 'SET_TIME_TAKEN_SECONDS', payload: seconds });
  }, []);

  const setLanguage = useCallback(language => {
    dispatch({ type: 'SET_LANGUAGE', payload: language });
  }, []);

  const setScreenRecordingUrl = useCallback(url => {
    dispatch({ type: 'SET_SCREEN_RECORDING_URL', payload: url });
  }, []);

  const setScreenRecordings = useCallback(recordings => {
    dispatch({ type: 'SET_SCREEN_RECORDINGS', payload: recordings });
  }, []);

  const addScreenRecording = useCallback(recording => {
    dispatch({ type: 'ADD_SCREEN_RECORDING', payload: recording });
  }, []);

  const setLanguages = useCallback(languages => {
    dispatch({ type: 'SET_LANGUAGES', payload: languages });
  }, []);

  const setLiveVideoFeed = useCallback(_liveVideoFeed => {
    dispatch({ type: 'SET_LIVE_VIDEO_FEED', payload: _liveVideoFeed });
  }, []);

  const setPermissionAllowed = useCallback(_permissionAllowed => {
    dispatch({ type: 'SET_PERMISSION_ALLOWED', payload: _permissionAllowed });
  }, []);

  const setPermissionErrors = useCallback(_permissionErrors => {
    dispatch({ type: 'SET_PERMISSION_ERRORS', payload: _permissionErrors });
  }, []);

  const setAskedPermission = useCallback(_askedPermission => {
    dispatch({ type: 'SET_ASKED_PERMISSION', payload: _askedPermission });
  }, []);

  const getVideoUrl = useCallback(
    async (videoBlob, questionNumber) => {
      try {
        const folder = `interview/${state.codingInterviewId}/question/${questionNumber}`;
        return uploadVideoToS3(videoBlob, folder);
      } catch (err) {
        console.log(err);
      }

      return null;
    },
    [state.codingInterviewId],
  );

  const onStopVideo = async (
    videoBlob,
    currentQuestionIndex,
    isPartialSubmit,
  ) => {
    try {
      const videoUrl = await getVideoUrl(videoBlob, currentQuestionIndex + 1);

      if (videoUrl) {
        const _screenRecordings = [...state.screenRecordings, videoUrl];

        await endCodingInterview(_screenRecordings, isPartialSubmit);

        setScreenRecordingUrl(videoUrl);
        addScreenRecording(videoUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // hooks
  const { permission, getMediaPermission, startRecording, stopRecording } =
    useMediaRecorder({
      audio: true,
      onVideoStop: async (
        videoBlob,
        _currentQuestionIndex,
        isPartialSubmit,
      ) => {
        onStopVideo(videoBlob, _currentQuestionIndex, isPartialSubmit);
      },
      liveVideoFeed: state.liveVideoFeed,
      videoChunks,
      setVideoChunks,
    });

  // functions
  const onSubmit = useCallback(
    (_currentQuestionIndex, isPartialSubmit = false) => {
      if (!isPartialSubmit) {
        setIsSubmitting(true);
      }
      stopRecording(_currentQuestionIndex, isPartialSubmit);
    },
    [setIsSubmitting, stopRecording],
  );

  const endCodingInterview = useCallback(
    async (screenRecordings, isPartialSubmit = false) => {
      try {
        const payload = {
          question_number: state.currentQuestionIndex + 1,
          coding_solution_data: {
            solution_code: state.code,
            time_taken_seconds: state.timeTakenSeconds,
            coding_language: state.language,
            screen_recordings: screenRecordings,
          },
        };

        let response = null;

        if (isPartialSubmit) {
          response = await partialSubmitCodingInterview(
            state.codingInterviewId,
            state.tokenId,
            payload,
          );
        } else {
          response = await submitCodingInterview(
            state.codingInterviewId,
            state.tokenId,
            payload,
          );
        }

        if (response?.success) {
          console.log(response);
          if (!isPartialSubmit) {
            setPageState(CONGRATULATIONS_PAGE);
          }
        } else throw new Error(response?.message);
      } catch (err) {
        console.log(err);
      } finally {
        setIsSubmitting(false);
        setScreenRecordingUrl('');
      }
    },
    [
      setIsSubmitting,
      setPageState,
      setScreenRecordingUrl,
      state.code,
      state.codingInterviewId,
      state.currentQuestionIndex,
      state.language,
      state.timeTakenSeconds,
      state.tokenId,
    ],
  );

  // effects
  useEffect(() => {
    const timeLimit = state.questions[state.currentQuestionIndex]?.timeLimit;

    if (
      state.timeTakenSeconds > 0 &&
      state.timeTakenSeconds % 60 === 0 &&
      state.timeTakenSeconds < timeLimit * 60
    ) {
      console.log("partial submitting ...at", state.timeTakenSeconds);
      onSubmit(state.currentQuestionIndex, true);
    } else if (state.timeTakenSeconds === timeLimit * 60) {
      onSubmit(state.currentQuestionIndex, false);
    }
  }, [state.currentQuestionIndex, state.questions, state.timeTakenSeconds]);

  // memoized value
  const memoizedValue = useMemo(
    () => ({
      ...state,
      permission,
      getMediaPermission,
      setLiveVideoFeed,
      startRecording,
      stopRecording,
      setCode,
      setCodingInterviewId,
      setTokenId,
      setQuestions,
      setIsSubmitting,
      setCurrentQuestionIndex,
      setTimeTakenSeconds,
      setLanguage,
      setScreenRecordingUrl,
      setScreenRecordings,
      addScreenRecording,
      setLanguages,
      setPageState,
      setPermissionAllowed,
      setPermissionErrors,
      setAskedPermission,
      onSubmit,
    }),
    [
      addScreenRecording,
      getMediaPermission,
      onSubmit,
      permission,
      setAskedPermission,
      setCode,
      setCodingInterviewId,
      setCurrentQuestionIndex,
      setIsSubmitting,
      setLanguage,
      setLanguages,
      setLiveVideoFeed,
      setPageState,
      setPermissionAllowed,
      setPermissionErrors,
      setQuestions,
      setScreenRecordingUrl,
      setScreenRecordings,
      setTimeTakenSeconds,
      setTokenId,
      startRecording,
      state,
      stopRecording,
    ],
  );

  return (
    <CodingInterviewContext.Provider value={memoizedValue}>
      {children}
    </CodingInterviewContext.Provider>
  );
};

export { CodingInterviewProvider };

CodingInterviewProvider.propTypes = {
  children: PropTypes.node,
};
