import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFUpload } from 'src/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import { uploadResumeToS3 } from 'src/services/aws.service';
import { EVENT_APPLY_JOB_RESUME_ERROR, EVENT_APPLY_JOB_RESUME_FILE_DOC, EVENT_APPLY_JOB_RESUME_FILE_DOCX, EVENT_APPLY_JOB_RESUME_FILE_PDF, EVENT_APPLY_JOB_RESUME_SUCCESS, EVENT_APPLY_JOB_UPLOAD_CLICK } from 'src/analytics/trackevents';
import { trackEvent } from 'src/analytics/datatrack';
import { createInterviewLinkPublic } from 'src/services/role.service';
import { JOB_APPLY_PRESCREENING, JOB_APPLY_RESUME_PARSING_FAILED } from 'src/sections/apply-for-job/view/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formInsideDiv: {
        gap: 24
    },
    jobRole: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    jobRoleHeading: {
        textAlign: "center",
        fontWeight: 600
    },
    uploadDiv: {
        gap: 20,
        [theme.breakpoints.down("sm")]: {
            gap: 16
        }
    },
    uploadMainDiv:{
        height:350,
        [theme.breakpoints.down("sm")]:{
            height: "65vh",
            width:"99%"
        }
    },
    button: {
        width: "100%",
        margin: 'auto',
        marginTop: 40,
        marginBottom: "2.5rem",
        // [theme.breakpoints.down("sm")]:{
        //     position:"absolute",
        //     bottom:"2rem",
        //     width:"92vw",
        //     margin:0
        // }
    }
}));

const ResumeUpload = ({ jobRole, jobId, orgId, setLoader, setPageState, setResumeUrl, setClientId, setTokenId, setInterviewUrl, setLoaderMessage, setPageError }) => {
    const classes = useStyles();
    const [resumeBlob, setResumeBlob] = useState(null);

    const Schema = Yup.object().shape({
        coverUrl: Yup.mixed().nullable().required('Resume is required'),
    });

    const defaultValues = useMemo(
        () => ({
            coverUrl: null,
        }),
        []
    );

    const methods = useForm({
        resolver: yupResolver(Schema),
        defaultValues,
    });

    const { watch, setValue, handleSubmit } = methods;
    watch();

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            setResumeBlob(file);
            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
            if (file) setValue('coverUrl', newFile, { shouldValidate: true });
        },
        [setValue]
    );

    const handleRemoveFile = useCallback(() => {
        setValue('coverUrl', null);
    }, [setValue]);

    const onSubmit = handleSubmit(async (data, event) => {
        setLoader(true);
        setLoaderMessage("Uploading...");
        trackEvent(EVENT_APPLY_JOB_UPLOAD_CLICK, { jobId: jobId, orgId: orgId })
        const fileName = resumeBlob?.name;
        const fileExtension = fileName.split('.').pop();
        uploadResumeToS3(data.coverUrl, resumeBlob?.type, fileExtension).then(resumeUrl => {
            console.log(resumeUrl);
            if (resumeUrl && resumeUrl.length > 0) {
                const payload = {
                    resume_url: resumeUrl
                }
                if (resumeUrl.toLowerCase().endsWith(".pdf")) {
                    trackEvent(EVENT_APPLY_JOB_RESUME_FILE_PDF, { jobId: jobId })
                }
                if (resumeUrl.toLowerCase().endsWith(".doc")) {
                    trackEvent(EVENT_APPLY_JOB_RESUME_FILE_DOC, { jobId: jobId })
                }
                if (resumeUrl.toLowerCase().endsWith(".docx")) {
                    trackEvent(EVENT_APPLY_JOB_RESUME_FILE_DOCX, { jobId: jobId })
                }
                createInterviewLinkPublic(payload, jobId).then(res => {
                    console.log(res);
                    setResumeUrl(resumeUrl);
                    if (res.success) {
                        if (res.body.interview_url) {
                            setInterviewUrl(res.body.interview_url);
                        }
                        trackEvent(EVENT_APPLY_JOB_RESUME_SUCCESS, { jobId: jobId })
                        setTokenId(res.body.user_token_id);
                        setClientId(res.body.user_id);
                        setPageState(JOB_APPLY_PRESCREENING);
                    }
                    else {
                        setPageState(JOB_APPLY_RESUME_PARSING_FAILED);
                        trackEvent(EVENT_APPLY_JOB_RESUME_ERROR, { jobId: jobId })
                    }
                    setLoader(false);
                }).catch(err => {
                    setPageError(true);
                    console.log(err);
                })
            }
            else {
                enqueueSnackbar({ message: "Error while uploading file", variant: "error" });
            }
        }).catch(err => {
            setPageError(true);
            console.log(err);
        })
    });

    return (
        <Stack className={classes.root}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack className={classes.formInsideDiv}>
                    <Stack justifyContent="center" alignItems="center" className={classes.jobRole}>
                        <Typography variant="h3" className={classes.jobRoleHeading}>{jobRole}</Typography>
                    </Stack>
                    <Stack>
                        <Stack className={classes.uploadDiv}>
                            <Typography variant="subheading1">Upload Resume</Typography>
                            <Stack className={classes.uploadMainDiv}>
                                <RHFUpload
                                    name="coverUrl"
                                    title="Upload Resume"
                                    maxSize={3000000}
                                    multiple={false}
                                    onDrop={handleDrop}
                                    onDelete={handleRemoveFile}
                                    accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], 'application/officedocument.wordprocessingml.document': ['.docx'] }}
                                />
                            </Stack>
                        </Stack>
                        <Stack>
                            <Button size="large" type="submit" variant="contained" className={classes.button}                            >
                                Continue
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </FormProvider>
        </Stack>
    )
}

export default ResumeUpload