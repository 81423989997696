import PropTypes from 'prop-types';
// @mui
import { Stack, Typography } from '@mui/material';
//
import { useResponsive } from 'src/hooks/use-responsive';
import Image from '../image';
import { fileData } from '../file-thumbnail';

// ----------------------------------------------------------------------

export default function SingleFilePreview({ file }) {
  // hooks
  const downSm = useResponsive('down', 'sm');

  // variables
  const { name } = fileData(file);

  return (
    <Stack alignItems="center" spacing="12px">
      <Image src="/assets/icons/files/ic_file.svg" />
      <Typography
        sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}
        variant={downSm ? 'subheading1' : 'paragraph'}
      >
        {name}
      </Typography>
    </Stack>
  );
}

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
