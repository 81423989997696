import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@mui/material';
import CompatibilityCheckLayout from './layout';

export default function BrowserCheck({
  browserCompatible,
  isBrowserCompatibilityChecking,
}) {
  return (
    <CompatibilityCheckLayout
      icon="tabler:browser"
      title="Browser compatibility"
      description="Checking if your browser is compatible for the interview or not."
      isLoading={isBrowserCompatibilityChecking}
      success={browserCompatible}
      errorMessage={
        <Typography variant="input">
          We recommend Chrome browser for best interview experience.
        </Typography>
      }
    />
  );
}

BrowserCheck.propTypes = {
  browserCompatible: PropTypes.bool,
  isBrowserCompatibilityChecking: PropTypes.bool,
};
