import { Stack } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'src/components/image/image';

export default function InterviewHeader({ companyLogo }) {
  return (
    <Stack
      component="header"
      direction="row"
      alignItems="center"
      justifyContent="center"
      padding="10px"
      borderBottom="1px solid #E9E9E9"
      bgcolor="background.primaryWhite1"
      position="fixed"
      top="0"
      zIndex="99"
      height={{ xs: '60px', md: '80px' }}
      width="100%"
    >
      <Stack alignItems="center">
        {companyLogo && companyLogo!=null?
            <Image
              sx={{
                width: { xs: '130px', md: '175px' },
                height:{xs: '44px', md: '60px'},
                objectFit:'contain',
              }}
              src={companyLogo}
              alt="Company Logo"
            />
          :
            null
        }
        
      </Stack>
    </Stack>
  );
}

InterviewHeader.propTypes = {
  companyLogo: PropTypes.string.isRequired,
};
