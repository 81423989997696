import React from 'react';
import PropTypes from 'prop-types';
import PermissionChecksLayout from './layout';

const MicrophonePermissions = ({ askedPermission, permissionAllowed }) => (
  <PermissionChecksLayout
    description="Microphone permission is required to record your audio responses."
    title="Microphone"
    icon="tabler:microphone"
    success={permissionAllowed}
    isLoading={!askedPermission}
  />
);

export default MicrophonePermissions;

MicrophonePermissions.propTypes = {
  askedPermission: PropTypes.bool,
  permissionAllowed: PropTypes.bool,
};
