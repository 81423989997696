import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBoolean } from 'src/hooks/use-boolean';
import PermissionChecksLayout from './layout';

const CameraPermission = ({ permissionAllowed }) => {
  // states
  const isChecking = useBoolean(true);

  // effects
  useEffect(() => {
    if (permissionAllowed !== null) isChecking.onFalse();
  }, [permissionAllowed]);

  return (
    <PermissionChecksLayout
      description="Camera permission is required to record your video responses."
      title="Camera"
      icon="tabler:video"
      success={permissionAllowed}
      isLoading={isChecking.value}
    />
  );
};

export default CameraPermission;

CameraPermission.propTypes = {
  permissionAllowed: PropTypes.bool,
};
