import { Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Header from './header';
import LeftPanel from './left-panel';
import RightPanel from './right-panel';
import useCodingInterviewContext from '../../hooks/use-coding-interview-context';
import SubmittingPage from './submitting-page';

export default function InterviewRunPage({ companyLogo }) {
  const { questions, currentQuestionIndex, startRecording, isSubmitting } =
    useCodingInterviewContext();

  // effects
  useEffect(() => {
    startRecording(currentQuestionIndex);
  }, [currentQuestionIndex, startRecording]);

  return (
    <>
      <Stack
        width="100%"
        height="100vh"
        maxHeight="100vh"
        minHeight="100vh"
        bgcolor="#0D0D0D"
      >
        <Header companyLogo={companyLogo} />
        <Grid container p="12px" height="100%" spacing="18px">
          <Grid item md={4}>
            <LeftPanel question={questions[currentQuestionIndex]} />
          </Grid>
          <Grid item md={8}>
            <RightPanel />
          </Grid>
        </Grid>
      </Stack>
      {isSubmitting && <SubmittingPage />}
    </>
  );
}

InterviewRunPage.propTypes = {
  companyLogo: PropTypes.string,
};
