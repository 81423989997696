import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import { RunInterviewView2 } from 'src/sections/interview-run/view';

// ----------------------------------------------------------------------

export default function RunInterviewPage2() {
  const params = useParams();

  const { id } = params;

  console.log("run interview page opened with id ",id);

  return (
    <>
      <Helmet>
        <title>HireIntel.AI - Interview Run Page - V2</title>
      </Helmet>

      <RunInterviewView2 interviewId={id} />
    </>
  );
}
