import { Navigate, useRoutes } from 'react-router-dom';
import { mainRoutes, HomePage } from './main';
import { plainRoutes } from './plain';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main routes
    ...mainRoutes,

    ...plainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
