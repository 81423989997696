import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import Loader from '../loader';

// ----------------------------------------------------------------------

export default function LoadingScreen({
  sx,
  loadingText,
  width = 100,
  height = 100,
  ...other
}) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <Stack alignItems="center" spacing={1.5} width="100%">
        <Loader width={width} height={height} />
        <Typography textAlign="center" variant="h5">
          {loadingText}
        </Typography>
      </Stack>
    </Box>
  );
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
  loadingText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
