import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

export default function Output({
  openTestResults,
  setOpenTestResults,
  outputText,
  errorMessage,
  isError,
}) {
  return (
    <Stack
      sx={{
        backgroundColor: '#111111',
        borderColor: '#4f4f4f57',
        borderRadius: '6px',
        height: '100%',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingLeft: '12px',
          paddingRight: '12px',
          cursor: 'pointer',
        }}
        onClick={() => setOpenTestResults(!openTestResults)}
      >
        <Stack direction="row" alignItems="center" spacing="8px">
          <Iconify
            icon="tabler:square-check"
            color="content.primaryGreen1"
            width={20}
          />
          <Typography
            variant="subheading"
            color="content.primaryWhite"
            fontSize={18}
          >
            Output
          </Typography>
        </Stack>
        <Iconify icon="tabler:chevron-down" color="content.primaryWhite" />
      </Stack>
      {openTestResults ? (
        <Stack height="100%" px="12px" pt="16px" pb="8.5px">
          <Stack
            borderRadius="5px"
            border="1px solid #252525"
            bgcolor="#010101"
            px="12px"
            py="20px"
            height="100%"
            sx= {{
              maxHeight: '190px',
            }}
          >
            <Scrollbar>
              {isError ? (
                <Stack spacing="12px">
                  <Typography variant="subheading" color="content.primaryRed1">
                    Error
                  </Typography>
                  <Typography
                    component="pre"
                    variant="body1"
                    color="content.primaryWhite"
                  >
                    {errorMessage}
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  component="pre"
                  variant="body1"
                  color="content.primaryWhite"
                >
                  {outputText}
                </Typography>
              )}
            </Scrollbar>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
}

Output.propTypes = {
  openTestResults: PropTypes.bool,
  setOpenTestResults: PropTypes.func,
  outputText: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
};
