import React, { useState } from 'react';
import AudioAnalyser from 'react-audio-analyser';
import PropTypes from 'prop-types';

export default function AudioAnalyzerComponent({
  audioStatus,
  className,
  onStopAudio,
  questionIndex,
  questionId,
}) {
  const [audioSrc, setAudioSrc] = useState(null);
  const audioType = 'audio/mp3';

  const handleStop = async e => {
    try {
      const audioBlob = e;
      console.log('Audio Recording Stopped');
      console.log("question index",questionIndex);
      console.log("question id",questionId);
      onStopAudio(audioBlob,questionIndex,questionId);
    } catch (err) {
      console.log(err);
    }
  };

  const handleError = err => {
    console.log('Audio Error: ', err);
  };

  const audioProps = {
    audioType,
    status: audioStatus,
    audioSrc,
    timeslice: 1000,
    stopCallback: handleStop,
    errorCallback: handleError,
  };

  return <AudioAnalyser className={className} {...audioProps} />;
}

AudioAnalyzerComponent.propTypes = {
  audioStatus: PropTypes.string,
  className: PropTypes.string,
  questionIndex: PropTypes.number,
  questionId: PropTypes.string,
  onStopAudio: PropTypes.func,
};
