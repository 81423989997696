import React, { useState } from 'react';
import { Stack, Typography, Box, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Image from 'src/components/image';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_INSTRUCTION_PAGE_CONTINUE } from 'src/analytics/trackevents';
import TermsAndPolicy from 'src/sections/terms-and-privacy';
import { acceptTermsAndPolicy } from 'src/services/interview.service';
import InterviewHeader from './interview-header';
import { PERMISSIONS_PAGE } from '../constants';

const InstructionsPage = ({
  tokenId,
  companyLogo,
  interviewId,
  setPageState,
  isTermsAndPolicyAccepted,
  setIsTermsAndPolicyAccepted,
}) => {
  // hooks
  const mdUp = useResponsive('up', 'md');

  // states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  // variables
  const instructions = [
    {
      icon: 'tabler:video',
      text: 'This is a video-based AI interview, so make sure you are in a quiet and bright place.',
    },
    {
      icon: 'tabler:device-desktop',
      text: 'We recommend you complete the AI interview in one go and avoid any distractions.',
    },
    {
      icon: 'tabler:user-scan',
      text: 'Your behavior and screen will be monitored during the interview to ensure fairness to everyone.',
    },
  ];

  // states
  const [termsAndPolicyType, setTermsAndPolicyType] = useState('');

  // functions
  const handleTermsAndPolicy = async () => {
    try {
      setIsSubmitting(true);
      const response = await acceptTermsAndPolicy(interviewId, tokenId);
      if (!response.success) {
        throw new Error(response.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <InterviewHeader companyLogo={companyLogo} />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        marginTop={{ xs: '60px', md: '80px' }}
        width="100%"
        paddingX={{
          xs: '16px',
          md: '20px',
        }}
        paddingBottom={{
          xs: '20px',
        }}
        paddingTop={{
          xs: '24px',
        }}
        height={{ xs: 'calc(100vh - 80px - 76px)', md: 'calc(100vh - 80px)' }}
        maxHeight={{
          xs: 'calc(100vh - 80px - 76px)',
          md: 'calc(100vh - 80px)',
        }}
        spacing="22px"
        sx={{
          overflowY: 'auto',
        }}
      >
        <Stack
          sx={{
            width: { xs: 'auto', md: '50%' },
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            height: '90%',
          }}
        >
          <Image
            src="/assets/illustrations/woman-working-on-a-computer.svg"
            alt="lady-waving"
          />
        </Stack>
        <Stack
          spacing={{ xs: '20px', md: '28px' }}
          width={{ xs: 'auto', md: '50%' }}
          height="100%"
        >
          <Stack
            width="100%"
            height="300px"
            mb="4px"
            alignItems="center"
            display={{ xs: 'none', md: 'none' }}
            justifyContent="center"
          >
            <Image
              src="/assets/illustrations/woman-working-on-a-computer.svg"
              alt="lady-waving"
            />
          </Stack>
          <Stack
            justifyContent={{ xs: 'center', md: 'center' }}
            spacing={{ md: '28px', xs: '20px' }}
            height="100%"
          >
            <Stack spacing="20px">
              <Typography variant={mdUp ? 'h3' : 'subheading2'}>
                Before you start
              </Typography>
              <Stack spacing="22px">
                {instructions.map((instruction, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing="12px"
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                  >
                    <Box
                      sx={{
                        width: { xs: '32px', md: '60px' },
                        minWidth: { xs: '32px', md: '60px' },
                        height: { xs: '32px', md: '60px' },
                        backgroundColor: 'background.primaryAccent2',
                        color: 'content.accent1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: { md: '14px', xs: '6.4px' },
                      }}
                    >
                      <Iconify width={mdUp ? 32 : 16} icon={instruction.icon} />
                    </Box>
                    <Typography variant="paragraph" fontWeight={400}>
                      {instruction.text}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            {!isTermsAndPolicyAccepted && (
              <Stack direction="row" alignItems="center" spacing="12px">
                <Checkbox
                  defaultChecked
                  value={isChecked}
                  onChange={e => {
                    setIsChecked(e.target.checked);
                  }}
                />
                <Typography variant="input" component="span">
                  I agree to{' '}
                  <Typography
                    variant="input"
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: '#0054FF',
                    }}
                    onClick={() => {
                      setTermsAndPolicyType('TERMS_AND_CONDITIONS');
                    }}
                  >
                    Terms of use
                  </Typography>{' '}
                  &{' '}
                  <Typography
                    variant="input"
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: '#0054FF',
                    }}
                    onClick={() => {
                      setTermsAndPolicyType('PRIVACY_POLICY');
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Typography>
              </Stack>
            )}
            {mdUp && (
              <Stack>
                <LoadingButton
                  disabled={!isChecked}
                  loading={isSubmitting}
                  loadingPosition="start"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={async () => {
                    handleTermsAndPolicy();
                    setPageState(PERMISSIONS_PAGE);
                    trackEvent(EVENT_INTERVIEW_INSTRUCTION_PAGE_CONTINUE, {});
                  }}
                >
                  Continue
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {!mdUp && (
        <Stack px="16px" py="20px" width="100%" position="fixed" bottom="0">
          <LoadingButton
            disabled={!isChecked}
            loading={isSubmitting}
            loadingPosition="start"
            fullWidth
            size="medium"
            variant="contained"
            onClick={async () => {
              handleTermsAndPolicy();
              setPageState(PERMISSIONS_PAGE);
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
      )}
      <TermsAndPolicy
        open={!!termsAndPolicyType}
        handleOnClose={() => {
          setTermsAndPolicyType('');
        }}
        type={termsAndPolicyType}
      />
    </>
  );
};

export default InstructionsPage;

InstructionsPage.propTypes = {
  tokenId: PropTypes.string.isRequired,
  companyLogo: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  setPageState: PropTypes.func.isRequired,
  isTermsAndPolicyAccepted: PropTypes.bool.isRequired,
  setIsTermsAndPolicyAccepted: PropTypes.func.isRequired,
};
