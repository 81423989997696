import { typographyClasses } from '@mui/material/Typography';
import { accordionClasses } from '@mui/material/Accordion';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { border } from '@mui/system';

// ----------------------------------------------------------------------

export function accordion(theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          paddingTop: '24px',
          paddingBottom: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
          border: `1px solid ${theme.palette.border.primaryBlack3}`,
          backgroundColor: theme.palette.common.white,
          borderRadius: '6px',
          '&:before': {
            opacity: 0,
          },
          [`&.${accordionClasses.expanded}`]: {
            boxShadow: 'none',
            borderRadius: '6px',
            backgroundColor: theme.palette.common.white,
          },
          [`&.${accordionClasses.disabled}`]: {
            backgroundColor: theme.palette.common.white,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          [`&.${accordionSummaryClasses.disabled}`]: {
            opacity: 1,
            color: theme.palette.action.disabled,
            [`& .${typographyClasses.root}`]: {
              color: 'inherit',
            },
          },
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
  };
}
