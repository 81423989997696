import React from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

const Webcamera = ({ videoPreview, webcamRef }) => (
  <Webcam
    style={{
      visibility: !videoPreview && 'hidden',
      width: '100%',
      height: '100%',
      transform: 'scaleX(-1)',
      objectFit: 'cover',
    }}
    autoPlay
    muted
    ref={webcamRef}
    screenshotFormat="image/jpeg"
  />
);

export default Webcamera;

Webcamera.propTypes = {
  videoPreview: PropTypes.bool,
  webcamRef: PropTypes.object,
};
