import React, { useEffect } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import PropTypes from 'prop-types';
import PermissionChecksLayout from './layout';

const MicrophonePermissions = ({ permissionAllowed }) => {
  // states
  const isChecking = useBoolean(true);

  // effects
  useEffect(() => {
    if (permissionAllowed !== null) isChecking.onFalse();
  }, [permissionAllowed]);

  return (
    <PermissionChecksLayout
      description="Microphone permission is required to record your audio responses."
      title="Microphone"
      icon="tabler:microphone"
      success={permissionAllowed}
      isLoading={isChecking.value}
    />
  );
};

export default MicrophonePermissions;

MicrophonePermissions.propTypes = {
  permissionAllowed: PropTypes.bool,
};
