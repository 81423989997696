import {
  postData,
  getData,
  putData,
  getDataGuest,
  postDataGuest,
} from 'src/utils/axios/index';
import { urlMap } from 'src/utils/urls';

export const getJobDetails = async (organizationId, jobId) => {
  const url = `${urlMap.getJobDetails}`
    .replace(`{org-id}`, organizationId)
    .replace(`{job-opening-id}`, jobId);
  return getData(url);
};

export const fetchInterviewRoles = async organizationId => {
  const url = `${urlMap.interviewRoles}`.replace(`{org-id}`, organizationId);
  // console.log(url);
  return getData(url);
};

export const createNewRole = async (roleData, organizationId) => {
  const url = `${urlMap.createRole}`.replace(`{org-id}`, organizationId);
  console.log('url = ', url);
  return postData(url, roleData);
};

export const editJobDetails = async (roleData, organizationId, jobId) => {
  const url = `${urlMap.editRole}`
    .replace(`{org-id}`, organizationId)
    .replace(`{job-id}`, jobId);
  return postData(url, roleData);
};

export const createQuestions = async (
  organizationId,
  jobOpeningId,
  reqBody,
) => {
  const mapObj = {
    '{org-id}': organizationId,
    '{job-opening-id}': jobOpeningId,
  };

  const url = `${urlMap.createPacket}`.replace(
    /{org-id}|{job-opening-id}/gi,
    matched => mapObj[matched],
  );
  console.log(url);
  return postData(url, reqBody);
};

export const createPrescreeningQues = async (
  payload,
  organizationId,
  jobId,
) => {
  const url = `${urlMap.createPrescreeningQues}`
    .replace(`{org-id}`, organizationId)
    .replace(`{job-id}`, jobId);
  return postData(url, payload);
};

export const getPrescreeningQues = async (jobId, token_id) => {
  const url = `${urlMap.getPrescreeningQues}`.replace(`{job-id}`, jobId);
  return getData(url, token_id);
};

export const postPrescreeningResponse = async (payload, jobId, token_id) => {
  const url = `${urlMap.getPrescreeningResponse}`.replace(`{job-id}`, jobId);
  return postData(url, payload, token_id);
};

export const editQuestions = async (organizationId, jobOpeningId, reqBody) => {
  const mapObj = {
    '{org-id}': organizationId,
    '{job-opening-id}': jobOpeningId,
  };

  const url = `${urlMap.editPacket}`.replace(
    /{org-id}|{job-opening-id}/gi,
    matched => mapObj[matched],
  );
  console.log(url);
  return putData(url, reqBody);
};

export const getJobSuggestions = async (payload, organizationId) => {
  const url = `${urlMap.jobSuggestion}`.replace(`{org-id}`, organizationId);
  // console.log(url);

  return postData(url, payload);
};

export const getSkillSuggestions = async (payload, organizationId) => {
  const url = `${urlMap.skillSuggestion}`.replace(`{org-id}`, organizationId);
  // console.log(url);

  return postData(url, payload);
};

export const getJobDescription = async (payload, organizationId) => {
  const url = `${urlMap.getJobDescription}`.replace(`{org-id}`, organizationId);
  // console.log(url);

  return postData(url, payload);
};

export const getPublicApplyLink = async jobId => {
  const url = `${urlMap.getPublicApplyLink}`.replace(`{job-id}`, jobId);
  // console.log(url);

  return getData(url);
};

export const getLinkDetails = async linkId => {
  const url = `${urlMap.getPublicLinkDetails}`.replace(`{link-id}`, linkId);
  return getDataGuest(url);
};

export const getPublicJobDetails = async (orgId, jobId) => {
  const url = `${urlMap.getPublicJobDetails}`
    .replace(`{org-id}`, orgId)
    .replace(`{job-id}`, jobId);
  return getDataGuest(url);
};

export const createInterviewLinkPublic = async (payload, jobId) => {
  const url = `${urlMap.createInterviewLinkPublic}`.replace(`{job-id}`, jobId);
  return postDataGuest(url, payload);
};

export const publishUnpublishLink = async (jobId, publishQuery) => {
  const url = `${urlMap.publishUnpublishLink}`
    .replace(`{job-id}`, jobId)
    .replace(`{publish-query}`, publishQuery);
  return postData(url);
};
