import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import InterviewScreeningView from 'src/sections/public-interview-screening/view/interview-screening-view';

// ----------------------------------------------------------------------

export default function PublicInterviewScreening() {
    const params = useParams();
    console.log("params = ",params);
    const { jobId, orgId } = params;

    return (
        <>
            <Helmet>
                <title>HireIntel.AI - AI Interview Screening</title>
            </Helmet>
            <InterviewScreeningView jobId={jobId} orgId={orgId}/>
        </>
    );
}
