import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = name => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  job: icon('ic_job'),
  report: icon('ic_analytics'),
  candidates: icon('ic_user'),

};

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t(' '),
        items: [
          {
            title: t('jobs'),
            path: paths.dashboard.job.root,
            icon: ICONS.job,
          },
          {
            title: t('candidates'),
            path: paths.dashboard.candidates.root,
            icon: ICONS.candidates,
          },
          {
            title: t('reports'),
            path: paths.dashboard.reports,
            icon: ICONS.report,
          },
        ],
      },
    ],
    [t],
  );

  return data;
}
