export const sendPageEvent= async(pageName)=> {
    try{
        if(process.env.REACT_APP_SEGMENT_ENABLED === "true" )
        {
            console.log("sending page name to analytics",pageName);
            window.analytics.page(pageName);
        }
    }
    catch(exception)
    {
       console.log("error sending analytics data",exception);
    }
}

export const sendIdentify = async(user)=> {
    try{
        if(process.env.REACT_APP_SEGMENT_ENABLED === "true" )
        {
            console.log("sending identification ",user);
            if(user)
            {
                analytics.identify(user.user_id, {
                    name: user.name,
                    email: user.email,
                  });
            }
        }
    }
    catch(exception)
    {
       console.log("error sending analytics data",exception);
    }
}

export const trackEvent = async(eventName,eventData)=> {
    try{
        if(process.env.REACT_APP_SEGMENT_ENABLED === "true" )
        {
            console.log("tracking event ",eventName,eventData);
            
            analytics.track(eventName,eventData);
            
        }
    }
    catch(exception)
    {
       console.log("error sending even track data",exception);
    }
}

