import React, { useEffect, useState } from 'react';
import DemoPage from '../demo-page';
import InstructionsPage from '../instructions-page';
import PrescreeningPage from '../prescreening-page';
import LaunchPage from '../launch-page';
import { trackEvent } from 'src/analytics/datatrack';
import { InterviewContext } from './interview-context';
import { EVENT_INTERVIEW_DEMO_PAGE, EVENT_INTERVIEW_INSTRUCTION_PAGE, EVENT_INTERVIEW_LAUNCH_PAGE, EVENT_INTERVIEW_PRESCREEN_PAGE } from 'src/analytics/trackevents';

export const INTERVIEW_PAGE_INSTRUCTION = "INTERVIEW_PAGE_INSTRUCTION";
export const INTERVIEW_PAGE_PRESCREEN = "INTERVIEW_PAGE_PRESCREEN";
export const INTERVIEW_PAGE_DEMO = "INTERVIEW_PAGE_DEMO";
export const INTERVIEW_PAGE_RUN = "INTERVIEW_PAGE_RUN";

const InterviewView = ({ interview_id }) => {
  const [pageState, setPageState] = useState(INTERVIEW_PAGE_INSTRUCTION);

    const [enableScreenRecording, setEnableScreenRecording] = useState(false);
    const [prescreeningSkipped, setPrescreeningSkipped] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const [interviewId, setInterviewId] = useState(interview_id);
    const [jobOpeningId,setJobOpeningId]= useState(null);

    // Define the context value
    const contextValue = {
        enableScreenRecording,
        setEnableScreenRecording,
        prescreeningSkipped,
        setPrescreeningSkipped,
        jobTitle,
        setJobTitle,
        interviewId,
        setInterviewId,
        jobOpeningId,
        setJobOpeningId
    };

  useEffect(() => {
    console.log("page state is ",pageState);
    if (pageState === INTERVIEW_PAGE_INSTRUCTION) {
      trackEvent(EVENT_INTERVIEW_INSTRUCTION_PAGE, {});
    }
    if (pageState === INTERVIEW_PAGE_PRESCREEN) {
      trackEvent(EVENT_INTERVIEW_PRESCREEN_PAGE, {});
    }
    if (pageState === INTERVIEW_PAGE_DEMO) {
      trackEvent(EVENT_INTERVIEW_DEMO_PAGE, {});
    }
    if (pageState === INTERVIEW_PAGE_RUN) {
      trackEvent(EVENT_INTERVIEW_LAUNCH_PAGE, {});
    }
  }, [pageState]);

  return (
    <InterviewContext.Provider value={contextValue}>
      {pageState === INTERVIEW_PAGE_INSTRUCTION && <InstructionsPage setPageState={setPageState} />}
      {pageState === INTERVIEW_PAGE_PRESCREEN && <PrescreeningPage  setPageState={setPageState} />}
      {pageState === INTERVIEW_PAGE_DEMO && <DemoPage setPageState={setPageState} />}
      {pageState === INTERVIEW_PAGE_RUN && <LaunchPage setPageState={setPageState} />}
    </InterviewContext.Provider>
  );
};

export default InterviewView;