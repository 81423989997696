import {
  Typography,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Loader from 'src/components/loader';
import { useResponsive } from 'src/hooks/use-responsive';

const TYPES = {
  TERMS_AND_CONDITIONS: {
    title: 'Terms Of Use',
    src: 'https://hireintel.ai/terms-headless.html',
  },
  PRIVACY_POLICY: {
    title: 'Privacy Policy',
    src: 'https://hireintel.ai/privacy-policy-headless.html',
  },
};

export default function TermsAndPolicy({ open, handleOnClose, type }) {
  // hooks
  const mdUp = useResponsive('up', 'md');

  // states
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="xl">
      <DialogTitle>
        {type && (
          <Typography variant="h4" mx="20px">
            {TYPES[type].title}
          </Typography>
        )}
      </DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
        onClick={handleOnClose}
      >
        <Iconify icon="tabler:x" />
      </IconButton>
      <DialogContent>
        {isLoading ? (
          <Stack height={400}>
            <Loader />
          </Stack>
        ) : (
          type && (
            <iframe
              src={TYPES[type].src}
              title={TYPES[type].title}
              width={mdUp ? 1000 : 300}
              height={400}
              style={{
                border: 'none',
              }}
              onLoad={() => {
                setIsLoading(false);
              }}
            />
          )
        )}
      </DialogContent>
    </Dialog>
  );
}

TermsAndPolicy.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
