import { alpha } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

// ----------------------------------------------------------------------

export function button(theme) {
  const lightMode = theme.palette.mode === 'light';

  const rootStyles = ownerState => {
    const inheritColor = ownerState.color === 'inherit';

    const containedVariant = ownerState.variant === 'contained';

    const outlinedVariant = ownerState.variant === 'outlined';

    const textVariant = ownerState.variant === 'text';

    const softVariant = ownerState.variant === 'soft';

    const smallSize = ownerState.size === 'small';

    const mediumSize = ownerState.size === 'medium';

    const largeSize = ownerState.size === 'large';

    const defaultStyle = {
      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          color: lightMode
            ? theme.palette.content.primaryWhite
            : theme.palette.grey[800],
          backgroundColor: lightMode
            ? theme.palette.background.primaryBlack1
            : theme.palette.common.white,
          '&:hover': {
            backgroundColor: lightMode
              ? theme.palette.background.primaryAccent1
              : theme.palette.grey[400],
          },
          '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            color: lightMode ? 'inherit' : theme.palette.grey[800],
          },
        }),
        // OUTLINED
        ...(outlinedVariant && {
          borderColor: theme.palette.border.primaryBlack2,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        // TEXT
        ...(textVariant && {
          color: theme.palette.content.primaryBlack2,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        // SOFT
        ...(softVariant && {
          color: theme.palette.text.primary,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.24),
          },
        }),
      }),
      ...(outlinedVariant && {
        '&:hover': {
          borderColor: 'currentColor',
          boxShadow: '0 0 0 0.5px currentColor',
        },
      }),
    };

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          '&:hover': {
            boxShadow: theme.customShadows[color],
          },
        }),
        // SOFT
        ...(softVariant && {
          color: theme.palette[color][lightMode ? 'dark' : 'light'],
          backgroundColor: alpha(theme.palette[color].main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette[color].main, 0.32),
          },
        }),
      }),
    }));

    const disabledState = {
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: theme.palette.background.primaryGrey2,
        // SOFT
        ...(softVariant && {
          backgroundColor: theme.palette.action.disabledBackground,
        }),
      },
    };

    const typography = {
      ...theme.typography.input,
    };

    const size = {
      ...(smallSize && {
        minWidth: 96,
        borderRadius: 8,
        height: 36,
        fontSize: 13,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10,
        paddingBottom: 10,
        ...(textVariant && {
          paddingLeft: 8,
          paddingRight: 8,
        }),
      }),
      ...(mediumSize && {
        minWidth: 120,
        borderRadius: 10,
        height: 40,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 6,
        paddingBottom: 6,
        ...(textVariant && {
          paddingLeft: 8,
          paddingRight: 8,
        }),
      }),
      ...(largeSize && {
        minWidth: 120,
        borderRadius: 12,
        height: 44,
        fontSize: 15,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
        paddingBottom: 8,
        ...(textVariant && {
          paddingLeft: 8,
          paddingRight: 8,
        }),
      }),
    };

    const iconSpacing = {
      '& .MuiButton-startIcon': {
        marginRight: 8,
        marginLeft: 0,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 8,
      },
    };

    return [
      { alignItems: 'center' },
      defaultStyle,
      ...colorStyle,
      disabledState,
      size,
      typography,
      iconSpacing,
    ];
  };

  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => rootStyles(ownerState),
      },
    },
  };
}
