import { Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useStopwatch } from 'react-timer-hook';

const StopWatch = forwardRef((props, ref) => {
  const { totalSeconds, seconds, minutes } = useStopwatch({ autoStart: true });

  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;

  useImperativeHandle(ref, () => ({
    totalSeconds,
  }));

  return (
    <Typography display="none" variant="caption">
      {formattedTime}
    </Typography>
  );
});

export default StopWatch;

StopWatch.propTypes = {};
