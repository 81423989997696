import { Button, Stack, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from "./styles.module.css";
import { createInterviewLinkPublic } from 'src/services/role.service';


const ResumeParsingFailed = ({setInterviewUrl, setEligible, resumeUrl, jobId}) => {
    const defaultValues = useMemo(
        () => ({
            fullname: '',
            email: '',
            phonenumber: '',
        }),
        [],
    );

    const ResumeSchema = Yup.object().shape({
        fullname: Yup.string().required('Full Name is required'),
        email: Yup.string()
            .email('Please enter a valid email')
            .required('Email is required'),
        phonenumber: Yup.string().required('Phone number is required'),
    });

    const ResumeMethods = useForm({
        resolver: yupResolver(ResumeSchema),
        defaultValues: defaultValues,
    });

    const [methods, setMethods] = useState(ResumeMethods);

    const { reset, setValue, handleSubmit, watch } = methods;

    const onSubmit = handleSubmit(async (data, event) => {
        console.log(data);
        try {
            const payload={
                name:data.fullname,
                email:data.email,
                phone_no:data.phonenumber,
                resume_url:resumeUrl,
                "disable_screening": true
            }
            const res = await createInterviewLinkPublic(payload, jobId);
            console.log(res);
            if(res?.success){
                setEligible("eligible");
                window.location.href = process.env.REACT_APP_INTERVIEW_CLIENT_URL + res?.body?.interview_url;
                setInterviewUrl(process.env.REACT_APP_INTERVIEW_CLIENT_URL + res.body.interview_url);
            }
        } catch (error) {
            console.error(error);
        }
    });

    return (
        <Stack className={styles.resumeParsingFailed}>
            <FormProvider methods={methods} onSubmit={onSubmit}>

                <Stack spacing="1rem" sx={{ width: "100%" }}>
                    <Typography sx={{marginBottom:"1rem"}} variant="h4">Enter your details</Typography>
                    <Stack spacing={1.5}>
                        <RHFTextField name="fullname" placeholder="Full Name" />
                    </Stack>
                    <Stack spacing={1.5}>
                        <RHFTextField name="email" type="email" placeholder="Email" />
                    </Stack>
                    <Stack spacing={1.5}>
                        <RHFTextField name="phonenumber" placeholder="Phone Number" />
                    </Stack>
                    <Stack sx={{margin:"auto"}}>
                        <Button sx={{ marginTop: "3.6rem", minWidth: "6rem", width: "6rem" }} variant="contained" type="submit">
                            Submit
                        </Button>
                    </Stack>

                </Stack>
            </FormProvider>
        </Stack>
    )
}

export default ResumeParsingFailed