 const getDeviceInfo = () => {
    const deviceInfo = {
      USER_AGENT: navigator.userAgent,
      OS_PLATFORM: navigator.platform,
      NETWORK_TYPE: navigator.connection ? navigator.connection.effectiveType : 'unknown',
      DEVICE_MEMORY: navigator.deviceMemory || 'unknown',
      CPU_CORES: navigator.hardwareConcurrency || 'unknown'
    };
    return deviceInfo;
  };

  export {getDeviceInfo};