import {getDeviceInfo} from '../device-details/device-details'
import {uploadTextFileToS3} from '../../services/aws.service'
let logs = [];
let logId = 0;

const generateLogId = () => {
  return `log_${logId++}`;
};

const formatDate = (date) => {
  return date.toISOString();
};

const captureLog = (level, ...args) => {
    const logEntry = {
        id: generateLogId(),
        timestamp: formatDate(new Date()),
        level,
        messages: args.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg)),
      };
  logs.push(logEntry);

  originalConsoleMethods[level](...args);
};

const originalConsoleMethods = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
  debug: console.debug
};

const overrideConsole = () => {
  console.log = (...args) => captureLog('log', ...args);
  console.info = (...args) => captureLog('info', ...args);
  console.warn = (...args) => captureLog('warn', ...args);
  console.error = (...args) => captureLog('error', ...args);
  console.debug = (...args) => captureLog('debug', ...args);
};

const getLogs = () => {
    console.log("Printing info and logs");
    console.log(getDeviceInfo());
  return logs;
};

const clearLogs = () => {
  logs = [];
};


const logsToTextFile = () => {
    return logs.map(log => 
      `${log.timestamp} [${log.level.toUpperCase()}] ${log.messages.join(' ')}`
    ).join('\n');
  };

const uploadLogs = async (interview_id) => {
    const logsToSend = logsToTextFile();
    if (logsToSend.length > 0) {
      try {
        const response = await uploadTextFileToS3(logsToSend, interview_id);
        if(response){
            console.log("Logs url in S3: ",response);
            clearLogs();
            return response;
        }else{
            console.log("Issue in uploading logs to S3");
            clearLogs();
            return "";
        }
        // clearLogs();
      } catch (error) {
        console.error('Error uploading logs to S3:', error);
      }
    }
  };

overrideConsole();

export { getLogs, clearLogs, captureLog ,uploadLogs};
