import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import animationData from 'src/assets/animations/json/loader.json';

export default function Loader({ height = 24, width = 24 }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
      style={{
        margin: 0,
      }}
    />
  );
}

Loader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
