import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify';
import { INSTRUCTIONS_PAGE } from './constants';
import BrowserCheck from '../compatibility-checks/browser-check';
import OSCheck from '../compatibility-checks/os-check';

export default function CombatibilityCheckDialog({
  open,
  onClose,
  setPageState,
  browserCompatible,
  osCompatible,
  isCompatibilityChecking,
  isBrowserCompatibilityChecking,
  isOSCompatibilityChecking,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth>
      <DialogTitle>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">System check</Typography>
          <IconButton onClick={onClose}>
            <Iconify icon="tabler:x" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="16px" width="100%">
          <BrowserCheck
            browserCompatible={browserCompatible}
            isBrowserCompatibilityChecking={
              isBrowserCompatibilityChecking.value
            }
          />

          <OSCheck
            osCompatible={osCompatible}
            isOSCompatibilityChecking={isOSCompatibilityChecking.value}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={
            isBrowserCompatibilityChecking.value ||
            isOSCompatibilityChecking.value 
          }
          variant="contained"
          onClick={() => {
            if (browserCompatible && osCompatible)
              setPageState(INSTRUCTIONS_PAGE);
            else onClose();
          }}
        >
          {isBrowserCompatibilityChecking.value ||
          isOSCompatibilityChecking.value ||
          (browserCompatible && osCompatible)
            ? 'Good to go !!'
            : "Got it I'll come back"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

CombatibilityCheckDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setPageState: PropTypes.func,
  browserCompatible: PropTypes.bool,
  osCompatible: PropTypes.bool,
  isCompatibilityChecking: PropTypes.object,
  isBrowserCompatibilityChecking: PropTypes.object,
  isOSCompatibilityChecking: PropTypes.object,
};
