import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    mainDiv:{
        display: "flex",
        zIndex: 12,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'row',
        padding: '1rem',
        borderBottom: "1px solid #E9E9E9",
        position: "fixed",
        background: theme.palette.background.default,
        top: 0,
    },
    headerDiv: {
        width: "90%",
    }
}));

const Jobheader = ({ companyLogo }) => {
    const classes = useStyles();
    return (
        <div className={classes.mainDiv}>
            <header className={classes.headerDiv}>
                <Stack alignItems="center" >
                    <img style={{ borderRadius: "5px" }} src={companyLogo} alt={"Company Logo"} width="80px" />
                </Stack>

                {/* <Avatar
                src={companyLogo}
                // alt={"Company Logo"}
                sx={{
                    mx: 'auto',
                    width: { xs: 64, md: 128 },
                    height: { xs: 64, md: 128 },
                }}
            /> */}

            </header>
        </div>
    )
}

export default Jobheader