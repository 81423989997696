import { Box, Button, Card, Container, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState,useContext } from 'react'
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings'
import classes from './styles.module.css';
import { useSnackbar } from 'notistack';
import { LoadingScreen } from 'src/components/loading-screen';
import { useQuery } from 'src/hooks/use-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getInterviewDetails } from 'src/services/interview.service';
import { getPrescreeningQues, postPrescreeningResponse } from 'src/services/role.service';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_PRESCREEN_SKIP, EVENT_INTERVIEW_PRESCREEN_SUBMIT } from 'src/analytics/trackevents';
import { INTERVIEW_PAGE_INSTRUCTION, INTERVIEW_PAGE_RUN } from './view/interview-view';
import { InterviewContext } from './view/interview-context';

const PrescreeningPage = ({ setPageState }) => {
  const settings = useSettingsContext();
  const [loader, setLoader] = useState(false);
  const [preScreenData, setPrescreenData] = useState([]);
  const [jobId, setJobId] = useState(null);
  const {interviewId,prescreeningSkipped,setPrescreeningSkipped,jobOpeningId} = useContext(InterviewContext);

  const { enqueueSnackbar } = useSnackbar();


  // hooks
  const params = useQuery();
  const tokenId = params.get('token');

  let initialSchema = Yup.object().shape({});


  const [validationSchema, setValidationSchema] = useState(initialSchema);
  const [defaultValues, setDefaultValues] = useState({});

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { trigger, getValues, setValue, reset, handleSubmit, control, watch, formState: { errors } } = methods;

  const skipPrescreening = ()=>{
    console.log("skipping prescreening");
    setPrescreenData(null);
    setPrescreeningSkipped(true);
    setPageState(INTERVIEW_PAGE_RUN);
    trackEvent(EVENT_INTERVIEW_PRESCREEN_SKIP, {});
  }


  useEffect(() => {
    setLoader(true);
    const caller = async () => {
        setJobId(jobOpeningId);
        const res = await getPrescreeningQues(jobOpeningId, tokenId);
        console.log(res);
        if (res.success) {
          if (res.body.prescreen_status === "COMPLETED" || res.body.questions.length==0) {
            skipPrescreening();
          }
          else {
            setPrescreenData(res.body.questions);
            let updatedSchema, schemaObject = {}, defaultValueDum = {};

            res.body?.questions?.map((field, index) => {
              const { response_type } = field;

              switch (response_type) {
                case 'NUMERIC':
                  schemaObject[`Question${index}`] = Yup.number().required(`Value must be a number`);
                  defaultValueDum[`Question${index}`] = 0
                  break;
                case 'TEXT':
                  schemaObject[`Question${index}`] = Yup.string().required(`Please enter valid value`);
                  defaultValueDum[`Question${index}`] = ''
                  break;
                case 'BOOLEAN':
                  schemaObject[`Question${index}`] = Yup.boolean().required(`Value must be a Yes / No`);
                  defaultValueDum[`Question${index}`] = ''
                  break;
                default:
                  break;
              }
            });

            updatedSchema = Yup.object().shape(schemaObject);
            setDefaultValues(defaultValueDum);
            setValidationSchema(updatedSchema);
          }
        }
        else {
          console.log("Error getting prescreen questions");
          skipPrescreening();
        }
        setLoader(false);
    }
    caller();
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = getValues();
    console.log(data);
    trackEvent(EVENT_INTERVIEW_PRESCREEN_SUBMIT, {});
    const validated = await trigger();
    if (validated) {
      if (preScreenData && preScreenData.length > 0) {
        let dumm = {};
        preScreenData.map((item, index) => {
          dumm[item.question_id] = data[`Question${index}`]
        })
        const payload = { response_map: dumm }
        console.log(payload);
        setLoader(true);
        const res = await postPrescreeningResponse(payload, jobId, tokenId)
        .catch((err)=> {
          setLoader(false);
          console.log(err);
          enqueueSnackbar({ variant: "error", message: "Technical problem in submitting prescreening form, please contact HR" });
        });
        console.log(res);
        if(res.success){
          enqueueSnackbar("Prescreening form has been submitted");
          setPrescreeningSkipped(true);
          setPageState(INTERVIEW_PAGE_RUN);
        }
        setLoader(false);
      }
      else
      {
        setPageState(INTERVIEW_PAGE_RUN);
      }
    }
  }

  return (
    <>
      {loader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            height: '90%',
          }}
        >
          <div style={{ width: "50vw" }}>
            <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
              Please wait ...
            </h2>
            <LoadingScreen />
          </div>
        </div>
      )}
      {!loader && (
        <Stack sx={{ height: "100%", justifyContent: "center" }}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: "100%"
            }}
            maxWidth={settings.themeStretch ? false : 'lg'}
          >

            <Stack component={Card} sx={{ minHeight:"88vh", height:"82vh" }} className={classes.demoVideoDiv} spacing={3} >
              <Stack
                spacing={1.5}
                direction="row"
                alignItems="center"
                sx={{
                  mb:1
                }}

              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <Typography variant="h4">Prescreening</Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => { setPageState(INTERVIEW_PAGE_INSTRUCTION) }}
                      startIcon={
                        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                      }
                    >
                      Back
                    </Button>
                  </Box>
                </Box>
              </Stack>
              <form style={{overflow:"auto",height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", paddingRight:"5px"}} onSubmit={handleSubmit(submitHandler)}>
                {preScreenData ? (
                  <>
                    <Stack sx={{ borderRadius: "10px" }}>
                      {preScreenData.map((item, index) => (
                        <Stack spacing={2} key={index} sx={{ marginBottom: "1rem" }}>
                          <Typography>Question {index + 1}. {item.text}</Typography>
                          {item.response_type === 'NUMERIC' ? (
                            <TextField
                              type="number"
                              name={`Question${index}`}
                              value={getValues()[`Question${index}`]}
                              onChange={e => { setValue(`Question${index}`, e.target.value) }}
                              label="Numbers only"
                              error={Boolean(errors[`Question${index}`])}
                              helperText={errors[`Question${index}`]?.message}
                            />
                          ) : null}
                          {item.response_type === 'BOOLEAN' ? (
                            <FormControl fullWidth>
                              <InputLabel id="Yes / No">Yes / No</InputLabel>
                              <Select
                                labelId={"Yes / No"}
                                name={`Question${index}`}
                                label="Yes / No"
                                value={getValues()[`Question${index}`]}
                                onChange={e => { setValue(`Question${index}`, e.target.value) }}
                                error={Boolean(errors[`Question${index}`])}
                              >
                                <MenuItem key="true" value={"TRUE"}>Yes</MenuItem>
                                <MenuItem key="false" value={"FALSE"}>No</MenuItem>
                              </Select>
                              {errors[`Question${index}`] ? (
                                <FormHelperText style={{ color: "#ff7150" }}>
                                  {errors[`Question${index}`]?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          ) : null}
                          {item.response_type === 'TEXT' ? (
                            <TextField
                              type="text"
                              name={`Question${index}`}
                              value={getValues()[`Question${index}`]}
                              onChange={e => { setValue(`Question${index}`, e.target.value) }}
                              multiline
                              label="Text only"
                              error={Boolean(errors[`Question${index}`])}
                              helperText={errors[`Question${index}`]?.message}
                            />
                          ) : null}
                        </Stack>

                      ))}
                    </Stack>

                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      alignItems: 'center',
                      height: '60vh',
                    }}
                  >
                    <div >
                      <h2 style={{ textAlign: 'center', marginTop: '-30px' }}>
                        Technical problem in prescreening form, please contact HR.
                      </h2>
                    </div>
                  </div>
                )}
                <Stack>
                  <Button
                    type="primary"
                    variant="contained"
                    sx={{ width: '10rem', margin: 'auto', marginTop: '4rem' }}
                    onClick={submitHandler}
                  >
                    Submit & Continue
                  </Button>
                </Stack>
                

              </form>
            </Stack>
          </Container>
        </Stack>
      )}


    </>

  )
}

export default PrescreeningPage;