import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const mimeType = 'video/webm; codecs="opus,vp8"';

export const useMediaRecorder = ({ video, audio, onVideoStop }) => {
  // refs
  const mediaRecorder = useRef(null);
  const liveVideoFeed = useRef(null);

  // states
  const [permission, setPermission] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);

  // functions
  const getCameraPermission = async () => {
    setRecordedVideo(null);

    // get video and audio permissions and then stream the result media stream to the videoSrc variable
    if ('MediaRecorder' in window) {
      try {
        const videoConstraints = {
          audio: audio || true,
          video: video || true,
        };

        const audioConstraints = { audio: true };

        // create audio and video streams separately
        const audioStream =
          await navigator.mediaDevices.getUserMedia(audioConstraints);
        const videoStream =
          await navigator.mediaDevices.getUserMedia(videoConstraints);

        setPermission(true);

        // combine both audio and video streams

        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        setStream(combinedStream);

        // set videostream to live feed player
        // liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.');
    }
  };

  const startRecording = async (currentQuestionId, currQuestionIndex) => {
    setRecordingStatus('recording');
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start(1000);

    mediaRecorder.current.ondataavailable = event => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;

      setVideoChunks(prevChunks => [
        ...prevChunks,
        { id: currentQuestionId, chunk: event.data },
      ]);
    };
  };

  const stopRecording = (currentQuestionId, currQuestionIndex) => {
    setPermission(false);
    setRecordingStatus('inactive');
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = () => {
      const videoChunksWithId = videoChunks.filter(
        chunk => chunk.id === currentQuestionId,
      );

      const videoBlob = new Blob(
        videoChunksWithId.map(chunk => chunk.chunk),
        {
          type: mimeType,
        },
      );

      const videoUrl = URL.createObjectURL(videoBlob);
      onVideoStop(videoBlob, currQuestionIndex, currentQuestionId);
      setRecordedVideo(videoUrl);

      setVideoChunks(prevChunks =>
        prevChunks.filter(chunk => chunk.id !== currentQuestionId),
      );
    };
  };

  // effects
  useEffect(() => {
    getCameraPermission();
  }, []);

  return {
    getCameraPermission,
    liveVideoFeed,
    permission,
    recordingStatus,
    recordedVideo,
    startRecording,
    stopRecording,
  };
};

useMediaRecorder.propTypes = {
  video: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  audio: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onVideoStop: PropTypes.func,
};
