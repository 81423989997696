import CodeEditor from 'src/components/code-editor';
import { useEffect, useState } from 'react';
import { defineTheme } from 'src/components/code-editor/lib/defineTheme';
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { codeInvoke } from 'src/services/coding-interview.service';
import useCodingInterviewContext from 'src/sections/coding-interview/hooks/use-coding-interview-context';
import { LoadingButton } from '@mui/lab';
import { LANGUAGES } from 'src/sections/coding-interview/constants/languages';
import ConfirmResetDialog from 'src/sections/coding-interview/dialogs/confirm-reset';
import Output from './output';
import { boilerplateCodes } from 'src/components/code-editor/constants/boilerplateCodes';
import { PsychologyAltRounded } from '@mui/icons-material';


export default function RightPanel() {
  // hooks
  const {
    code,
    language,
    languages,
    tokenId,
    codingInterviewId,
    setCode,
    setLanguage,
  } = useCodingInterviewContext();

  const [customInput, setCustomInput] = useState('');
  const [outputText, setOutputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState('cobalt');
  const [openTestResults, setOpenTestResults] = useState(false);
  const [openResetConfirmPopup, setOpenResetConfirmPopup] = useState(false);
  const [userCode, setUserCode] = useState(code);

  // functions
  const onChange = (action, data) => {
    switch (action) {
      case 'code': {
        setCode(data);
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  const handleCompile = async () => {
    try {
      setProcessing(true);
      const payload = {
        code_text: code,
        coding_language: language,
        input_text: customInput,
      };
      if (!code || code === '') {
        if (language) {
          const boilerplate = boilerplateCodes.find(item => item.language === language.toLowerCase());
          if (boilerplate) {
            payload.code_text=boilerplate.code;
            console.log(boilerplate.code);
          } else {
            console.log(`No boilerplate code found for language: ${language}`);
          }
        } else {
          console.log('Language is null or undefined');
        }
      }
      console.log("payCode",payload.code_text);
      

      const response = await codeInvoke(codingInterviewId, tokenId, payload);

      if (response?.success) {
        setOutputText(response?.body?.output_text);
        setErrorMessage(response?.body?.error_message);
        setIsError(response?.body?.is_error);
      } else {
        throw new Error(response?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProcessing(false);
      setOpenTestResults(true);
    }
  };

  useEffect(() => {
    defineTheme('upstream-sunburst').then(_ =>
      setTheme({ value: 'upstream-sunburst', label: 'Upstream Sunburst' }),
    );
  }, []);


  

  return (
    <Stack spacing="16px" height="100%" overflow="none">
      <Stack height="100%" border="1px solid #282828" borderRadius="9px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px="12px"
          py="7px"
          bgcolor="#010101"
          borderBottom="1px solid #282828"
          sx={{
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
          }}
        >
          <Stack direction="row" alignItems="center" spacing="24px">
            <Stack direction="row" alignItems="center" spacing="8px">
              <Iconify
                icon="tabler:code"
                color={_theme => _theme.palette.content.primaryWhite}
              />
              <Typography
                variant="subheading"
                fontSize={18}
                color="content.primaryWhite"
              >
                Code
              </Typography>
            </Stack>
            <Select
              sx={{
                width: '120px',
                backgroundColor: '#181818',
                color: 'content.primaryBlack3',
                '& .MuiOutlinedInput-input': {
                  minWidth: '0px',
                  color: 'content.primaryBlack3',
                },
                '& .MuiSelect-root': {
                  backgroundColor: '#181818',
                  borderColor: '#4e4e4e33',
                  color: 'content.primaryBlack3',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#181818',
                    color: 'content.primaryBlack3',
                    maxHeight: '300px',
                    width: '200px',
                  },
                },
              }}
              value={language}
            >
              {LANGUAGES.filter(option => languages.includes(option.value)).map(
                option => (
                  <MenuItem
                    key={option.value}
                    selected={option.value === language}
                    onClick={() => {
                      setLanguage(option.value);
                    }}
                    value={option.value}
                    sx={{
                      color: 'content.primaryBlack3',
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </Stack>
        </Stack>
        <CodeEditor
          code={code}
          onChange={onChange}
          language={language?.toLowerCase()}
          theme={theme.value}
          height={`calc(100vh - 44px - 104px - 16px - ${
            openTestResults ? '300px' : '86px'
          })`}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          px="12px"
          py="7px"
          bgcolor="#010101"
          borderTop="1px solid #2e2e2e"
          sx={{
            borderBottomLeftRadius: '9px',
            borderBottomRightRadius: '9px',
          }}
        >
          <TextField
            multiline
            maxRows={1}
            placeholder="Custom Input"
            sx={{
              '& .MuiOutlinedInput-root': {
                color: _theme => _theme.palette.content.primaryWhite,
                backgroundColor: _theme => _theme.palette.content.primaryBlack1,
              },
              '& .MuiOutlinedInput-input': {
                color: _theme => _theme.palette.content.primaryWhite,
                backgroundColor: _theme => _theme.palette.content.primaryBlack1,
              },
              width: '50%',
            }}
            value={customInput}
            onChange={e => setCustomInput(e.target.value)}
          />
          <Stack direction="row" alignItems="center" spacing="12px">
            <Tooltip title="Reset">
              <IconButton onClick={() => setOpenResetConfirmPopup(true)}>
                <Iconify
                  icon="tabler:reload"
                  color={_theme => _theme.palette.content.primaryBlack3}
                />
              </IconButton>
            </Tooltip>
            <LoadingButton
              loading={processing}
              variant="contained"
              sx={{
                color: _theme => _theme.palette.content.primaryBlack1,
                bgcolor: _theme => _theme.palette.content.primaryGreen1,
                borderColor: '#5858585c',
                boxShadow: 'inset 0px 0px 0px 2px #37373742',
                '&:hover': {
                  color: 'content.primaryGreen1',
                  bgcolor: _theme => _theme.palette.content.primaryBlack1,
                },
              }}
              onClick={handleCompile}
            >
              Run
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
      <Output
        openTestResults={openTestResults}
        setOpenTestResults={setOpenTestResults}
        outputText={outputText}
        isError={isError}
        errorMessage={errorMessage}
      />
      <ConfirmResetDialog
        open={openResetConfirmPopup}
        setOpen={setOpenResetConfirmPopup}
      />
    </Stack>
  );
}
