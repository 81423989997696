import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
//
import { getRatio } from './utils';

// ----------------------------------------------------------------------

const Image = forwardRef(
  (
    {
      ratio,
      overlay,
      disabledEffect = false,
      //
      alt,
      src,
      afterLoad,
      delayTime,
      threshold,
      beforeLoad,
      delayMethod,
      placeholder,
      wrapperProps,
      scrollPosition,
      effect = 'blur',
      visibleByDefault,
      wrapperClassName,
      useIntersectionObserver,
      sx,
      ...other
    },
    ref,
  ) => {
    const theme = useTheme();

    const overlayStyles = !!overlay && {
      '&:before': {
        content: "''",
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 1,
        position: 'absolute',
        background: overlay || alpha(theme.palette.grey[900], 0.48),
      },
    };

    const content = (
      <Box
        component="image"
        //
        alt={alt}
        src={src}
        // afterLoad={afterLoad}
        // delayTime={delayTime}
        // threshold={threshold}
        // beforeLoad={beforeLoad}
        // delayMethod={delayMethod}
        // placeholder={placeholder}
        // wrapperProps={wrapperProps}
        // scrollPosition={scrollPosition}
        // visibleByDefault={visibleByDefault}
        // effect={disabledEffect ? undefined : effect}
        // useIntersectionObserver={useIntersectionObserver}
        // wrapperClassName={wrapperClassName || 'component-image-wrapper'}
        // placeholderSrc={
        //   disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'
        // }
        //
        sx={{
          width: 1,
          height: 1,
          objectFit: 'cover',
          verticalAlign: 'bottom',
        }}
      />
    );

    return <Box component="img" src={src} alt={alt} sx={sx} {...other} />;
  },
);

Image.propTypes = {
  afterLoad: PropTypes.func,
  alt: PropTypes.string,
  beforeLoad: PropTypes.func,
  delayMethod: PropTypes.string,
  delayTime: PropTypes.number,
  disabledEffect: PropTypes.bool,
  effect: PropTypes.string,
  overlay: PropTypes.string,
  ratio: PropTypes.oneOf([
    '4/3',
    '3/4',
    '6/4',
    '4/6',
    '16/9',
    '9/16',
    '21/9',
    '9/21',
    '1/1',
  ]),
  scrollPosition: PropTypes.object,
  src: PropTypes.string,
  sx: PropTypes.object,
  threshold: PropTypes.number,
  useIntersectionObserver: PropTypes.bool,
  visibleByDefault: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  wrapperProps: PropTypes.object,
  placeholder: PropTypes.element,
};

export default Image;
