export const LANGUAGES = [
  {
    label: 'Java',
    value: 'JAVA',
  },
  {
    label: 'Python',
    value: 'PYTHON',
  },
  {
    label: 'C',
    value: 'C',
  },
  {
    label: 'C++',
    value: 'CPP',
  },
  {
    label: 'JavaScript',
    value: 'JAVASCRIPT',
  },
  {
    label: 'Ruby',
    value: 'RUBY',
  },
  {
    label: 'Dart',
    value: 'DART',
  },
  {
    label: 'Go',
    value: 'GO',
  },
  {
    label:"C#",
    value: "CSHARP"
  },
  {
    label: "PHP",
    value: "PHP"
  },
  {
    label: "Swift",
    value: "SWIFT"
  },
  {
    label: "TypeScript",
    value: "TYPESCRIPT"
  }
];
