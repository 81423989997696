import { alpha } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { filledInputClasses } from '@mui/material/FilledInput';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

export function textField(theme) {
  const placeholderColor = {
    enabled: theme.palette.content.primaryBlack3,
    focused: theme.palette.content.primaryBlack1,
    error: theme.palette.error.main,
    success: theme.palette.success.main,
    disabled: theme.palette.content.tertiary,
  };

  const borderColor = {
    enabled: theme.palette.border.primaryBlack3,
    hovered: theme.palette.border.primaryBlack2,
    focused: theme.palette.border.primaryAccent1,
    error: theme.palette.error.main,
    success: theme.palette.success.main,
    disabled: theme.palette.border.primaryBlack2,
  };

  const font = {
    input: {
      ...theme.typography.input,
      fontWeight: 400,
      color: theme.palette.content.primaryBlack1,
    },
  };

  return {
    // size variants

    // HELPER
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...theme.typography.caption,
          fontWeight: 400,
          marginTop: theme.spacing(1),
        },
      },
    },

    // LABEL
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...font.input,
          color: placeholderColor.enabled,
          [`&.${inputLabelClasses.shrink}`]: {
            ...font.label,
            fontWeight: 600,
            color: placeholderColor.focused,
            [`&.${inputLabelClasses.focused}`]: {
              color: placeholderColor.focused,
            },
            [`&.${inputLabelClasses.error}`]: {
              color: placeholderColor.error,
            },
            [`&.${inputLabelClasses.disabled}`]: {
              color: placeholderColor.disabled,
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: 'translate(12px, 6px) scale(0.75)',
            },
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          display: 'none',
        },
      },
    },

    // BASE
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          [`&.${inputBaseClasses.focused}`]: {
            borderColor: borderColor.focused,
          },
          [`&.${inputBaseClasses.disabled}`]: {
            '& svg': {
              color: placeholderColor.disabled,
            },
          },
          '&:hover': {
            borderColor: borderColor.hovered,
          },
        },
        input: {
          ...font.input,
          minWidth: 260,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.content.primaryBlack3,
          },
        },
      },
    },

    // STANDARD
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: placeholderColor.focused,
          },
          '&:after': {
            borderBottomColor: borderColor.focused,
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart': {
            marginLeft: 8, // Adjust the left margin for startAdornment
            marginRight: 0, // Reset the right margin for startAdornment
          },
          '&.MuiInputAdornment-positionEnd': {
            marginLeft: 0, // Reset the left margin for endAdornment
            marginRight: 8, // Adjust the right margin for endAdornment
          },
          '& svg': {
            color: theme.palette.content.primaryBlack3,
          },
        },
      },
    },

    // OUTLINED
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: 40,
          padding: 0,
          [`&.${outlinedInputClasses.hovered}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: borderColor.hovered,
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: borderColor.focused,
              borderWidth: '1px',
            },
          },
          [`&.${outlinedInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: borderColor.error,
            },
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: borderColor.disabled,
            },
          },
        },
        input: {
          padding: 8,
        },
        notchedOutline: {
          borderColor: borderColor.enabled,
          transition: theme.transitions.create(['border-color'], {
            duration: theme.transitions.duration.shortest,
          }),
        },
      },
    },

    // FILLED
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          [`&.${filledInputClasses.error}`]: {
            backgroundColor: alpha(theme.palette.error.main, 0.08),
            [`&.${filledInputClasses.focused}`]: {
              backgroundColor: alpha(theme.palette.error.main, 0.16),
            },
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        input: {
          padding: 8,
          minHeight: 40,
        },
      },
    },
  };
}
