import { Typography } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTimer } from 'react-timer-hook';
import { useResponsive } from 'src/hooks/use-responsive';
import useCodingInterviewContext from '../hooks/use-coding-interview-context';

const StopWatch = forwardRef((props, ref) => {
  // hooks
  const { setTimeTakenSeconds, questions ,onSubmit, currentQuestionIndex } = useCodingInterviewContext();

  const timeLimit = questions[currentQuestionIndex]?.timeLimit;

  // Calculate the expiry timestamp
  const expiryTimestamp = new Date();
  expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + timeLimit);

  const { seconds, minutes, hours, isRunning, start, pause, resume, restart, totalSeconds } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn('Timer has expired');
      // onSubmit(currentQuestionIndex);
    }
  });

  const mdUp = useResponsive('up', 'md');

  // variables
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes,
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  useImperativeHandle(ref, () => ({
    totalSeconds,
  }));

  // effects
  useEffect(() => {
    const elapsedSeconds = timeLimit * 60 - totalSeconds;
    setTimeTakenSeconds(elapsedSeconds);
  }, [totalSeconds, setTimeTakenSeconds, timeLimit]);

  return (
    <Typography
      variant={mdUp ? 'subheading2' : 'caption'}
      fontSize={{
        xs: '12px',
        md: '16px',
      }}
      fontWeight={400}
    >
      {formattedTime}
    </Typography>
  );
});

export default StopWatch;

StopWatch.propTypes = {};
