import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

export default function Animation({
  height = 100,
  width = 100,
  animationData,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
      style={{
        margin: 0,
      }}
    />
  );
}

Animation.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  animationData: PropTypes.object,
};
