// export const BACKEND_URL = 'http://localhost:5000';
export const BACKEND_URL = process.env.REACT_APP_HIRE_HOST_API;
export const PY_API_URL = process.env.REACT_APP_PY_API_URL;

export const urlMap = {
  // user
  login: `${BACKEND_URL}/api/v1/user/auth/login`,
  gettingStarted: `${BACKEND_URL}/api/v1/user/auth/started`,
  adminDetails: `${BACKEND_URL}/api/v1/user/details`,
  getUserDetails: `${BACKEND_URL}/api/v1/user`,
  forgotPassword: `${BACKEND_URL}/api/v1/user/auth/forgot`,
  updateUserDetails: `${BACKEND_URL}/api/v1/user/auth/update`,

  // orgs
  checkOrgHandleAvailable: `${BACKEND_URL}/api/v1/organization/handle/available/check`,
  createOrganization: `${BACKEND_URL}/api/v1/organization/create`,
  getCreditDetails: `${BACKEND_URL}/api/v1/credit/{org-id}/details`,
  orgUpdate: `${BACKEND_URL}/api/v1/organization/{org-id}/update`,
  getOrganizationDetails: `${BACKEND_URL}/api/v1/organization/{org-id}/details`,
  resetTemplate: `${BACKEND_URL}/api/v1/organization/{org-id}/reset_email_template`,

  // job
  getJobDetails: `${BACKEND_URL}/api/v1/{org-id}/job-opening/{job-opening-id}`,
  interviewRoles: `${BACKEND_URL}/api/v1/{org-id}/job-opening`,
  createRole: `${BACKEND_URL}/api/v1/{org-id}/job-opening`,
  editRole: `${BACKEND_URL}/api/v1/{org-id}/job-opening/{job-id}/edit`,
  jobSuggestion: `${BACKEND_URL}/api/v1/{org-id}/jobs`,
  skillSuggestion: `${BACKEND_URL}/api/v1/{org-id}/jobs/skills`,
  getJobDescription: `${BACKEND_URL}/api/v1/{org-id}/jobs/job_description`,
  getPublicApplyLink: `${BACKEND_URL}/api/v1/shortlink/job/{job-id}/create`,
  getPublicLinkDetails: `${BACKEND_URL}/api/v1/shortlink/{link-id}/details`,
  getPublicJobDetails: `${BACKEND_URL}/api/v1/{org-id}/job-opening/{job-id}/public`,
  createInterviewLinkPublic: `${BACKEND_URL}/api/v1/{job-id}/interview/public`,
  publishUnpublishLink: `${BACKEND_URL}/api/v1/shortlink/job/{job-id}/{publish-query}`,
  createPrescreeningQues: `${BACKEND_URL}/api/v1/{org-id}/{job-id}/packet/prescreen`,
  getPrescreeningQues: `${BACKEND_URL}/api/v1/prescreen/{job-id}/start`,
  getPrescreeningResponse: `${BACKEND_URL}/api/v1/prescreen/{job-id}/submit`,

  // candidates
  getCandidates: `${BACKEND_URL}/api/v1/candidates/{org-id}/list?limit={limit}&page_number={page_number}`,
  getCandidatesByJobId: `${BACKEND_URL}/api/v1/{org-id}/job-opening/{job-opening-id}/candidates?limit={limit}&page_number={page_number}`,
  getCandidateDetails: `${BACKEND_URL}/api/v1/candidates/{org-id}/{candidate-id}`,
  getCandidateDetailsByJobId: `${BACKEND_URL}/api/v1/{org-id}/job-opening/{job-opening-id}/candidate/{candidate-id}`,
  addCandidate: `${BACKEND_URL}/api/v1/candidates/{org-id}/create`,
  editCandidateDetails: `${BACKEND_URL}/api/v1/candidates/{org-id}/{candidate-id}/edit`,
  getPreScreeningQuestions: `${BACKEND_URL}/api/v1/prescreen/{job-opening-id}/user/{user-id}/result`,

  // interview
  getInterviews: `${BACKEND_URL}/api/v1/{job-opening-id}/interview`,
  createInterview: `${BACKEND_URL}/api/v1/{job-opening-id}/interview`,
  bulkUpload: `${BACKEND_URL}/api/v1/utility/{job-opening-id}/bulk-upload`,

  createPacket: `${BACKEND_URL}/api/v1/{org-id}/{job-opening-id}/packet`,
  editPacket: `${BACKEND_URL}/api/v1/{org-id}/{job-opening-id}/packet`,
  getPacket: `${BACKEND_URL}/api/v1/{org-id}/{job-opening-id}/packet`,
  regenerateInterviewUrl: `${BACKEND_URL}/api/v1/{job-opening-id}/interview/{interview-id}/reset`,

  editInterviewQuestion: `${BACKEND_URL}/api/v1/{org-id}/{job-opening-id}/packet`,
  sendEmailInterview: `${BACKEND_URL}/api/v1/{job-opening-id}/interview/email`,
  evaluateSolution: `${BACKEND_URL}/api/v1/interview/{interview-id}/{question-id}/submit`,
  getInterviewDetails: `${BACKEND_URL}/api/v1/interview/{interview-id}/details`,
  acceptTermsAndPolicy: `${BACKEND_URL}/api/v1/interview/{interview-id}/accept-terms`,
  uploadInterviewLogs: `${BACKEND_URL}/api/v1/interview/{interview-id}/debug_log`,

  // interview client
  evaluate: `${BACKEND_URL}/api/v1/interviewclient/transcribe`,
  result: `${BACKEND_URL}/api/v1/interviewclient/result`,
  putScreenRecord: `${BACKEND_URL}/api/v1/interview/{interview-id}/{question-id}/screenrecording`,
  startInterview: `${BACKEND_URL}/api/v1/interview/{interview-id}/start?token={token-id}`,
  endInterview: `${BACKEND_URL}/api/v1/interview/{interview-id}/end`,
  getInterviewResult: `${BACKEND_URL}/api/v1/{job-opening-id}/interview/{interview-id}`,
  editInterviewDetails: `${BACKEND_URL}/api/v1/{job-opening-id}/interview/{interview-id}`,

  // coding interview
  getCodingInterview: `${BACKEND_URL}/api/v1/coding/coding-interview/{coding-interview-id}`,
  startCodingInterview: `${BACKEND_URL}/api/v1/coding/coding-interview/{coding-interview-id}/start`,
  submitCodingInterview: `${BACKEND_URL}/api/v1/coding/coding-interview/{coding-interview-id}/submit`,
  partialSubmitCodingInterview: `${BACKEND_URL}/api/v1/coding/coding-interview/{coding-interview-id}/part-submit`,
  codeInvoke: `${BACKEND_URL}/api/v1/coding/coding-interview/{coding-interview-id}/code-invoke`,

  // two way interview
  twoWayInterview:{
    details: `${BACKEND_URL}/api/v1/two-way-interview/{interview-id}`,
    start: `${BACKEND_URL}/api/v1/two-way-interview/{interview-id}/start`,
    createSession: `${PY_API_URL}/services/two-way-interview/{interview-id}/create-session`,
    getMessages: `${PY_API_URL}/services/two-way-interview/{interview-id}/messages`,
    sendMessage: `${PY_API_URL}/services/two-way-interview/{interview-id}/send-message`,
    submit: `${BACKEND_URL}/api/v1/two-way-interview/{interview-id}/submit`,
    partSubmit: `${BACKEND_URL}/api/v1/two-way-interview/{interview-id}/part-submit`,
  },

  // report
  generateReport: `${BACKEND_URL}/api/v1/report/{org-id}/create`,
  getReports: `${BACKEND_URL}/api/v1/report/{org-id}/list`,
};
