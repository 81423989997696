import { useContext } from 'react';
import CodingInterviewContext from '../context/coding-interview-context';

export default function useCodingInterviewContext() {
  const context = useContext(CodingInterviewContext);

  if (!context)
    throw new Error(
      'useCodingInterviewContext context must be use inside CodingInterviewProvider',
    );

  return context;
}
