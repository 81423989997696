import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getInterviewDetails } from 'src/services/interview.service';
import PropTypes from 'prop-types';
import Loader from '../loader';
import {
  CONGRATULATIONS_PAGE,
  INSTRUCTIONS_PAGE,
  INTERVIEW_RUN_PAGE,
  JOB_DETAILS_PAGE,
  PERMISSIONS_PAGE,
  PRE_SCREENING_PAGE,
  TIMER_PAGE,
} from '../sections/constants';
import JobDetailsPage from '../sections/job-details-page';
import InstructionsPage from '../sections/instructions-page';
import PermissionsPage from '../sections/permissions-page';
import Timer from '../components/timer';
import InterviewRunPage from '../sections/interview-run-page';
import Congratulations from '../sections/congratulations';
import InterviewAlreadyCompleted from '../sections/interview-already-completed';
import PrescreeningPage from '../sections/prescreening';
import InterviewExpired from '../sections/interview-expired';

function usePreloadImage(imageUrl) {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = imageUrl;
    link.as = 'image';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [imageUrl]);
}

const InterviewView = ({ interviewId, tokenId }) => {
  // hooks
  usePreloadImage('/assets/illustrations/woman-working-on-a-computer.svg');
  usePreloadImage('/assets/illustrations/lady-waving.svg');

  // states
  const [loader, setLoader] = useState(true);
  const [company, setCompany] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [jobId, setJobId] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [location, setLocation] = useState('Banglore');
  const [founderMessage, setFounderMessage] = useState(null);
  const [pageState, setPageState] = useState(JOB_DETAILS_PAGE);
  const [isAlreadyCompleted, setIsAlreadyCompleted] = useState(false);
  const [isInterviewExpired, setIsInterviewExpired] = useState(false);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [isTermsAndPolicyAccepted, setIsTermsAndPolicyAccepted] =
    useState(null);

  // effects
  useEffect(() => {
    const callerFunc = async () => {
      try {
        const response = await getInterviewDetails(interviewId, tokenId);

        if (response?.success) {
          setCompany(response.body.org_name);
          setCompanyLogo(response?.body?.org_logo_url);
          setJobId(response?.body?.job_opening_id);
          setCandidateName(response?.body?.candidate_name);
          setJobRole(response?.body?.job_name);
          setIsAlreadyCompleted(
            ['COMPLETED', 'EVALUATED'].includes(
              response?.body?.interview_status,
            ),
          );
          setIsTermsAndPolicyAccepted(response?.body?.is_terms_accepted);
          setIsInterviewExpired(response?.body?.is_expired);
        } else if (response?.response?.status === 401) {
          setIsInterviewExpired(true);
        }
      } catch (err) {
        console.log('err:', err);
      } finally {
        setLoader(false);
      }
    };

    callerFunc();
  }, [interviewId, tokenId]);

  return loader ? (
    <Loader
      companyLogo={companyLogo}
      loader={loader}
      setLoader={setLoader}
      loaderMessage="Loading ..."
    />
  ) : (
    <>
      {isInterviewExpired && !isAlreadyCompleted && (
        <InterviewExpired
          companyLogo={companyLogo}
          jobRole={jobRole}
          location={location}
          company={company}
        />
      )}
      {isAlreadyCompleted && (
        <InterviewAlreadyCompleted
          companyLogo={companyLogo}
          jobRole={jobRole}
          location={location}
          company={company}
        />
      )}
      {!isAlreadyCompleted && !isInterviewExpired && (
        <Stack>
          {pageState === JOB_DETAILS_PAGE && (
            <JobDetailsPage
              company={company}
              companyLogo={companyLogo}
              jobRole={jobRole}
              location={location}
              candidateName={candidateName}
              founderMessage={founderMessage}
              setPageState={setPageState}
            />
          )}
          {pageState === INSTRUCTIONS_PAGE && (
            <InstructionsPage
              tokenId={tokenId}
              companyLogo={companyLogo}
              interviewId={interviewId}
              setPageState={setPageState}
              isTermsAndPolicyAccepted={isTermsAndPolicyAccepted}
              setIsTermsAndPolicyAccepted={setIsTermsAndPolicyAccepted}
            />
          )}
          {pageState === PRE_SCREENING_PAGE && (
            <PrescreeningPage
              companyLogo={companyLogo}
              jobId={jobId}
              setPageState={setPageState}
            />
          )}
          {pageState === PERMISSIONS_PAGE && (
            <PermissionsPage
              companyLogo={companyLogo}
              setPageState={setPageState}
            />
          )}
          {pageState === TIMER_PAGE && (
            <Timer
              token_id={tokenId}
              interview_id={interviewId}
              setInterviewDetails={setInterviewDetails}
              companyLogo={companyLogo}
              setPageState={setPageState}
            />
          )}
          {pageState === INTERVIEW_RUN_PAGE && (
            <InterviewRunPage
              interviewDetails={interviewDetails}
              interviewId={interviewId}
              companyLogo={companyLogo}
              setPageState={setPageState}
            />
          )}
          {pageState === CONGRATULATIONS_PAGE &&
            // <Congratulations companyLogo={companyLogo} />
            (window.location.href = `${process.env.REACT_APP_INTERVIEW_CLIENT_URL}/congratulations`)}
        </Stack>
      )}
    </>
  );
};

export default InterviewView;

InterviewView.propTypes = {
  interviewId: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
};
