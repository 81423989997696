import { useEffect, useState } from 'react';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_PERMISSION_DENIED } from 'src/analytics/trackevents';

const useCameraMicrophone = () => {
  const [permissionAllowed, setPermissionAllowed] = useState({
    audio: null,
    video: null,
    screen: null,
  });
  const [errors, setErrors] = useState(null);
  const [askedPermission, setAskedPermission] = useState({
    audio: false,
    video: false,
    screen: false,
  });

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;

        if (typeof navigator.mediaDevices.getUserMedia !== 'undefined') {
          await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });

          setPermissionAllowed(_allowed => ({
            ..._allowed,
            audio: true,
            video: true,
          }));

          setAskedPermission(_askedPermission => ({
            ..._askedPermission,
            audio: true,
            video: true,
          }));
        }
      } catch (err) {
        trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, { err: err.message });
        setPermissionAllowed(_allowed => ({
          ..._allowed,
          audio: false,
          video: false,
        }));
        setErrors({
          audio: true,
          video: true,
        });
        setAskedPermission(_askedPermission => ({
          ..._askedPermission,
          audio: true,
          video: true,
        }));
      }
    };

    if (askedPermission.audio === false || askedPermission.video === false) {
      getUserMedia();
    }
  }, [askedPermission.audio, askedPermission.video]);

  return {
    permissionAllowed,
    errors,
    askedPermission,
    setPermissionAllowed,
    setAskedPermission,
    setErrors,
  };
};

export default useCameraMicrophone;
