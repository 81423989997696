import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSettingsContext } from 'src/components/settings';
import { useSnackbar } from 'notistack';
import { useQuery } from 'src/hooks/use-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  getPrescreeningQues,
  postPrescreeningResponse,
} from 'src/services/role.service';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_INTERVIEW_PRESCREEN_SKIP,
  EVENT_INTERVIEW_PRESCREEN_SUBMIT,
} from 'src/analytics/trackevents';
import PropTypes from 'prop-types';
import { useResponsive } from 'src/hooks/use-responsive';
import { PERMISSIONS_PAGE } from './constants';
import InterviewHeader from '../interview-header';
import Scrollbar from 'src/components/scrollbar';
import Loader from 'src/components/loader';
import { LoadingScreen } from 'src/components/loading-screen';
import { NumericFormatCustom } from 'src/components/hook-form/rhf-text-field';

const PrescreeningPage = ({ setPageState, jobId, companyLogo }) => {
  // hooks
  const { enqueueSnackbar } = useSnackbar();
  const params = useQuery();
  const mdUp = useResponsive('up', 'md');

  // variables
  const tokenId = params.get('token');
  const initialSchema = Yup.object().shape({});

  // states
  const [loader, setLoader] = useState(true);
  const [preScreenData, setPrescreenData] = useState([]);
  const [validationSchema, setValidationSchema] = useState(initialSchema);
  const [defaultValues, setDefaultValues] = useState({});

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    trigger,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const skipPrescreening = () => {
    console.log('skipping prescreening');
    setPrescreenData(null);
    // setPrescreeningSkipped(true);
    setPageState(PERMISSIONS_PAGE);
    trackEvent(EVENT_INTERVIEW_PRESCREEN_SKIP, {});
  };

  useEffect(() => {
    const caller = async () => {
      try {
        setLoader(true);
        const res = await getPrescreeningQues(jobId, tokenId);

        if (res.success) {
          if (
            res.body.prescreen_status === 'COMPLETED' ||
            res.body.questions.length === 0
          ) {
            skipPrescreening();
          } else {
            setPrescreenData(res.body.questions);
            let updatedSchema;
            const schemaObject = {};
            const defaultValueDum = {};

            res.body?.questions?.map((field, index) => {
              const { response_type } = field;

              switch (response_type) {
                case 'NUMERIC':
                  schemaObject[`Question${index}`] = Yup.number().required(
                    `Value must be a number`,
                  );
                  defaultValueDum[`Question${index}`] = 0;
                  break;
                case 'TEXT':
                  schemaObject[`Question${index}`] = Yup.string().required(
                    `Please enter valid value`,
                  );
                  defaultValueDum[`Question${index}`] = '';
                  break;
                case 'BOOLEAN':
                  schemaObject[`Question${index}`] = Yup.boolean().required(
                    `Value must be a Yes / No`,
                  );
                  defaultValueDum[`Question${index}`] = '';
                  break;
                default:
                  break;
              }
            });

            updatedSchema = Yup.object().shape(schemaObject);
            setDefaultValues(defaultValueDum);
            setValidationSchema(updatedSchema);
          }
        } else {
          throw new Error(res.message);
        }
      } catch (err) {
        console.log(err);
        console.log('Error getting prescreen questions');
        skipPrescreening();
      } finally {
        setLoader(false);
      }
    };
    caller();
  }, []);

  const submitHandler = async e => {
    e.preventDefault();
    const data = getValues();
    trackEvent(EVENT_INTERVIEW_PRESCREEN_SUBMIT, {});

    const validated = await trigger();

    if (validated) {
      if (preScreenData && preScreenData.length > 0) {
        const dumm = {};
        preScreenData.map((item, index) => {
          dumm[item.question_id] = data[`Question${index}`];
        });
        const payload = { response_map: dumm };
        setLoader(true);
        const res = await postPrescreeningResponse(
          payload,
          jobId,
          tokenId,
        ).catch(err => {
          setLoader(false);
          console.log(err);
          enqueueSnackbar({
            variant: 'error',
            message:
              'Technical problem in submitting prescreening form, please contact HR',
          });
        });
        console.log(res);
        if (res.success) {
          enqueueSnackbar('Prescreening form has been submitted');
          //   setPrescreeningSkipped(true);
          setPageState(PERMISSIONS_PAGE);
        }
        setLoader(false);
      } else {
        setPageState(PERMISSIONS_PAGE);
      }
    }
  };

  return (
    <>
      <InterviewHeader companyLogo={companyLogo} />
      {loader ? (
        <Stack height="100vh">
          <LoadingScreen />
        </Stack>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          <Stack
            mt={{ md: '80px', xs: '60px' }}
            px="16px"
            py="16px"
            height={{
              xs: 'calc(100vh - 80px - 60px)',
              md: 'calc(100vh - 80px - 80px)',
            }}
            maxHeight={{
              xs: 'calc(100vh - 80px - 60px)',
              md: 'calc(100vh - 80px - 80px)',
            }}
            overflow="auto"
            mx="auto"
            maxWidth="800px"
          >
            <Scrollbar
              sx={{
                paddingRight: '16px',
              }}
            >
              {preScreenData ? (
                preScreenData.map((item, index) => (
                  <Stack spacing={2} key={index} sx={{ marginBottom: '1rem' }}>
                    <Typography>
                      Question {index + 1}. {item.text}
                    </Typography>
                    {item.response_type === 'NUMERIC' ? (
                      <TextField
                        placeholder="Numbers only"
                        name={`Question${index}`}
                        value={getValues()[`Question${index}`]}
                        error={Boolean(errors[`Question${index}`])}
                        helperText={errors[`Question${index}`]?.message}
                        onChange={e => {
                          setValue(`Question${index}`, e.target.value);
                        }}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                      />
                    ) : null}
                    {item.response_type === 'BOOLEAN' ? (
                      <FormControl fullWidth>
                        {/* <InputLabel id="Yes / No">Yes / No</InputLabel> */}
                        <Select
                          labelId="Yes / No"
                          name={`Question${index}`}
                          //   label="Yes / No"

                          value={getValues()[`Question${index}`]}
                          onChange={e => {
                            setValue(`Question${index}`, e.target.value);
                          }}
                          error={Boolean(errors[`Question${index}`])}
                        >
                          <MenuItem key="true" value="TRUE">
                            Yes
                          </MenuItem>
                          <MenuItem key="false" value="FALSE">
                            No
                          </MenuItem>
                        </Select>
                        {errors[`Question${index}`] ? (
                          <FormHelperText style={{ color: '#ff7150' }}>
                            {errors[`Question${index}`]?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    ) : null}
                    {item.response_type === 'TEXT' ? (
                      <TextField
                        type="text"
                        name={`Question${index}`}
                        value={getValues()[`Question${index}`]}
                        onChange={e => {
                          setValue(`Question${index}`, e.target.value);
                        }}
                        multiline
                        placeholder="Text only"
                        error={Boolean(errors[`Question${index}`])}
                        helperText={errors[`Question${index}`]?.message}
                      />
                    ) : null}
                  </Stack>
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'center',
                    height: '60vh',
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: 'center', marginTop: '-30px' }}>
                      Technical problem in prescreening form, please contact HR.
                    </h2>
                  </div>
                </div>
              )}
            </Scrollbar>
          </Stack>
          <Stack
            px="16px"
            py="20px"
            width="100%"
            position="fixed"
            bottom="0"
            left="0"
            right="0"
          >
            <Button
              fullWidth
              size="medium"
              variant="contained"
              sx={{
                mx: 'auto',
                maxWidth: '800px',
              }}
              onClick={submitHandler}
            >
              Continue
            </Button>
          </Stack>
        </form>
      )}
    </>
  );
};

export default PrescreeningPage;

PrescreeningPage.propTypes = {
  setPageState: PropTypes.func.isRequired,
  jobId: PropTypes.string.isRequired,
  companyLogo: PropTypes.string.isRequired,
};
