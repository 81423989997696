import { Typography } from '@mui/material';
import React from 'react';
import { useTimer } from 'react-timer-hook';

const StopWatch = () => {
  const timeLimit = 60;
  const expiryTimestamp = new Date();
  expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + timeLimit);

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn('Timer has expired');
    },
  });

  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;

  return (
    <Typography display="none" variant="caption">
      {formattedTime}
    </Typography>
  );
};

export default StopWatch;

StopWatch.propTypes = {};
