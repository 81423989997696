import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, Stack, Typography, IconButton, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import SvgColor from 'src/components/svg-color/svg-color';
import PropTypes from 'prop-types';
import {
  postEndInterviewData,
  postStartInterviewData,
} from 'src/services/interview.service';
import { useQuery } from 'src/hooks/use-query';
import { useBoolean } from 'src/hooks/use-boolean';
import Loader from 'src/components/loader';
import {
  uploadAudioToS3,
  uploadImageToS3,
  uploadVideoToS3,
} from 'src/services/aws.service';
import { postEvaluateSolution } from 'src/services/interviewclient.service';
import { uploadInterviewLogs } from 'src/services/interview.service';
import { RecordingWaveAnimation } from 'src/assets/animations/components/recording-wave';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';
import {
  EVENT_INTERVIEW_END_INTERVIEW_CLICKED,
  EVENT_INTERVIEW_NEXT_QUESTION_CLICKED,
} from 'src/analytics/trackevents';
import { trackEvent } from 'src/analytics/datatrack';
import { useReactMediaRecorder } from 'react-media-recorder';
import { INTERVIEW_VIDEO_RECORD } from 'src/config-global';
import Webcamera from '../components/webcam';
import StopWatch from '../components/stop-watch';
import {
  INACTIVE_AUDIO_STATUS,
  INITIAL_AUDIO_STATUS,
  RECORDING_AUDIO_STATUS,
} from '../hooks/use-audio-analyzer';
import AudioAnalyzerComponent from '../components/audio-analyzer-component';
import { CONGRATULATIONS_PAGE } from './constants';
import SubmittingPage from './submitting-page';
import { useMediaRecorder } from 'src/hooks/use-media-recorder';
import { uploadLogs } from 'src/utils/logger/get-logs';
import { getDeviceInfo } from 'src/utils/device-details/device-details';
import { VoiceMessageTimer } from './voice-message-timer';

// http://localhost:3040/interview-client-v3/65c3dee22cc9161d5ff07197?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1c2VyX3R5cGUiOiJJTlRFUlZJRVdFRSIsIm9yZ2FuaXphdGlvbl9pZCI6IjY1MDE2YWE4NDM2NDY5NWI1MmE5NDhlYiIsIm5hbWUiOiIiLCJpZCI6IjY1MzBkNGU5MzE4NDZkMThjZjllZjdiYSIsImV4cCI6MTcwOTkyNzM5NCwiZW1haWwiOiJhYmhpc2hla3phZGF2QGdtYWlsLmNvbSJ9.rCRGmxxw35maa7aEMJEmZ_dN_YDm2b0O5yIyN2qU6X_IBwwc_F4A0UOsUrD1l1sc5-HeIG6fX-RrwNRUanRvdw
const useStyles = makeStyles(theme => ({
  mainDiv: {
    padding: '1rem',
    marginBottom: '5.4rem',
    overflowY: 'auto',
    gap: '14px',
  },
  recordingTimerDiv: {
    padding: '4px 12px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    borderRadius: 35,
    background: theme.palette.content.primaryWhite,
  },
  audioAnalyzer: {
    display: 'none',
  },
}));

const Questions = ({
  questions,
  currentQuestion,
  isQuestionSubmitting,
  onNextQuestion,
  totalQuestions,
  isQuestionsLoading,
  webCamHidden,
}) => {
  // functions
  const handleOnClick = () => {
    onNextQuestion();
  };

  const mdUp = useResponsive('up', 'md');

  return isQuestionsLoading.value ? (
    <Loader />
  ) : (
    <Stack
      height="100%"
      sx={{
        gap: webCamHidden ? '330px' : '90px',
        transition: theme =>
          theme.transitions.create(['gap'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
          }),
      }}
    >
      <Stack>
        <Typography
          sx={{ lineHeight: 'normal' }}
          variant="subheading1"
          color={{
            xs: 'content.primaryWhite',
            md: 'content.primaryBlack1',
          }}
        >
          {questions[currentQuestion].question}
        </Typography>
      </Stack>
      {mdUp && (
        <Stack height="100%" width="100%">
          <Stack
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <Box sx={{ flexGrow: 1 }} />
            <VoiceMessageTimer />
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack>
            <LoadingButton
              loading={isQuestionSubmitting.value}
              loadingPosition="start"
              fullWidth
              size="large"
              variant="contained"
              onClick={handleOnClick}
              sx={{
                background: theme => ({
                  xs: theme.palette.background.primaryAccent1,
                  md: theme.palette.content.primaryBlack1,
                }),
              }}
            >
              {currentQuestion + 1 < totalQuestions
                ? 'Next Question'
                : 'Submit'}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  isQuestionSubmitting: PropTypes.object.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  isQuestionsLoading: PropTypes.object.isRequired,
  webCamHidden: PropTypes.bool,
};

const BottomDrawer = ({ open, webCamHidden, children }) => (
  <Drawer
    sx={{
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        transition: theme =>
          theme.transitions.create(['min-height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
          }),
        boxSizing: 'border-box',
        zIndex: 1,
      },
    }}
    PaperProps={{
      style: {
        background: `linear-gradient(
          180deg,
          rgba(22.03, 22.03, 22.03, 0.68) 0%,
          rgba(25.98, 25.98, 25.98, 0.61) 32%,
          rgba(30.8, 30.8, 30.8, 0.45) 54.5%,
          rgba(95.66, 95.66, 95.66, 0.17) 76.5%,
          rgba(0, 0, 0, 0) 92.5%,
          rgba(255, 255, 255, 0) 100%
        )`,
        border: 'none',
      },
    }}
    variant="persistent"
    anchor="top"
    open={open}
  >
    {open && children}
  </Drawer>
);

BottomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  webCamHidden: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const InterviewRunPage = ({ setPageState, companyLogo, interviewId }) => {
  // refs
  const mobileSubmitBtnRef = useRef(null);

  // hooks
  const classes = useStyles();
  const searchParams = useQuery();
  const mdUp = useResponsive('up', 'md');
  const token = searchParams.get('token');

  // refs
  const stopwatchRef = useRef(null);
  const webcamRef = useRef(null);

  // states
  const [webCamHidden, setWebCamHidden] = useState(false);
  const [audioStatus, setAudioStatus] = useState(INITIAL_AUDIO_STATUS);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const isQuestionsLoading = useBoolean(true);
  const [isQuestionsFetched, setIsQuestionsFetched] = useState(false);
  const isQuestionSubmitting = useBoolean(false);
  const [candidateCaptures, setCandidateCaptures] = useState([]);
  const [isUploaded, setIsUploaded] = useState({
    audio: false,
    video: false,
    candidateCaptures: false,
  });
  const [isQuestionItemsUploaded, setIsQuestionItemsUploaded] = useState({
    audio: false,
    video: false,
  });
  const [videoBlobTimeout, setVideoBlobTimeout] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const isSubmitting = useBoolean(false);

  // constants
  const videoWidth = mdUp ? 640 : 426;
  const videoHeight = mdUp ? 360 : 240;
  const aspectRatio = mdUp ? 1.778 : 0.5625;

  // functions
  const getAudioUrl = async (audioBlob, _currentQuestionId) => {
    try {
      const folder = `interview/${interviewId}/question/${_currentQuestionId}`;
      return uploadAudioToS3(audioBlob, folder);
    } catch (err) {
      console.log(err);
    }

    return null;
  };

  const getVideoUrl = useCallback(
    async (videoBlob, _currentQuestionId) => {
      try {
        const folder = `interview/${interviewId}/question/${_currentQuestionId}`;
        return uploadVideoToS3(videoBlob, folder);
      } catch (err) {
        console.log(err);
      }

      return null;
    },
    [interviewId],
  );

  const onStopAudio = async (
    audioBlob,
    lastQuestionCounter,
    lastQuestionId,
  ) => {
    try {
      setIsQuestionItemsUploaded(prevProps => ({
        ...prevProps,
        audio: true,
      }));

      if (lastQuestionCounter + 1 === totalQuestions) isSubmitting.onTrue();

      getAudioUrl(audioBlob, lastQuestionId)
        .then(audioUrl => {
          const payload = {
            audio_file_url: audioUrl,
          };

          postEvaluateSolution(payload, lastQuestionId, interviewId, token)
            .then(response => {
              if (lastQuestionCounter + 1 === totalQuestions) {
                setIsUploaded(prevProps => ({
                  ...prevProps,
                  audio: true,
                }));
              }

              if (response.success) {
                console.log('Audio Submitted Successfully');
              } else {
                console.log('Audio Submission Failed');
                console.log(response.message);
              }
            })
            .catch(err => {
              console.log('error in posting to API');
              console.log(err);
            });
        })
        .catch(err => {
          console.log('error in uploading to file storage');
          console.log(err);
        });
    } catch (err) {
      console.log('Error in processing audio blob');
      console.log(err);
    }
  };

  const onStopVideo = async (
    videoBlob,
    lastQuestionCounter,
    lastQuestionId,
  ) => {
    try {
      setIsQuestionItemsUploaded(prevProps => ({
        ...prevProps,
        video: true,
      }));

      getVideoUrl(videoBlob, lastQuestionId)
        .then(videoUrl => {
          const payload = {
            video_record_url: videoUrl,
          };

          postEvaluateSolution(payload, lastQuestionId, interviewId, token)
            .then(response => {
              if (lastQuestionCounter + 1 === totalQuestions) {
                setIsUploaded(prevProps => ({
                  ...prevProps,
                  video: true,
                }));
              }

              if (response.success) {
                console.log('Video Submitted Successfully');
              } else {
                console.log('Video Submission Failed');
                console.log(response.message);
              }
            })
            .catch(err => {
              console.log('error in posting to API');
              console.log(err);
            });
        })
        .catch(err => {
          console.log('error in uploading to file storage');
          console.log(err);
        });
    } catch (err) {
      console.log('Error in processing video blob');
      console.log(err);
    }
  };

  const { startRecording, stopRecording, permission } = useMediaRecorder({
    video: {
      width: videoWidth,
      height: videoHeight,
      aspectRatio,
      frameRate: 12,
      facingMode: 'user',
    },
    onVideoStop: async (videoBlob, _currentQuestion, _currentQuestionId) => {
      console.log('Video Recording Stopped');
      onStopVideo(videoBlob, _currentQuestion, _currentQuestionId);
    },
  });

  // functions
  const takeScreenshot = async folderName => {
    try {
      const imageBase64 = webcamRef.current.getScreenshot();

      if (imageBase64) {
        const response = await fetch(imageBase64);
        const blob = await response.blob();
        const url = await uploadImageToS3(blob, folderName);
        return url;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  };

  const uploadCandidateCaptures = useCallback(
    async (currentCandidateCaptures, _currentQuestionId) => {
      try {
        const payload = {
          candidate_captures: currentCandidateCaptures.map(capture => ({
            interview_time: capture.interviewTime,
            image_url: capture.imageUrl,
          })),
        };

        const response = await postEvaluateSolution(
          payload,
          _currentQuestionId,
          interviewId,
          token,
        );

        if (response.success) {
          console.log('Candidate captures uploaded successfully');
        } else throw new Error(response.message);
      } catch (err) {
        console.log(err);
      }
    },
    [interviewId, token],
  );

  const getQuestions = useCallback(async () => {
    isQuestionsLoading.onTrue();

    try {
      if (interviewId && token) {
        const response = await postStartInterviewData(interviewId, token);

        if (response.success) {
          if (response?.body) {
            const _questions = response.body.map((question, index) => ({
              id: question.question_id,
              question: question.text,
            }));

            setQuestions(_questions);
            setTotalQuestions(_questions.length);
            setCurrentQuestionId(_questions[0]?.id);
            setIsQuestionsFetched(true);
          }
        } else throw new Error(response.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      isQuestionsLoading.onFalse();
    }
  }, [interviewId, token]);

  const onNextQuestion = async () => {
    try {
      isQuestionSubmitting.onTrue();

      // stop audio & video recording
      setAudioStatus(INACTIVE_AUDIO_STATUS);
      if (INTERVIEW_VIDEO_RECORD === 'true') {
        stopRecording(currentQuestionId, currentQuestion);
      }

      // timeout for video blob
      setTimeout(() => {
        setVideoBlobTimeout(true);
      }, 3000);

      // candidate captures
      if (currentQuestion + 1 === totalQuestions) {
        await uploadCandidateCaptures(candidateCaptures, currentQuestionId);
        getLogs();
        setIsUploaded(prevProps => ({
          ...prevProps,
          candidateCaptures: true,
        }));

        try {
          let result = await uploadLogs(interviewId);
          if (result !== '') {
            const payload = {
              log_file_path: result,
              debug_data: getDeviceInfo(),
            };
            console.log(payload);
            const uploadResult = await uploadInterviewLogs(
              interviewId,
              payload,
              token,
            );
            if (uploadResult.success) {
              console.log('Uploaded logs successfully');
            } else {
              console.log('Error uploading logs');
            }
          } else {
            console.log('Error in getting logs URL');
          }
        } catch (error) {
          console.error('An error occurred in Logs:', error);
        }
        trackEvent(EVENT_INTERVIEW_END_INTERVIEW_CLICKED, {});
      } else {
        setTimeout(() => {
          const nextQuestionId = questions[currentQuestion + 1].id;
          const nextQuestionIndex = currentQuestion + 1;

          if (currentQuestion + 1 < totalQuestions) {
            setCurrentQuestionId(questions[currentQuestion + 1].id);
            setCurrentQuestion(prevProps => prevProps + 1);
          }

          isQuestionSubmitting.onFalse();

          setAudioStatus(RECORDING_AUDIO_STATUS);
          startRecording(nextQuestionId, nextQuestionIndex);
        }, 1000);

        uploadCandidateCaptures(candidateCaptures, currentQuestionId);
        trackEvent(EVENT_INTERVIEW_NEXT_QUESTION_CLICKED, {});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initVideoRecording = () => {
    startRecording(currentQuestionId, currentQuestion);
  };

  // effects
  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (permission && INTERVIEW_VIDEO_RECORD === 'true' && isQuestionsFetched) {
      initVideoRecording();
      setAudioStatus(RECORDING_AUDIO_STATUS);
    }
  }, [permission, INTERVIEW_VIDEO_RECORD, isQuestionsFetched]);

  // upload candidate captures
  useEffect(() => {
    if (!currentQuestionId) return;

    const captureCandidateImages = async () => {
      try {
        if (candidateCaptures.length >= 3) {
          clearInterval(intervalId);
          return;
        }
        if (
          !stopwatchRef ||
          !stopwatchRef.current ||
          !stopwatchRef.current.totalSeconds
        ) {
          clearInterval(intervalId);
          return;
        }

        const folder = `interview/${interviewId}/question/${currentQuestionId}`;
        const imageUrl = await takeScreenshot(folder);
        let interviewTime = 0;
        if (stopwatchRef?.current?.totalSeconds) {
          interviewTime = stopwatchRef?.current?.totalSeconds;
          setCandidateCaptures(prev => [
            ...prev,
            {
              interviewTime,
              imageUrl,
            },
          ]);
        }

        if (candidateCaptures.length + 1 === 3) {
          clearInterval(intervalId);
        }
      } catch (e) {
        console.log(e);
        clearInterval(intervalId);
      }
    };

    setTimeout(captureCandidateImages, 2000);

    const intervalId = setInterval(captureCandidateImages, 30000);

    // Cleanup function
    return () => clearInterval(intervalId);
  }, [questions, currentQuestionId, candidateCaptures.length, interviewId]);

  // interview submission
  useEffect(() => {
    if (
      currentQuestion + 1 === totalQuestions &&
      (INTERVIEW_VIDEO_RECORD === 'true' ? isUploaded.video : true) &&
      isUploaded.audio &&
      isUploaded.candidateCaptures
    ) {
      postEndInterviewData(interviewId, token)
        .then(response => {
          if (response.success) {
            setTimeout(() => {
              isSubmitting.onFalse();
              setPageState(CONGRATULATIONS_PAGE);
            }, 3000);
            console.log('Interview Ended Successfully');
          } else throw new Error(response.message);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [
    currentQuestion,
    interviewId,
    isUploaded.audio,
    isUploaded.video,
    isUploaded.candidateCaptures,
    setPageState,
    token,
    totalQuestions,
  ]);

  useEffect(() => {
    const element = mobileSubmitBtnRef.current;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return isSubmitting.value ? (
    <SubmittingPage />
  ) : (
    <>
      <AudioAnalyzerComponent
        className={classes.audioAnalyzer}
        audioStatus={audioStatus}
        onStopAudio={onStopAudio}
        questionIndex={currentQuestion}
        questionId={currentQuestionId}
      />
      <Stack
        sx={{
          marginTop: { xs: '0px', md: '0px' },
          height: { xs: '100vh', md: '100vh' },
          maxHeight: { xs: '100vh', md: '100vh' },
          width: '100%',
          position: 'relative',
          flexDirection: 'row',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: { xs: '100%', md: '50%' },
            height: '100%',
            bgcolor: theme => theme.palette.background.primaryBlack1,
          }}
        >
          <Stack
            sx={{
              width: '100%',
              position: 'absolute',
              display: { xs: 'none', md: 'flex' },
              padding: '16px',
              zIndex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack direction="row" alignItems="center" spacing="12px">
              <Stack>
                <SvgColor
                  sx={{
                    bgcolor: theme => theme.palette.content.primaryRed1,
                    width: '33px',
                    height: '33px',
                  }}
                  src="/assets/icons/interview-page/recording-red.svg"
                />
                <StopWatch ref={stopwatchRef} />
              </Stack>
              {!webCamHidden && <RecordingWaveAnimation size="medium" />}
            </Stack>

            <IconButton
              sx={{
                bgcolor: mdUp ? '#50505029' : 'none',
                backdropFilter: mdUp ? 'blur(19.18px)' : 'none',
              }}
              onClick={() => {
                setWebCamHidden(prev => !prev);
              }}
            >
              <Iconify
                color="content.primaryWhite"
                width={32}
                icon={`tabler:picture-in-picture-${
                  webCamHidden ? 'off' : 'on'
                }`}
              />
            </IconButton>
          </Stack>

          {mdUp && webCamHidden && (
            <Stack
              position="absolute"
              height="100%"
              width="100%"
              alignItems="center"
              justifyContent="center"
              top="0"
              left="0"
              bottom="0"
              right="0"
            >
              <RecordingWaveAnimation size="large" />
            </Stack>
          )}
          <Webcamera
            width={videoWidth}
            height={videoHeight}
            videoPreview={!webCamHidden}
            webcamRef={webcamRef}
          />
        </Stack>
        <Stack
          position="absolute"
          id="mobile-submit-btn"
          display={{
            xs: 'flex',
            md: 'none',
          }}
          bottom={0}
          width="100%"
          paddingX="16px"
          paddingY="24px"
        >
          <LoadingButton
            loading={isQuestionSubmitting.value}
            loadingPosition="start"
            fullWidth
            size="large"
            variant="contained"
            onClick={() => onNextQuestion()}
            sx={{
              background: theme => ({
                xs: theme.palette.background.primaryAccent1,
                md: theme.palette.content.primaryBlack1,
              }),
            }}
          >
            Submit
          </LoadingButton>
        </Stack>

        <Stack
          position="absolute"
          display={{
            xs: 'flex',
            md: 'none',
          }}
          bottom={0}
          width="100%"
          paddingX="16px"
          paddingY="24px"
        >
          <LoadingButton
            ref={mobileSubmitBtnRef}
            loading={isQuestionsLoading.value || isQuestionSubmitting.value}
            loadingPosition="start"
            fullWidth
            size="large"
            variant="contained"
            onClick={() => onNextQuestion()}
            sx={{
              background: theme => ({
                xs: theme.palette.background.primaryAccent1,
                md: theme.palette.content.primaryBlack1,
              }),
            }}
          >
            {isQuestionsLoading.value && 'Loading...'}
            {!isQuestionsLoading.value &&
              (currentQuestion + 1 < totalQuestions
                ? 'Next Question'
                : 'Submit')}
          </LoadingButton>
        </Stack>
        {mdUp && (
          <Stack
            sx={{ width: { xs: '100%', md: '50%' }, height: '100%' }}
            paddingY="40px"
            paddingX="16px"
            bgcolor={theme => theme.palette.background.primaryGrey2}
          >
            <Questions
              questions={questions}
              currentQuestion={currentQuestion}
              totalQuestions={totalQuestions}
              isQuestionsLoading={isQuestionsLoading}
              isQuestionSubmitting={isQuestionSubmitting}
              onNextQuestion={onNextQuestion}
            />
          </Stack>
        )}
      </Stack>
      {!mdUp && (
        <BottomDrawer open={drawerOpen} webCamHidden={webCamHidden}>
          <Stack
            sx={{
              width: '100%',
              padding: '8px 16px',
              zIndex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#161616ad',
              border: 'none',
            }}
          >
            <Stack direction="row" alignItems="center" spacing="12px">
              <Stack>
                <SvgColor
                  sx={{
                    bgcolor: theme => theme.palette.content.primaryRed1,
                    width: '27px',
                    height: '27px',
                  }}
                  src="/assets/icons/interview-page/recording-red.svg"
                />
                <StopWatch ref={stopwatchRef} />
              </Stack>
              <RecordingWaveAnimation size="small" width={14} height={14} />
            </Stack>
            {!mdUp && (
              <IconButton
                onClick={() => {
                  setWebCamHidden(prev => !prev);
                }}
              >
                <Iconify
                  color="#E8E8E8"
                  width={24}
                  icon={`tabler:picture-in-picture-${
                    webCamHidden ? 'off' : 'on'
                  }`}
                />
              </IconButton>
            )}
          </Stack>
          <Stack
            sx={{
              border: 'none',
              background: `linear-gradient(
                180deg,
                rgba(22.03, 22.03, 22.03, 0.68) 0%,
                rgba(25.98, 25.98, 25.98, 0.61) 32%,
                rgba(30.8, 30.8, 30.8, 0.45) 54.5%,
                rgba(95.66, 95.66, 95.66, 0.17) 76.5%,
                rgba(0, 0, 0, 0) 92.5%,
                rgba(255, 255, 255, 0) 100%
              )`,
              minHeight: '140px',
              maxHeight: '280px',
              overflowY: 'auto',
              paddingX: '16px',
              paddingTop: '20px',
              paddingBottom: '20%',
            }}
          >
            <Questions
              questions={questions}
              currentQuestion={currentQuestion}
              totalQuestions={totalQuestions}
              isQuestionsLoading={isQuestionsLoading}
              isQuestionSubmitting={isQuestionSubmitting}
              onNextQuestion={onNextQuestion}
              webCamHidden={webCamHidden}
            />
          </Stack>
        </BottomDrawer>
      )}
    </>
  );
};

InterviewRunPage.propTypes = {
  setPageState: PropTypes.func.isRequired,
  companyLogo: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default InterviewRunPage;
