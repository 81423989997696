import React from 'react';
import PropTypes from 'prop-types';
import CompatibilityCheckLayout from './layout';

export default function InternetCheck({
  internetCompatible,
  isInternetCompatibliityChecking,
}) {
  return (
    <CompatibilityCheckLayout
      icon="tabler:world"
      title="Internet compatibility"
      description="Checking if your internet is compatible for the interview or not."
      isLoading={isInternetCompatibliityChecking}
      success={internetCompatible}
      errorMessage="Minimum speed required is 2 Mbps."
    />
  );
}

InternetCheck.propTypes = {
  internetCompatible: PropTypes.bool,
  isInternetCompatibliityChecking: PropTypes.bool,
};
