import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import PropTypes from 'prop-types';

const VideoTimer = ({
  onSubmit,
  shouldEndInterview,
  setShouldEndInterview,
}) => {
  const timeLimit = 60;
  const expiryTimestamp = new Date();
  expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + timeLimit);

  const {totalSeconds } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn('Timer has expired');
      setShouldEndInterview(true);
    },
  });

  const elapsedSeconds = timeLimit * 60 - totalSeconds;

  useEffect(() => {
    if (!shouldEndInterview && elapsedSeconds > 0 && elapsedSeconds % 20 === 0) {
      onSubmit(0, true);
    }
  }, [elapsedSeconds, shouldEndInterview]);

  return null;
};

export default VideoTimer;

VideoTimer.propTypes = {
  onSubmit: PropTypes.func,
  setShouldEndInterview: PropTypes.func,
  shouldEndInterview: PropTypes.bool,
};
