import { Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import Lottie from 'react-lottie';
import animationData from 'src/assets/loader.json'; // Replace with your animation file path
import Jobheader from './job-header';
import logger from 'src/utils/logger/logger';

const Loader = ({ loader, setLoader, loaderMessage, companyLogo }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useEffect(() => {
        logger.debug(`Loader initializing with loader=> ${loader}`);
        let timer;
        if (loader) {
            timer = setTimeout(() => {
                logger.debug("Loader took more than 60 seconds");
                setLoader(false);
            }, 60000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [loader]);

    return (
        <Stack sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', height: '100%', }} >
            {companyLogo && <Jobheader companyLogo={companyLogo} />}
            <Stack sx={{ width: "100%" }}>
                <Lottie options={defaultOptions} height={120} width={120} />
                <Typography variant='subtitle1' sx={{ textAlign: "center" }}>{loaderMessage}</Typography>
            </Stack>
        </Stack >
    )
}

export default Loader;