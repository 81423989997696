import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';

// S3 Region
const REGION = process.env.REACT_APP_AWS_REGION;

// S3 Credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export const uploadVideoToS3 = async (fileObj, folderName) => {
  try {
    // const combinedBlob = new Blob(fileObj, { type: fileObj[0].type });
    // console.log('uploading video file obj is ', combinedBlob);
    let uploadKey = '';

    if (folderName) uploadKey = `videos/${folderName}/${uuidv4()}.mp4`;
    else uploadKey = `videos/${uuidv4()}.mp4`;

    const bucketName = process.env.REACT_APP_RESUME_BUCKET;
    const videoUrl = await uploadFile(
      bucketName,
      fileObj,
      uploadKey,
      'video/mp4',
    );
    return videoUrl;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImageToS3 = async (fileObj, folderName) => {
  try {
    let uploadKey = '';

    if (folderName) uploadKey = `images/${folderName}/${uuidv4()}.jpeg`;
    else uploadKey = `images/${uuidv4()}.jpeg`;

    const bucketName = process.env.REACT_APP_RESUME_BUCKET;
    const resumeUrl = await uploadFile(
      bucketName,
      fileObj,
      uploadKey,
      'image/jpeg',
    );
    return resumeUrl;
  } catch (error) {
    console.log(error);
  }
};

export const uploadResumeToS3 = async (
  fileObj,
  accept = 'application/pdf',
  fileExtension = 'pdf',
  folderName = 'candidates',
) => {
  try {
    const uploadKey = `resumes/${folderName}/${uuidv4()}.${fileExtension}`;
    const bucketName = process.env.REACT_APP_RESUME_BUCKET;
    const resumeUrl = await uploadFile(bucketName, fileObj, uploadKey, accept);
    return resumeUrl;
  } catch (error) {
    console.log(error);
  }
};

export const uploadAudioToS3 = async (fileObj, folderName) => {
  try {
    let uploadKey = '';

    if (folderName) uploadKey = `audios/${folderName}/${uuidv4()}.mp3`;
    else uploadKey = `audios/${uuidv4()}.mp3`;

    const bucketName = process.env.REACT_APP_RESUME_BUCKET;
    const resumeUrl = await uploadFile(
      bucketName,
      fileObj,
      uploadKey,
      'audio/mp3',
    );
    return resumeUrl;
  } catch (error) {
    console.log(error);
  }
};

export const uploadTextFileToS3 = async (logsText, folderName) => {
  try {
    let uploadKey = '';

    if (folderName) uploadKey = `Logs/${folderName}/${uuidv4()}.txt`;
    else uploadKey = `Logs/${uuidv4()}.txt`;

    const bucketName = process.env.REACT_APP_RESUME_BUCKET;
    const textFileUrl = await uploadFile(
      bucketName,
      logsText,
      uploadKey,
      'text/plain',
    );
    return textFileUrl;
  } catch (error) {
    console.error('Error uploading text file to S3:', error);
    throw error;
  }
};

const uploadFile = async (bucketName, fileObj, uploadKey, contentType) => {
  try {
    // S3 Bucket Name
    const s3 = new AWS.S3({
      params: { Bucket: bucketName },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: bucketName,
      Key: uploadKey,
      Body: fileObj,
      ContentType: contentType,
    };

    // Uploading file to s3

    const upload = s3
      .putObject(params)
      .on('httpUploadProgress', evt => {
        // File uploading progress
        // console.log(`Uploading ${parseInt((evt.loaded * 100) / evt.total)}%`);
      })
      .promise();

    return await upload
      .then(data => {
        return `https://${bucketName}.s3.${REGION}.amazonaws.com/${uploadKey}`;
      })
      .catch(err => {
        console.log('error uploading file');
        console.log(err);
        return '';
      });
  } catch (error) {
    console.log(error);
  }
};
