import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const mimeType = 'video/webm; codecs="opus,vp8"';

export const useMediaRecorder = ({ video, audio, onVideoStop }) => {
  // refs
  const mediaRecorder = useRef(null);
  // const liveVideoFeed = useRef(null);

  // states
  const [permission, setPermission] = useState(false);
  // const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [stream, setStream] = useState(null);
  // const [recordedVideo, setRecordedVideo] = useState(null);

  const videoChunksRef = useRef([]);

  // functions
  const getCameraPermission = useCallback(async () => {
    // setRecordedVideo(null);

    // get video and audio permissions and then stream the result media stream to the videoSrc variable
    if ('MediaRecorder' in window) {
      try {
        const videoConstraints = {
          audio: audio || true,
          video: video || true,
        };

        const audioConstraints = { audio: true };

        // create audio and video streams separately
        const audioStream =
          await navigator.mediaDevices.getUserMedia(audioConstraints);
        const videoStream =
          await navigator.mediaDevices.getUserMedia(videoConstraints);

        setPermission(true);

        // combine both audio and video streams

        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        setStream(combinedStream);

        // set videostream to live feed player
        // liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.');
    }
  }, [audio, video]);

  const startRecording = useCallback(
    async currQuestionIndex => {
      // setRecordingStatus('recording');
      const media = new MediaRecorder(stream, { mimeType });
      mediaRecorder.current = media;
      mediaRecorder.current.start(1000);

      mediaRecorder.current.ondataavailable = event => {
        if (typeof event.data === 'undefined' || event.data.size === 0) return;
        videoChunksRef.current.push({ id: currQuestionIndex, chunk: event.data });
      };
    },
    [stream],
  );

  const stopRecording = useCallback(
    (currQuestionIndex, isPartialSubmit = false) => {
      setPermission(false);
      // setRecordingStatus('inactive');
      mediaRecorder.current.stop();

      mediaRecorder.current.onstop = () => {
        const videoChunksWithId = videoChunksRef.current.filter(
          chunk => chunk.id === currQuestionIndex
        );

        const videoBlob = new Blob(
          videoChunksWithId.map(chunk => chunk.chunk),
          { type: mimeType }
        );

        videoChunksRef.current = videoChunksRef.current.filter(
          chunk => chunk.id !== currQuestionIndex
        );

        if (isPartialSubmit) {
          startRecording(currQuestionIndex);
        }

        onVideoStop(videoBlob, currQuestionIndex, isPartialSubmit);

      };
    },
    [onVideoStop, startRecording],
  );

  // effects
  useEffect(() => {
    getCameraPermission();
  }, []);

  const value = useMemo(
    () => ({
      permission,
      startRecording,
      stopRecording,
    }),
    [permission, startRecording, stopRecording],
  );

  return value;
};

useMediaRecorder.propTypes = {
  video: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  audio: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onVideoStop: PropTypes.func,
};
