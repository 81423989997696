import { useCallback, useEffect, useRef, useState } from 'react';
import { usePreloadImage } from 'src/hooks/use-preload-image';
import { useResponsive } from 'src/hooks/use-responsive';
import PropTypes from 'prop-types';
import { getCodingInterview } from 'src/services/coding-interview.service';
import { uploadVideoToS3 } from 'src/services/aws.service';
import Loader from '../sections/loader';
import InterviewExpired from '../sections/interview-expired';
import InterviewAlreadyCompleted from '../sections/interview-already-completed';
import {
  CONGRATULATIONS_PAGE,
  INSTRUCTIONS_PAGE,
  INTERVIEW_RUN_PAGE,
  JOB_DETAILS_PAGE,
  PERMISSIONS_PAGE,
  TIMER_PAGE,
} from '../constants';
import JobDetailsPage from '../sections/job-details-page';
import InstructionsPage from '../sections/instructions-page';
import PermissionsPage from '../sections/permissions-page';
import Timer from '../components/timer';
import InterviewRunPage from '../sections/interview-run-page';
import useCodingInterviewContext from '../hooks/use-coding-interview-context';
import MobileView from 'src/sections/mobile-view';

export default function CodingInterviewView({ interviewId, tokenId }) {
  // hooks
  usePreloadImage('/assets/illustrations/woman-working-on-a-computer.svg');
  usePreloadImage('/assets/illustrations/lady-waving.svg');

  const {
    setCodingInterviewId,
    setTokenId,
    permission,
    getMediaPermission,
    liveVideoFeed,
    pageState,
    setPageState,
  } = useCodingInterviewContext();

  const mdUp = useResponsive('up', 'md');

  // states
  const [loader, setLoader] = useState(true);
  const [company, setCompany] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [location, setLocation] = useState('Banglore');
  const [founderMessage, setFounderMessage] = useState(null);
  const [isAlreadyCompleted, setIsAlreadyCompleted] = useState(false);
  const [isInterviewExpired, setIsInterviewExpired] = useState(false);
  const [isTermsAndPolicyAccepted, setIsTermsAndPolicyAccepted] =
    useState(null);

  // effects
  useEffect(() => {
    const callerFunc = async () => {
      try {
        setCodingInterviewId(interviewId);
        setTokenId(tokenId);

        const response = await getCodingInterview(interviewId, tokenId);

        if (response?.success) {
          setCompany(response?.body?.organization?.name);
          setCompanyLogo(response?.body?.organization?.logo_url);
          setCandidateName(response?.body?.user?.name);
          setJobRole(response?.body?.job_opening?.name);
          setIsAlreadyCompleted(
            ['COMPLETED', 'EVALUATED'].includes(
              response?.body?.interview_status,
            ),
          );
          setIsTermsAndPolicyAccepted(response?.body?.is_terms_accepted);
          setIsInterviewExpired(response?.body?.is_expired);
        } else if (response?.response?.status === 401) {
          setIsInterviewExpired(true);
        }
      } catch (err) {
        console.log('err:', err);
      } finally {
        setLoader(false);
      }
    };

    callerFunc();
  }, [interviewId, setCodingInterviewId, setTokenId, tokenId]);

  useEffect(() => {
    if (
      pageState === PERMISSIONS_PAGE &&
      permission === null &&
      liveVideoFeed?.current
    ) {
      getMediaPermission();
    }
  }, [getMediaPermission, liveVideoFeed, pageState, permission]);

  useEffect(() => {
    if (pageState === CONGRATULATIONS_PAGE)
      window.location.href = `${process.env.REACT_APP_INTERVIEW_CLIENT_URL}/congratulations`;
  }, [pageState]);

  return loader ? (
    <Loader
      companyLogo={companyLogo}
      loader={loader}
      setLoader={setLoader}
      loaderMessage="Loading ..."
    />
  ) : (
    mdUp ? (
      <>
        {isInterviewExpired && !isAlreadyCompleted && (
          <InterviewExpired
            companyLogo={companyLogo}
            jobRole={jobRole}
            location={location}
            company={company}
          />
        )}
        {isAlreadyCompleted && (
          <InterviewAlreadyCompleted
            companyLogo={companyLogo}
            jobRole={jobRole}
            location={location}
            company={company}
          />
        )}
        {!isAlreadyCompleted && !isInterviewExpired && (
          <>
            {pageState === JOB_DETAILS_PAGE && (
              <JobDetailsPage
                company={company}
                companyLogo={companyLogo}
                jobRole={jobRole}
                location={location}
                candidateName={candidateName}
                founderMessage={founderMessage}
                setPageState={setPageState}
              />
            )}
            {pageState === INSTRUCTIONS_PAGE && (
              <InstructionsPage
                tokenId={tokenId}
                companyLogo={companyLogo}
                interviewId={interviewId}
                setPageState={setPageState}
                isTermsAndPolicyAccepted={isTermsAndPolicyAccepted}
                setIsTermsAndPolicyAccepted={setIsTermsAndPolicyAccepted}
              />
            )}
            {pageState === PERMISSIONS_PAGE && (
              <PermissionsPage
                companyLogo={companyLogo}
                setPageState={setPageState}
              />
            )}
            {pageState === TIMER_PAGE && (
              <Timer
                tokenId={tokenId}
                interviewId={interviewId}
                companyLogo={companyLogo}
                setPageState={setPageState}
              />
            )}
            {pageState === INTERVIEW_RUN_PAGE && (
              <InterviewRunPage
                companyLogo={companyLogo}
                setPageState={setPageState}
              />
            )}
          </>
        )}
      </>
    ) : (
      <MobileView/>
    )
  );
}

CodingInterviewView.propTypes = {
  interviewId: PropTypes.string,
  tokenId: PropTypes.string,
};
