import { Button, Stack, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import BrowserCheck from '../compatibility-checks/browser-check';
import InternetCheck from '../compatibility-checks/internet-check';
import { INSTRUCTIONS_PAGE } from './constants';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    padding: '1rem',
    gap: '14px',
  },
}));

const BottomDrawer = ({
  open,
  setOpen,
  setPageState,
  browserCompatible,
  internetCompatible,
  isBrowserCompatibilityChecking,
  isInternetCompatibliityChecking,
}) => {
  // hooks
  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperProps={{
        style: { borderTopLeftRadius: '20px', borderTopRightRadius: '20px' },
      }}
    >
      {open && (
        <Stack className={classes.mainDiv}>
          <Typography variant="subheading2">System check</Typography>
          <BrowserCheck
            browserCompatible={browserCompatible}
            isBrowserCompatibilityChecking={
              isBrowserCompatibilityChecking.value
            }
          />
          <InternetCheck
            internetCompatible={internetCompatible}
            isInternetCompatibliityChecking={
              isInternetCompatibliityChecking.value
            }
          />
          <Button
            disabled={!(internetCompatible && browserCompatible)}
            variant="contained"
            onClick={() => {
              setPageState(INSTRUCTIONS_PAGE);
            }}
          >
            Good to go !!
          </Button>
        </Stack>
      )}
    </Drawer>
  );
};
export default BottomDrawer;

BottomDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setPageState: PropTypes.func,
  browserCompatible: PropTypes.bool,
  internetCompatible: PropTypes.bool,
  isBrowserCompatibilityChecking: PropTypes.object,
  isInternetCompatibliityChecking: PropTypes.object,
};
