import { useDispatch } from 'react-redux';
import { postEndInterviewData } from 'src/services/interview.service';

const {
  register,
  MediaRecorder: ExtendableMediaRecorder,
  IMediaRecorder,
} = require('extendable-media-recorder');
const { useCallback, useEffect, useRef, useState } = require('react');
const { connect } = require('extendable-media-recorder-wav-encoder');

export const RecorderErrors = {
  AbortError: 'media_aborted',
  NotAllowedError: 'permission_denied',
  NotFoundError: 'no_specified_media_found',
  NotReadableError: 'media_in_use',
  OverconstrainedError: 'invalid_media_constraints',
  TypeError: 'no_constraints',
  NONE: '',
  NO_RECORDER: 'recorder_error',
  InvalidAccessError: 'InvalidAccessError',
};

export const useReactMediaRecorder = ({
  audio = true,
  video = false,
  onStop = () => null,
  onStart = () => null,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = undefined,
  customMediaStream = null,
  stopStreamsOnStop = true,
  askPermissionOnMount = false,
  controlAudio,
  setLoading,
  interviewId,
  token_id,
  setComplete,
  setUserStopedScreenRecording,
}) => {
  const dispatch = useDispatch();
  const mediaRecorder = useRef(null);
  const mediaChunks = useRef([]);
  const mediaStream = useRef(null);
  const [status, setStatus] = useState('idle');
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(undefined);
  const [error, setError] = useState('NONE');

  useEffect(() => {
    const setup = async () => {
      await register(await connect());
    };
    setup();
  }, []);

  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');
    const requiredMedia = {
      audio: typeof audio === 'boolean' ? !!audio : audio,
      video: typeof video === 'boolean' ? !!video : video,
    };
    try {
      if (customMediaStream) {
        mediaStream.current = customMediaStream;
      } else if (screen) {
        const stream = await window.navigator.mediaDevices.getDisplayMedia({
          video: { facingMode: 'user' },
        });
        stream.getVideoTracks()[0].addEventListener('ended', () => {
          pauseRecording();
          controlAudio('inactive');
          dispatch(setLoading(true));
          setTimeout(() => {
            postEndInterviewData(interviewId, token_id).then(data => {
              setComplete(true);
            });
            setUserStopedScreenRecording(true);
          }, 2000);
        });
        if (audio) {
          const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio,
          });

          audioStream
            .getAudioTracks()
            .forEach(audioTrack => stream.addTrack(audioTrack));
        }
        mediaStream.current = stream;
      } else {
        const stream =
          await window.navigator.mediaDevices.getUserMedia(requiredMedia);
        mediaStream.current = stream;
      }
      setStatus('idle');
    } catch (error) {
      console.log('error aquiring media', error);
      console.log(error.name);
      setError(error.name);
      setStatus('idle');
    }
  }, [audio, video, screen]);

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new Error('Unsupported Browser');
    }

    if (screen) {
      if (!window.navigator.mediaDevices.getDisplayMedia) {
        throw new Error("This browser doesn't support screen capturing");
      }
    }

    const checkConstraints = mediaType => {
      const supportedMediaConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      const unSupportedConstraints = Object.keys(mediaType).filter(
        constraint => !supportedMediaConstraints[constraint],
      );

      if (unSupportedConstraints.length > 0) {
        console.error(
          `The constraints ${unSupportedConstraints.join(
            ',',
          )} doesn't support on this browser. Please check your ReactMediaRecorder component.`,
        );
      }
    };

    if (typeof audio === 'object') {
      checkConstraints(audio);
    }
    if (typeof video === 'object') {
      checkConstraints(video);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type you supplied for MediaRecorder doesn't support this browser`,
        );
      }
    }

    if (!mediaStream.current && askPermissionOnMount) {
      getMediaStream();
    }

    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getTracks();
        tracks.forEach(track => track.clone().stop());
      }
    };
  }, [
    audio,
    screen,
    video,
    getMediaStream,
    mediaRecorderOptions,
    askPermissionOnMount,
  ]);

  const startRecording = async () => {
    setError('NONE');
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some(track => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }

      if (!mediaStream.current.active) {
        return;
      }
      mediaRecorder.current = new ExtendableMediaRecorder(
        mediaStream.current,
        mediaRecorderOptions || undefined,
      );
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onstart = onRecordingStart;
      mediaRecorder.current.onerror = () => {
        setError('NO_RECORDER');
        setStatus('idle');
      };
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const onRecordingActive = ({ data }) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStart = () => {
    onStart();
  };

  const onRecordingStop = () => {
    if (status !== 'stoped') {
      console.log('mediaChunk -----------', mediaChunks);
      const [chunk] = mediaChunks.current;
      const blobProperty = {
        type: chunk.type,
        ...(blobPropertyBag ||
          (video ? { type: 'video/mp4' } : { type: 'audio/wav' })),
      };
      const blob = new Blob(mediaChunks.current, blobProperty);
      const url = URL.createObjectURL(blob);
      setStatus('stopped');
      console.log('blobUrl ------------------------------------', url);
      setMediaBlobUrl(url);
      onStop(url, blob);
      mediaChunks.current = [];
    }
  };

  /// ////////////////////////////////////////////////////////////////////

  const muteAudio = mute => {
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      mediaStream.current
        .getAudioTracks()
        .forEach(audioTrack => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setStatus('paused');
      mediaRecorder.current.stop();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
    }
    mediaRecorder.current.start();
    setStatus('recording');
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping');
        mediaRecorder.current.stop();
        if (stopStreamsOnStop) {
          mediaStream.current &&
            mediaStream.current.getTracks().forEach(track => track.stop());
        }
        mediaChunks.current = [];
      }
    }
  };
  return {
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    isAudioMuted,
    previewStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getVideoTracks())
      : null,
    previewAudioStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getAudioTracks())
      : null,
    clearBlobUrl: () => {
      if (mediaBlobUrl) {
        URL.revokeObjectURL(mediaBlobUrl);
      }
      setMediaBlobUrl(undefined);
      setStatus('idle');
    },
  };
};
