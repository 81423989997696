import { alpha } from '@mui/material/styles';
import { dark } from '@mui/material/styles/createPalette';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FBFBFB',
  50: '#F3F3F3',
  100: '#E8E8E8',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const BLACK = {
  1: '#212121',
  2: '#383838',
  3: '#707070',
  4: '#A0A0A0',
  5: '#CFCFCF',
  6: '#E7E7E7',
};

const PRIMARY = {
  lighter: '#FBFBFB', // primary b
  light: '#C1C1C1', // primary c
  main: '#212121', // primary a
  dark: '#212121', // primary a
  darker: '#212121', // primary a
};

const SECONDARY = {
  lighter: '#EDFAFF',
  light: '#EDFAFF',
  main: '#212121',
  dark: '#212121',
  darker: '#212121',
  contrastText: '#212121',
};

const INFO = {
  lighter: '#F5F1FF', // accent (purple)
  light: '#F5F1FF', // accent (purple)
  main: '#5418EF', // cta (purple)
  dark: '#5418EF', // cta (purple)
  darker: '#5418EF', // cta (purple)
};

const SUCCESS = {
  lighter: '#EFFFF1', // positive (green)
  light: '#EFFFF1', // positive (green)
  main: '#08944A', // positive (green)
  dark: '#08944A', // positive (green)
  contrastText: '#08944A', // positive (green)
};

const WARNING = {
  lighter: '#FFF4D3',
  light: '#FFF4D3',
  main: '#F6BC2F', // warning(yellow)
  dark: '#F6BC2F',
  darker: '#F6BC2F',
};

const ERROR = {
  lighter: '#FFF2F1',
  light: '#FFF2F1',
  main: '#DE1135', // negative (red)
  dark: '#DE1135', // negative (red)
  darker: '#DE1135', // negative (red)
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: '#A0A0A0',
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 1,
  },
};

export function palette(mode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: '#212121',
      secondary: '#383838',
      focused: '#5418EF',
      disabled: '#A0A0A0',
    },
    content: {
      primaryBlack1: '#212121',
      primaryBlack2: '#383838',
      primaryBlack3: '#A0A0A0',
      primaryWhite: '#FFFFFF',
      accent1: '#5418EF',
      accent2: '#F5F1FF',
      primaryRed1: '#DE1135',
      primaryRed2: '#FFF2F1',
      primaryGreen1: '#08944A',
      primaryGreen2: '#F4FFF6',
      primaryYellow1: '#F6BC2F',
      primaryYellow2: '#FFF4D3',
    },
    background: {
      primaryWhite1: COMMON.common.white,
      primaryGrey1: '#FBFBFB',
      primaryGrey2: '#F3F3F3',
      primaryGrey3: '#E8E8E8',
      primaryAccent1: '#5418EF',
      primaryAccent2: '#F5F1FF',
      primaryBlack1: '#212121',
      primaryRed1: '#DE1135',
      primaryRed2: '#FFF2F1',
      primaryYellow1: '#F6BC2F',
      primaryYellow2: '#FFF4D3',
      primaryGreen1: '#08944A',
      primaryGreen2: 'rgba(239, 255, 241, 0.67)',
      primaryGreen3: '#9BFFE4',
      default: COMMON.common.white,
      paper: COMMON.common.white,
      neutral: GREY[200],
    },
    border: {
      primaryBlack1: '#212121',
      primaryBlack2: '#BEBEBE',
      primaryBlack3: '#E9E9E9',
      primaryAccent1: '#5418EF',
      primaryAccent2: '#F5F1FF',
      primaryRed1: '#DE1135',
      primaryGreen1: '#08944A',
      primaryYellow1: '#F6BC2F',
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return light;
}
