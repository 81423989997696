import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { EVENT_APPLY_JOB_APPLY_CLICK } from 'src/analytics/trackevents';
import JobNotPublished from './job-not-published';
import { trackEvent } from 'src/analytics/datatrack';
import { JOB_APPLY_RESUME_UPLOAD } from 'src/sections/apply-for-job/view/constants';
import Iconify from 'src/components/iconify';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        width: "100%"
    },
    mainfirst: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    jobRoleText: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 20,
        }
    },
    roleHeading: {
        flexGrow: 1,
        gap: "12px"
    },
    company: {
        fontSize: "14px",
        fontWeight: "500",
        color: theme.palette.content.primaryBlack3,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        }
    },
    applydiv: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        gap: "8px",
        marginTop: "0rem",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'row-reverse',
            justifyContent: "flex-end"
        }
    },
    overview: {
        [theme.breakpoints.down("sm")]: {
            [theme.breakpoints.down("sm")]: {
                fontSize: 20,
            }
        }
    },
    icon: {
        display: "none",
        cursor: "pointer",
        padding: "10px 11px",
        lineHeight: "0px",
        borderRadius: 12,
        border: "0.5px solid",
        borderColor: theme.palette.border.tertiary,
        background: theme.palette.background.primaryGrey1
    },
    button: {
        // height: "80%",
        // width: "9.2rem",
        borderRadius: 12,
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    descriptionDiv: {
        marginTop: "1.5rem",
    },
    description: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        }
    },
    copyrightDiv: {
        marginTop: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 8,
        // background: theme.palette.background.primaryGrey1,
    },
    copyright: {
        textAlign: "center",
        padding: "0.5rem",
        fontSize: "0.8rem"
    },
    hireintelLink:{
        color:theme.palette.content.accent1,
        textDecoration:"none",
        fontWeight:600
    }
}));

const JobDescription = ({ setPageState, jobPublished, jobDescription, jobRole, company, orgId, jobId }) => {
    const classes = useStyles();

    const applyButtonClicked = () => {
        trackEvent(EVENT_APPLY_JOB_APPLY_CLICK, { jobId: jobId, orgId: orgId });
        setPageState(JOB_APPLY_RESUME_UPLOAD);
    }

    return (
        jobPublished === false ?
            <JobNotPublished /> :
            <Stack className={classes.main}>
                <Stack className={classes.mainfirst}>
                    <Stack spacing={1} className={classes.roleHeading} direction="column">
                        <Typography variant="h3" className={classes.jobRoleText}>{jobRole}</Typography>
                        <Typography variant="paragraph" className={classes.company}>{company}</Typography>
                    </Stack>
                    <Stack className={classes.applydiv}>
                        <Box component={"div"} className={classes.icon}>
                            <Iconify icon="tabler:share" />
                        </Box>
                        <Button variant="contained" onClick={() => applyButtonClicked()} className={classes.button}>Apply Now</Button>
                    </Stack>
                </Stack>
                <Stack className={classes.descriptionDiv}>
                    <Typography variant="subheading2" className={classes.overview}>Overview</Typography>
                    <Typography varient="paragraph" dangerouslySetInnerHTML={{ __html: jobDescription }} className={classes.description}></Typography>
                </Stack>
                <Stack className={classes.copyrightDiv}>
                    <Typography className={classes.copyright} variant="caption">
                       Powered by <a href="https://hireintel.ai" className={classes.hireintelLink}>HireIntel</a>
                    </Typography>
                </Stack>
            </Stack>

    )
}

export default JobDescription