import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import classes from './styles.module.css';
import ReactPlayer from 'react-player';
import {
  getInterviewDetails,
  postStartInterviewData,
} from 'src/services/interview.service';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_INTERVIEW_DEMO_CONTINUE,
  EVENT_INTERVIEW_PERMISSION_DENIED,
  EVENT_INTERVIEW_START_BUTTON_CLICKED,
} from 'src/analytics/trackevents';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingScreen } from 'src/components/loading-screen';
import { setQuestions } from 'src/store/questionSlice';
import { useQuery } from 'src/hooks/use-query';
import Scrollbar from 'src/components/scrollbar';

const DemoPage = ({ interview_id, setPageState, prescreenSkip }) => {
  const settings = useSettingsContext();
  var isFirefox = typeof InstallTrigger !== 'undefined';
  const [startBtnDisabled, setStartBntDisabled] = useState(false);
  const [interviewMsg, setInterviewMsg] = useState(
    isFirefox
      ? 'Interview is not compatible with firefox browser, please use Google Chrome for best Experience.'
      : '',
  );
  const [loader, setLoader] = useState(false);
  const [doScreenRecord, setDoScreenRecord] = useState('NO');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // hooks
  const params = useQuery();

  // variables
  const token_id = params.get('token');

  const postStartInterviewCaller = (interview_id, token_id) => {
    postStartInterviewData(interview_id, token_id)
      .then(data => {
        console.log('questions = ', data);
        setLoader(false);
        if (data.success === true) {
          dispatch(setQuestions(data.body));
          setSuccess(true);
          setMessage('');
          navigate(
            process.env.REACT_APP_ENVIRONMENT === 'PROD'
              ? `/interview-client/${interview_id}/run2?token=${token_id}&SR=${doScreenRecord}`
              : `/interview-client/${interview_id}/run2?token=${token_id}&SR=${doScreenRecord}`,
          );
        } else {
          setLoader(false);
          enqueueSnackbar({
            message: data.message,
            variant: 'info',
          });
          setMessage(data.message);
        }
      })
      .catch(err => {
        setMessage('Error loading Interview');
        console.log(err);
        enqueueSnackbar({
          message: 'Error loading Interview',
          variant: 'error',
        });
      });
  };

  const handleStartInterview = async () => {
    try {
      setLoader(true);
      trackEvent(EVENT_INTERVIEW_DEMO_CONTINUE, {});
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
        navigator.getUserMedia(
          {
            audio: true,
            video: true,
          },
          stream => {
            setStartBntDisabled(false);
            console.log('launch interview');
            console.log('Loading questions');
            setMessage('');
            setSuccess(false);
            postStartInterviewCaller(interview_id, token_id);
          },
          err => {
            console.log('Premissions denied');
            setStartBntDisabled(true);
            setInterviewMsg(
              'Pease give permissions for using camera and microphone.',
            );
            setLoader(false);
            trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, {});
          },
        );
      } else {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .then(stream => {
            setStartBntDisabled(false);
            console.log('launch interview');
            console.log('Loading questions');
            setMessage('');
            setSuccess(false);
            postStartInterviewCaller(interview_id, token_id);
          })
          .catch(err => {
            console.log('Premissions denied');
            setStartBntDisabled(true);
            setInterviewMsg(
              'Pease give permissions for using camera and microphone.',
            );
            setLoader(false);
            trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, {});
          });
      }
    } catch (error) {
      console.log(error);
    }
    trackEvent(EVENT_INTERVIEW_START_BUTTON_CLICKED, {});
  };

  useEffect(() => {
    const caller = async () => {
      let res = await getInterviewDetails(interview_id, token_id);
      console.log(res);
      if (res?.body?.enable_screen_recording) setDoScreenRecord('YES');
    };
    caller();
  }, []);

  return (
    <>
      {loader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            height: '90%',
          }}
        >
          <div className={classes.interview_getting_ready}>
            <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
              Your interview is getting ready ...
            </h2>
            <LoadingScreen />
          </div>
        </div>
      )}
      {!loader && (
        <Stack sx={{ height: '100%', justifyContent: 'center' }}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
            maxWidth={settings.themeStretch ? false : 'lg'}
          >
            <Stack
              component={Card}
              className={classes.demoVideoDiv}
              spacing={1}
              sx={{ minHeight: '88vh', height: '82vh' }}
            >
              <Scrollbar>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    mb: { xs: 1, md: 1 },
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ mb: '1rem' }}>
                      <Typography variant="h4">AI Interview</Typography>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          if (prescreenSkip) setPageState(0);
                          else setPageState(1);
                        }}
                        startIcon={
                          <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                        }
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Stack>

                <Stack direction="column" justifyContent="space-between">
                  <Stack className={classes.demoVideo}>
                    <Typography
                      sx={{
                        marginBottom: '2rem',
                        fontSize: '1.1rem',
                        fontWeight: '600',
                      }}
                    >
                      Please watch interview demo video before starting
                      interview
                    </Typography>
                    <Stack
                      height="40vh"
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ReactPlayer
                        width="45%"
                        // height="142px"
                        // style={{height:"100px"}}
                        controls={true}
                        url={'https://youtu.be/CVEG8Y-Vvug'}
                      />
                    </Stack>
                  </Stack>

                  <Button
                    type="primary"
                    variant="contained"
                    sx={{ width: '6rem', margin: 'auto', marginTop: '2.5rem' }}
                    disabled={startBtnDisabled === true || isFirefox === true}
                    onClick={handleStartInterview}
                  >
                    Continue
                  </Button>
                  <Typography
                    sx={{ fontSize: '1rem', color: 'red', marginTop: '1rem' }}
                    variant="body2"
                  >
                    {interviewMsg}
                  </Typography>
                </Stack>
              </Scrollbar>
            </Stack>
          </Container>
        </Stack>
      )}
    </>
  );
};

export default DemoPage;
