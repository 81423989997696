import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import { uploadImageToS3 } from 'src/services/aws.service';
import styles from './styles.module.css';

const WebCam = ({ setCamImageUrls, totalSeconds }) => {
  var isFirefox = typeof InstallTrigger !== 'undefined';



  let myWidth = -1, myHeight = -1;
  // console.log("height = ",window.innerHeight)
  if (typeof (window.innerWidth) == 'number') {
    // console.log("hello there")
    myWidth = window.innerWidth;
    myHeight = window.innerHeight;
  } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
    myWidth = document.documentElement.clientWidth;
    myHeight = document.documentElement.clientHeight;
  } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
    myWidth = document.body.clientWidth;
    myHeight = document.body.clientHeight;
  }

  let isMobileDevice = true
  if(myWidth!==-1){
    isMobileDevice = myWidth<800
  }
  // console.log("dimensions = ",myWidth,myHeight)
  const webcamRef = useRef(null);
  const [TotSeconds, setTotSeconds] = useState(0);

  const LaptopVideoConstraints = {
    width: (20 / 100) * myWidth,
    height: (20 / 100) * myHeight,
    facingMode: 'user',
  };

  const mobileVideoConstraints = {
    width: (25 / 100) * myWidth,
    height: (20 / 100) * myHeight,
    facingMode: 'user',
  };

  // effects
  useEffect(() => {
    setTotSeconds(totalSeconds);
  }, [totalSeconds]);

  useEffect(() => {
    setTimeout(() => {
      function captureCandidateImage() {
        let imageBase64 = null;

        try {
          imageBase64 = webcamRef.current.getScreenshot();
          console.log('imageBase64', imageBase64);
        } catch (error) {
          console.log(error);
        }

        let dummyTotalSeconds = -1;

        setTotSeconds(prevState => {
          dummyTotalSeconds = prevState;
          return prevState;
        });

        if (imageBase64) {
          fetch(imageBase64)
            .then(res => res.blob())
            .then(res => {
              uploadImageToS3(res)
                .then(data => {
                  console.log('image-url = ', data);

                  setCamImageUrls(prevData => [
                    ...prevData,
                    { image_url: data, interview_time: dummyTotalSeconds },
                  ]);
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => console.log(err));
        }
      }

      if (webcamRef && webcamRef.current) {
        captureCandidateImage();
      }

      const intervalId = setInterval(captureCandidateImage, 30000);
      return () => clearInterval(intervalId);
    }, 3000);
  }, [setCamImageUrls, webcamRef]);

  return (
    <div className={styles.webcam_container}>
      {isMobileDevice === true ?
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          width={100}
          height={147}
          videoConstraints={
            {
              width: 600,
              height: 400,
              facingMode: 'user',
            }
          }
        /> 
        : 
         <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          
          width={isFirefox ? (myWidth < 500 ? (25 / 100) * myWidth : (20 / 100) * myWidth) : ""}
          height={isFirefox ? (myWidth < 500 ? (20 / 100) * myHeight : (20 / 100) * myHeight) : ""}
          videoConstraints={
            myWidth < 500
              ? mobileVideoConstraints
              : LaptopVideoConstraints
          }
        />
      } 
    </div>
  );
};
export default WebCam;

WebCam.propTypes = {
  setCamImageUrls: PropTypes.func,
  totalSeconds: PropTypes.number,
};
