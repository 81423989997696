import React, { createContext, useState } from 'react';
// Create the context
export const InterviewContext = createContext();

// Create a provider component
export const InterviewProvider = ({ children }) => {
    // Define the state variables
    
    // Return the provider component with the context value
    return (
        <InterviewContext.Provider value={contextValue}>
            {children}
        </InterviewContext.Provider>
    );
};
