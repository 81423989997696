import { postData } from "src/utils/axios/index"
import { urlMap } from 'src/utils/urls';


export const getCodingInterview = async (codingInterviewId, tokenId) => {
  const url = urlMap.getCodingInterview.replace('{coding-interview-id}', codingInterviewId)

  return postData(url, {}, tokenId)
}

export const startCodingInterview = async (codingInterviewId, tokenId) => {
  const url = urlMap.startCodingInterview.replace('{coding-interview-id}', codingInterviewId)

  return postData(url, {}, tokenId)
}

export const submitCodingInterview = async (codingInterviewId, tokenId, reqBody) => {
  const url = urlMap.submitCodingInterview.replace('{coding-interview-id}', codingInterviewId)

  return postData(url, reqBody, tokenId)
}

export const partialSubmitCodingInterview = async (codingInterviewId, tokenId, reqBody) => {
  const url = urlMap.partialSubmitCodingInterview.replace('{coding-interview-id}', codingInterviewId)

  return postData(url, reqBody, tokenId)
}

export const codeInvoke = async (codingInterviewId, tokenId, reqBody) => {
  const url = urlMap.codeInvoke.replace('{coding-interview-id}', codingInterviewId)

  return postData(url, reqBody, tokenId)
}
