import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { makeStyles } from '@mui/styles';
import InterviewHeader from './interview-header';

const useStyles = makeStyles(theme => ({
  location: {
    color: theme.palette.content.primaryBlack3,
  },
}));

export default function InterviewAlreadyCompleted({
  companyLogo,
  jobRole,
  location,
  company,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <InterviewHeader companyLogo={companyLogo} />
      <Stack
        height="100vh"
        direction="row"
        alignItems="center"
        justifyContent="center"
        paddingX={{ xs: '15px', md: '40px' }}
        spacing="22px"
        width="100%"
      >
        <Stack
          sx={{
            display: { xs: 'none', md: 'block' },
            paddingX: '60px',
            width: '50%',
          }}
        >
          <Image
            src="/assets/illustrations/interview-completed.svg"
            sx={{
              zIndex: -1,
            }}
            alt="Interview Completed"
          />
        </Stack>
        <Stack
          spacing="36px"
          height="100%"
          justifyContent="center"
          width={{
            xs: '100%',
            md: '50%',
          }}
        >
          <Stack spacing="8px">
            <Typography variant="subheading1">{jobRole}</Typography>
            {company && (
              <Stack direction="row" spacing="6px" alignItems="center">
                <Iconify
                  icon="tabler:briefcase-2"
                  color={theme.palette.content.primaryBlack3}
                />
                <Typography
                  variant={mdUp ? 'paragraph' : 'input'}
                  className={classes.location}
                >
                  {company}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack spacing="16px">
            <Typography variant={mdUp ? 'h1' : 'h4'}>
              You&apos;ve already completed your interview!
            </Typography>
            <Typography
              variant="subheading2"
              fontWeight={400}
              color="content.primaryBlack3"
            >
              We&apos;ll be in touch soon with the next steps.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

InterviewAlreadyCompleted.propTypes = {
  companyLogo: PropTypes.string,
  jobRole: PropTypes.string,
  location: PropTypes.string,
  company: PropTypes.string,
};
