import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// assets
import { CardActionArea, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import HireIntelExcel from 'src/assets/files/hireintel_format.xlsx';
//
import Iconify from '../iconify';
//
import RejectionFiles from './errors-rejection-files';
import MultiFilePreview from './preview-multi-file';
import Scrollbar from '../scrollbar';
import CustomPopover, { usePopover } from '../custom-popover';
import FileThumbnail from '../file-thumbnail';
import Label from '../label';
import Image from '../image';
import { useTheme } from '@mui/system';
import { useResponsive } from 'src/hooks/use-responsive';
import SingleFilePreview from './preview-single-file';

// ----------------------------------------------------------------------

export default function Upload({
  title = 'Upload Resume',
  disabled,
  type = 'resume',
  multiple = false,
  inputSourceFormatOptions,
  inputFileTypeOptions,
  inputSourceFormat,
  onInputSourceFormatChange,
  inputFileType,
  onInputFileTypeChange,
  inputFileTypeButtonRef,
  inputSourceFormatButtonRef,
  setInsideButtonClicked,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  isExcel,
  sx,
  ...other
}) {
  const hasFile = !!file && !multiple;
  const hasFiles = !!files && multiple && !!files.length;

  let isMultiple = false;

  if (multiple) {
    if (isExcel) {
      isMultiple = false;
    } else isMultiple = true;
  } else isMultiple = false;

  console.log('isMultiple', multiple, isMultiple, isExcel);

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: isMultiple,
    disabled,
    ...other,
  });

  // const theme = useTheme();
  const downSm = useResponsive('down', 'sm');
  const inputFileTypePopover = usePopover();
  const inputSourceFormatPopover = usePopover();

  const { enqueueSnackbar } = useSnackbar();

  const hasError = isDragReject || !!error;

  const onBrowse = () => {
    if (
      inputFileType === 'pdf' ||
      (inputFileType === 'excel' && inputSourceFormat)
    ) {
      open();
    } else {
      enqueueSnackbar({
        message: 'Please choose the source format',
        variant: 'error',
      });
    }
  };

  const downloadExcelFormat = () => {
    const element = document.createElement('a');
    element.href = HireIntelExcel;
    element.download = 'hireintel-format.xlsx';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const getFileIcon = () => {
    if (type === 'resume' || hasFile) return '/assets/icons/files/ic_file.svg';
    return '/assets/icons/components/upload/illustration.svg';
  };

  const renderSinglePreview = (
    <SingleFilePreview file={file} />
    // <MultiFilePreview files={[file]} />
  );

  const renderPlaceholder = (
    <Stack
      spacing={3}
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      <Image src={getFileIcon()} />
      <Stack alignItems="center" spacing={2}>
        <Stack alignItems="center" spacing={downSm && '8px'}>
          <Typography
            sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}
            variant={downSm ? 'subheading1' : 'paragraph'}
          >
            {title}
          </Typography>
          <Stack direction="row" textAlign="center" alignItems="center">
            <Typography variant="caption" sx={{ fontSize: 14 }}>
              Drag and drop or
              <Box
                component="span"
                sx={{
                  mx: 0.5,
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'content.accent1',
                }}
              >
                Browse
              </Box>
            </Typography>
          </Stack>
        </Stack>

        {/* <>
                <Button
                  disableRipple
                  variant="outlined"
                  color="inherit"
                  ref={inputFileTypeButtonRef}
                  onClick={event => {
                    setInsideButtonClicked(true);

                    if (inputFileTypeButtonRef.current) {
                      inputFileTypeButtonRef.current.click();
                    }
                    // if (inputSourceFormatButtonRef.current) {
                    //   inputSourceFormatButtonRef.current.click();
                    // }
                    // setTimeout(() => {
                    //   setInsideButtonClicked(false);
                    // }, 2000);
                    inputFileTypePopover.onOpen(event);
                  }}
                  endIcon={
                    <Iconify
                      icon={
                        inputFileTypePopover.open
                          ? 'eva:arrow-ios-upward-fill'
                          : 'eva:arrow-ios-downward-fill'
                      }
                    />
                  }
                  sx={{ fontWeight: 'fontWeightSemiBold' }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 'fontWeightBold',
                      textTransform: 'capitalize',
                    }}
                  >
                    {
                      inputFileTypeOptions.find(
                        item => item.value === inputFileType,
                      ).label
                    }
                  </Box>
                </Button>
                <CustomPopover
                  open={inputFileTypePopover.open}
                  onClose={() => {
                    inputFileTypePopover.onClose();
                    setInsideButtonClicked(false);
                  }}
                  sx={{ width: 140 }}
                >
                  {inputFileTypeOptions.map(option => (
                    <MenuItem
                      key={option.value}
                      selected={option.value === inputFileType}
                      onClick={() => {
                        setInsideButtonClicked(false);
                        inputFileTypePopover.onClose();
                        if (option.value === 'pdf')
                          onInputSourceFormatChange('');
                        onInputFileTypeChange(option.value);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomPopover>
              </> */}

        {/* {inputFileType === 'excel' && (
          <Stack
            direction="row"
            spacing={1}
            textAlign="center"
            alignItems="center"
          >
            <Typography variant="body2">
              I have {inputFileType.toLowerCase()} file of{' '}
              <Box component="span" sx={{ color: 'red' }}>
                *
              </Box>
            </Typography>

            <Button
              disableRipple
              variant="outlined"
              color="inherit"
              onClick={inputSourceFormatPopover.onOpen}
              endIcon={
                <Iconify
                  icon={
                    inputSourceFormatPopover.open
                      ? 'eva:arrow-ios-upward-fill'
                      : 'eva:arrow-ios-downward-fill'
                  }
                />
              }
              sx={{
                fontWeight: 'fontWeightSemiBold',
                fontSize: 12,
              }}
            >
              <Box
                component="span"
                sx={{
                  fontWeight: 'fontWeightBold',
                  textTransform: 'capitalize',
                }}
              >
                {inputSourceFormat
                  ? inputSourceFormatOptions.find(
                      item => item.value === inputSourceFormat,
                    ).label
                  : 'Choose Source'}
              </Box>
            </Button>

            <CustomPopover
              open={inputSourceFormatPopover.open}
              onClose={inputSourceFormatPopover.onClose}
              sx={{ p: 2.5 }}
            >
              <Stack spacing={2.5}>
                <Box
                  gap={1}
                  display="grid"
                  gridTemplateColumns="repeat(1, 1fr)"
                >
                  {inputSourceFormatOptions.map(type => (
                    <CardActionArea
                      key={type.value}
                      onClick={() => {
                        inputSourceFormatPopover.onClose();
                        onInputSourceFormatChange(type.value);
                      }}
                      sx={{
                        p: 1,
                        borderRadius: 1,
                        cursor: 'pointer',
                        border: theme =>
                          `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                        ...(type.value === inputSourceFormat && {
                          bgcolor: 'action.selected',
                        }),
                      }}
                    >
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box
                          component="img"
                          src={type.logo}
                          sx={{
                            width: 32,
                            height: 32,
                            flexShrink: 0,
                            ...sx,
                          }}
                        />
                        <Typography
                          variant={
                            type.value === inputSourceFormat
                              ? 'subtitle2'
                              : 'body2'
                          }
                        >
                          {type.label}
                        </Typography>
                      </Stack>
                    </CardActionArea>
                  ))}
                </Box>
              </Stack>
            </CustomPopover>
          </Stack>
        )}
        {inputSourceFormat === 'HIREINTEL' && (
          <Label
            sx={{ textTransform: 'none' }}
            color="info"
            startIcon={<Iconify icon="ic:outline-info" />}
          >
            <Stack
              direction="row"
              textAlign="center"
              gap={0.5}
              alignItems="center"
            >
              <Typography variant="caption">Upload Excel in this</Typography>
              <Box
                component="span"
                onClick={downloadExcelFormat}
                sx={{
                  color: 'primary.main',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                variant="caption"
              >
                Format
              </Box>
            </Stack>
          </Label>
        )} */}
      </Stack>
    </Stack>
  );

  const replaceSingleFile = hasFile && (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Iconify icon="tabler:refresh" />
      <Typography variant="input" fontWeight={400}>
        Replace
      </Typography>
    </Box>
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: theme => alpha(theme.palette.common.white, 0.8),
        bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
        '&:hover': {
          bgcolor: theme => alpha(theme.palette.grey[900], 0.48),
        },
      }}
    >
      <Iconify icon="mingcute:close-line" width={18} />
    </IconButton>
  );

  const renderMultiPreview = hasFiles && (
    <Box
      sx={{
        borderRadius: '6px',
        border: theme => `1px solid ${theme.palette.border.primaryBlack3}`,
        bgcolor: theme => theme.palette.background.primaryWhite1,
      }}
    >
      <MultiFilePreview
        files={files}
        thumbnail={thumbnail}
        onRemove={onRemove}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={theme => theme.palette.background.primaryGrey1}
        px="20px"
        py="12px"
      >
        {type === 'resume' && (
          <Typography variant="subheading2">{files.length} resumes</Typography>
        )}
        {onRemoveAll && (
          <Button
            color="inherit"
            variant="text"
            size="small"
            onClick={onRemoveAll}
          >
            Remove All
          </Button>
        )}

        {/* {onUpload && (
          <Button
            size="small"
            variant="contained"
            onClick={onUpload}
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          >
            Upload
          </Button>
        )} */}
      </Stack>
    </Box>
  );

  return (
    <Stack spacing="20px">
      <Box
        sx={{
          width: 1,
          height: 1,
          position: 'relative',
          border: theme => `1px dashed ${theme.palette.border.primaryAccent1}`,
          borderRadius: '6px',
          background: theme => theme.palette.background.primaryAccent2,
          ...sx,
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 5,
            height: '100%',
            outline: 'none',
            borderRadius: 1,
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            bgcolor: theme => alpha(theme.palette.grey[500], 0.08),
            border: theme =>
              `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            transition: theme =>
              theme.transitions.create(['opacity', 'padding']),
            '&:hover': {
              opacity: hasFile ? 1 : 0.72,
            },
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(disabled && {
              opacity: 0.48,
              pointerEvents: 'none',
            }),
            ...(hasError && {
              color: 'error.main',
              borderColor: 'error.main',
              bgcolor: theme => alpha(theme.palette.error.main, 0.08),
            }),
            ...(hasFile && {
              padding: '3% 0',
              height: '100%',
              // height: '7.85rem',
            }),
          }}
        >
          <input {...getInputProps()} />
          <Stack alignItems="center">
            {hasFile ? renderSinglePreview : renderPlaceholder}

            {replaceSingleFile}
          </Stack>
        </Box>

        {/* {removeSinglePreview} */}

        {helperText && helperText}

        <RejectionFiles fileRejections={fileRejections} />
      </Box>

      {renderMultiPreview}
    </Stack>
  );
}

Upload.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.object,
  error: PropTypes.bool,
  type: PropTypes.oneOf(['resume', 'excel', 'manual']),
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  files: PropTypes.array,
  helperText: PropTypes.object,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  sx: PropTypes.object,
  thumbnail: PropTypes.bool,
  inputFileTypeOptions: PropTypes.array,
  inputSourceFormatOptions: PropTypes.array,
  inputSourceFormat: PropTypes.object,
  inputFileType: PropTypes.object,
  onInputFileTypeChange: PropTypes.func,
  onInputSourceFormatChange: PropTypes.func,
  inputFileTypeButtonRef: PropTypes.object,
  inputSourceFormatButtonRef: PropTypes.object,
  setInsideButtonClicked: PropTypes.func,
  isExcel: PropTypes.bool,
};
