import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import ConfirmSubmitDialog from '../dialogs/confirm-submit';

export default function SubmitButton() {
  // states
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);

  return (
    <>
      <LoadingButton
        onClick={() => setOpenSubmitConfirmation(true)}
        variant="contained"
        sx={{
          color: _theme => _theme.palette.content.primaryWhite,
          bgcolor: _theme => _theme.palette.background.primaryAccent1,
          borderColor: '#5858585c',
          boxShadow: 'inset 0px 0px 0px 2px #37373742',

          '&:hover': {
            color: 'content.accent1',
            bgcolor: _theme => _theme.palette.background.primaryWhite1,
          },
        }}
      >
        Submit
      </LoadingButton>
      <ConfirmSubmitDialog
        open={openSubmitConfirmation}
        setOpen={setOpenSubmitConfirmation}
      />
    </>
  );
}
