import {
  getData,
  postData,
  getDataGuest,
  putData,
} from 'src/utils/axios/index';
import { urlMap } from 'src/utils/urls';

export const fetchInterviews = async role_id => {
  const url = `${urlMap.getInterviews}`.replace(`{job-opening-id}`, role_id);
  return getData(url);
};

export const fetchInterviewResult = async (role_id, interviewId) => {
  const url = `${urlMap.getInterviewResult}`
    .replace(`{job-opening-id}`, role_id)
    .replace(`{interview-id}`, interviewId);
  return getData(url);
};

export const bulkUpload = async (jobOpeningId, reqBody) => {
  const url = `${urlMap.bulkUpload}`.replace(`{job-opening-id}`, jobOpeningId);
  return postData(url, reqBody);
};

export const createNewInterview = async (interviewData, role_id) => {
  const url = `${urlMap.createInterview}`.replace(`{job-opening-id}`, role_id);
  return postData(url, interviewData);
};

export const regenerateInterviewUrl = async (jobOpeningId, interviewId) => {
  const url = `${urlMap.regenerateInterviewUrl}`
    .replace('{job-opening-id}', jobOpeningId)
    .replace('{interview-id}', interviewId);

  return postData(url);
};

export const generateInterviewReport = async (orgId, reqBody) => {
  const url = `${urlMap.generateReport}`.replace('{org-id}', orgId);

  return postData(url, reqBody);
};

export const getInterviewReports = async orgId => {
  const url = `${urlMap.getReports}`.replace('{org-id}', orgId);

  return getData(url);
};

export const editInterviewQuestions = async (
  org_id,
  job_opening_id,
  interviewData,
) => {
  const url = `${urlMap.editInterviewQuestion}`
    .replace('{org-id}', org_id)
    .replace('{job-opening-id}', job_opening_id);
  return putData(url, interviewData);
};

export const fetchInterviewQuestions = async interviewId => {
  const url = `${urlMap.getInterview}/${interviewId}`;
  return getData(url);
};

export const sendEmailInterview = async (jobOpeningId, reqBody) => {
  const url = `${urlMap.sendEmailInterview}`.replace(
    '{job-opening-id}',
    jobOpeningId,
  );
  return postData(url, reqBody);
};

export const postStartInterviewData = async (interview_id, token_id) => {
  const url = `${urlMap.startInterview}`
    .replace('{interview-id}', interview_id)
    .replace('{token-id}', token_id);
  return getDataGuest(url);
};

export const postEndInterviewData = async (interview_id, token_id) => {
  const url = `${urlMap.endInterview}`.replace('{interview-id}', interview_id);
  return putData(url, {}, token_id);
};

export const getInterviewDetails = async (interviewId, token_id) => {
  const url = `${urlMap.getInterviewDetails}`.replace(
    '{interview-id}',
    interviewId,
  );
  return getData(url, token_id);
};

export const editInterviewDetails = async (jobId, interviewId, reqBody) => {
  const url = `${urlMap.editInterviewDetails}`
    .replace(`{job-opening-id}`, jobId)
    .replace(`{interview-id}`, interviewId);
  return putData(url, reqBody);
};

export const acceptTermsAndPolicy = async (interviewId, tokenId) => {
  const url = `${urlMap.acceptTermsAndPolicy}`.replace(
    `{interview-id}`,
    interviewId,
  );
  return postData(url, {}, tokenId);
};


export const uploadInterviewLogs = async (interviewId, payload, token) => {
  const url = `${urlMap.uploadInterviewLogs}`.replace(
    `{interview-id}`,
    interviewId,
  );
  return postData(url, payload, token);
};