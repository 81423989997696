import { getData, postData } from 'src/utils/axios/index';
import { urlMap } from 'src/utils/urls';

export const getInterviewDetails = async (interviewId, tokenId) => {
  const url = urlMap.twoWayInterview.details.replace(
    '{interview-id}',
    interviewId,
  );

  return getData(url, tokenId);
};

export const startInterview = async (interviewId, tokenId) => {
  const url = urlMap.twoWayInterview.start.replace(
    '{interview-id}',
    interviewId,
  );

  return postData(url, {}, tokenId);
};

export const createSession = async (interviewId, tokenId, payload) => {
  const url = urlMap.twoWayInterview.createSession.replace(
    '{interview-id}',
    interviewId,
  );

  return postData(url, payload, tokenId);
};

export const getMessages = async (interviewId, tokenId) => {
  const url = urlMap.twoWayInterview.getMessages.replace(
    '{interview-id}',
    interviewId,
  );

  return getData(url, tokenId);
};

export const sendMessage = async (interviewId, payload, tokenId) => {
  const url = urlMap.twoWayInterview.sendMessage.replace(
    '{interview-id}',
    interviewId,
  );

  return postData(url, payload, tokenId);
};

export const partialInterview = async (interviewId, payload, tokenId) => {
  const url = urlMap.twoWayInterview.partSubmit.replace(
    '{interview-id}',
    interviewId,
  );

  return postData(url, payload, tokenId);
};

export const submitInterview = async (interviewId, payload, tokenId) => {
  const url = urlMap.twoWayInterview.submit.replace(
    '{interview-id}',
    interviewId,
  );

  return postData(url, payload, tokenId);
};
