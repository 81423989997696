import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  getPrescreeningQues,
  postPrescreeningResponse,
} from 'src/services/role.service';
import { trackEvent } from 'src/analytics/datatrack';
import { makeStyles } from '@mui/styles';
import {
  EVENT_APPLY_JOB_PRESCEEENING_SUBMIT,
  EVENT_APPLY_JOB_PRESCREEINIG_PAGE,
  EVENT_APPLY_JOB_PRESCREENING_SUBMIT_ERROR,
  EVENT_APPLY_JOB_PRESCREENING_SUBMIT_SUCCESS,
  EVENT_APPLY_JOB_PRESCREENING_SUCCESS,
} from 'src/analytics/trackevents';
import { NumericFormatCustom } from 'src/components/hook-form/rhf-text-field';

const useStyles = makeStyles(theme => ({
  maindiv: {
    width: '100%',
    justifyContent: 'center',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      gap: 30,
    },
  },
  rolediv: {
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  jobRole: {
    textAlign: 'center',
  },
  detailsDiv: {
    gap: 20,
    width: '100%',
  },
  questionsDiv: {
    gap: 16,
    minHeight: '50vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '68vh',
    },
  },
  form: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  question: {
    gap: 6,
  },
  btn: {
    margin: 'auto',
    margin: '2rem 0rem',
    width: '100%',
  },
}));

const PrescreeningPage = ({
  jobRole,
  jobId,
  tokenId,
  setLoader,
  setAfterPreScreenTrigger,
  setLoaderMessage,
  setPageError,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  let initialSchema = Yup.object().shape({});
  const [validationSchema, setValidationSchema] = useState(initialSchema);

  const [preScreenQuestions, setPreScreenQuestions] = useState(null);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    trigger,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const caller = async () => {
      getPrescreeningQues(jobId, tokenId)
        .then(res => {
          if (res.success) {
            console.log(res);
            if (
              res.body.prescreen_status !== 'COMPLETED' &&
              res.body.questions &&
              res.body.questions.length > 0
            ) {
              setPreScreenQuestions(res.body.questions);
              let updatedSchema,
                schemaObject = {};
              res.body.questions?.map((field, index) => {
                const { response_type } = field;
                switch (response_type) {
                  case 'NUMERIC':
                    schemaObject[`Question${index}`] = Yup.number()
                      .required(`Value must be a number`)
                      .typeError('Value must be a valid number');
                    break;
                  case 'TEXT':
                    schemaObject[`Question${index}`] = Yup.string().required(
                      `Please enter valid value`,
                    );
                    break;
                  case 'BOOLEAN':
                    schemaObject[`Question${index}`] = Yup.string().required(
                      `Value must be not be blank`,
                    );
                    break;
                  default:
                    break;
                }
              });
              updatedSchema = Yup.object().shape(schemaObject);
              setValidationSchema(updatedSchema);
            } else if (
              res.body.prescreen_status === 'COMPLETED' ||
              res?.body?.questions?.length === 0
            ) {
              setLoader(true);
              setLoaderMessage('Processing...');
              setAfterPreScreenTrigger(true);
            }
          } else {
            enqueueSnackbar({
              variant: 'error',
              message: 'Prescreening questions could not be fetched',
            });
            setLoader(true);
            setLoaderMessage('Processing...');
            setAfterPreScreenTrigger(true);
          }
        })
        .catch(err => {
          console.log(err);
          setPageError(false);
          setLoader(true);
          setLoaderMessage('Processing...');
          setAfterPreScreenTrigger(true);
        });
    };
    caller();
    trackEvent(EVENT_APPLY_JOB_PRESCREEINIG_PAGE, {});
  }, [jobId]);

  const submitHandler = async e => {
    e.preventDefault();
    const data = getValues();
    trackEvent(EVENT_APPLY_JOB_PRESCEEENING_SUBMIT, {});
    const validated = await trigger();
    if (validated) {
      console.log(data);
      setLoader(true);
      setLoaderMessage('Processing...');
      let dumm = {};
      preScreenQuestions.map((item, index) => {
        dumm[item.question_id] = data[`Question${index}`];
      });
      const payload = { response_map: dumm };
      console.log(payload);
      postPrescreeningResponse(payload, jobId, tokenId)
        .then(res => {
          console.log(res);
          if (res.success) {
            enqueueSnackbar('Prescreening form has been submitted');
            trackEvent(EVENT_APPLY_JOB_PRESCREENING_SUBMIT_SUCCESS, {});
          } else {
            enqueueSnackbar({
              variant: 'error',
              message: 'Prescreening form submission failed..',
            });
            trackEvent(EVENT_APPLY_JOB_PRESCREENING_SUBMIT_ERROR, {});
          }
          setAfterPreScreenTrigger(true);
        })
        .catch(err => {
          console.log(err);
          trackEvent(EVENT_APPLY_JOB_PRESCREENING_SUBMIT_ERROR, {});
          enqueueSnackbar({
            variant: 'error',
            message:
              'Technical problem in submitting prescreening form, please contact HR',
          });
          setPageError(false);
          setAfterPreScreenTrigger(true);
        });
    } else {
      // errors automatically shown corresponding to the fields
    }
  };
  watch();

  return (
    preScreenQuestions && (
      <Stack className={classes.maindiv}>
        <Stack className={classes.rolediv}>
          <Typography variant="h3" className={classes.jobRole}>
            {jobRole}
          </Typography>
        </Stack>
        <Stack className={classes.detailsDiv}>
          <Typography variant="subheading1">Application details</Typography>
          <form className={classes.form} onSubmit={handleSubmit(submitHandler)}>
            <Stack className={classes.questionsDiv}>
              {preScreenQuestions?.map((item, index) => (
                <Stack key={index} className={classes.question}>
                  <Typography variant="subtitle1">{item.text}</Typography>
                  {item.response_type === 'NUMERIC' ? (
                    <TextField
                      name={`Question${index}`}
                      value={getValues()[`Question${index}`]}
                      onChange={e => {
                        setValue(`Question${index}`, e.target.value);
                      }}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      error={Boolean(errors[`Question${index}`])}
                      helperText={errors[`Question${index}`]?.message}
                    />
                  ) : null}
                  {item.response_type === 'BOOLEAN' ? (
                    <FormControl fullWidth>
                      <Select
                        name={`Question${index}`}
                        value={getValues()[`Question${index}`]}
                        onChange={e => {
                          setValue(`Question${index}`, e.target.value);
                        }}
                        error={Boolean(errors[`Question${index}`])}
                      >
                        <MenuItem key="YES" value="YES">
                          Yes
                        </MenuItem>
                        <MenuItem key="NO" value="NO">
                          No
                        </MenuItem>
                      </Select>
                      {errors[`Question${index}`] ? (
                        <FormHelperText style={{ color: '#ff7150' }}>
                          {errors[`Question${index}`]?.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  ) : null}
                  {item.response_type === 'TEXT' ? (
                    <TextField
                      type="text"
                      name={`Question${index}`}
                      value={getValues()[`Question${index}`]}
                      onChange={e => {
                        setValue(`Question${index}`, e.target.value);
                      }}
                      multiline
                      error={Boolean(errors[`Question${index}`])}
                      helperText={errors[`Question${index}`]?.message}
                    />
                  ) : null}
                </Stack>
              ))}
            </Stack>
            <Stack>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={submitHandler}
                size="large"
              >
                Continue
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    )
  );
};

export default PrescreeningPage;
