import { detect } from 'detect-browser';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBoolean } from 'src/hooks/use-boolean';

export default function useBrowserCheck({ deviceType }) {
  // variables
  const browser = detect();

  // states
  const [browserCompatible, setBrowserCompatible] = useState(false);
  const isBrowserCompatibilityChecking = useBoolean(true);
  const isBrowserCompatibilityChecked = useBoolean(false);

  // effects
  useEffect(() => {
    if (!isBrowserCompatibilityChecked.value) {
      if (deviceType === 'mobile') {
        if (
          [
            'safari',
            'chrome',
            'chrome-mobile',
            'edge-ios',
            'edge',
            'ios',
            'android',
            'crios',
          ].includes(browser?.name)
        ) {
          setBrowserCompatible(true);
        } else {
          setBrowserCompatible(false);
        }
      } else if (deviceType === 'desktop') {
        if (
          ['safari', 'chrome', 'edge', 'edge-chromium'].includes(browser?.name)
        ) {
          setBrowserCompatible(true);
        } else {
          setBrowserCompatible(false);
        }
      }

      setTimeout(() => {
        isBrowserCompatibilityChecking.onFalse();
        isBrowserCompatibilityChecked.onTrue();
      }, 1000);
    }
  }, [deviceType]);

  return {
    browserCompatible,
    setBrowserCompatible,
    isBrowserCompatibilityChecking,
    isBrowserCompatibilityChecked,
  };
}

useBrowserCheck.propTypes = {
  deviceType: PropTypes.string,
};
