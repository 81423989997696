import { Editor } from '@monaco-editor/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import './styles/index.css';
import { boilerplateCodes } from './constants/boilerplateCodes';

export default function CodeEditor({
  onChange,
  language,
  code,
  theme,
  ...props
}) {
  const [value, setValue] = useState('');

  const handleEditorChange = _value => {
    setValue(_value);
    onChange('code', _value);
  };

  useEffect(() => {
    console.log(language);
    if (!code || code === '') {
      if (language) {
        const boilerplate = boilerplateCodes.find(item => item.language === language);
        if (boilerplate) {
          setValue(boilerplate.code);
          console.log(boilerplate.code);
        } else {
          console.log(`No boilerplate code found for language: ${language}`);
        }
      } else {
        console.log('Language is null or undefined');
      }
    } else {
      setValue(code);
    }
  }, [code, language]);
  
  return (
    <Editor
      width="100%"
      language={language || 'javascript'}
      value={value}
      theme={theme}
      className="code-editor"
      onChange={handleEditorChange}
      {...props}
    />
  );
}

CodeEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  code: PropTypes.string,
  theme: PropTypes.string,
};
