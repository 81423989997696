export const EVENT_INTERVIEW_PERMISSION_DENIED="interview_permission_denied"
export const EVENT_INTERVIEW_LOAD_ERROR ="interview_load_error"
export const EVENT_INTERVIEW_EXPIRED="interview_expired"
export const EVENT_INTERVIEW_START_BUTTON_CLICKED="interview_start_button_clicked"
export const EVENT_INTERVIEW_NEXT_QUESTION_CLICKED="interview_next_question_clicked"
export const EVENT_INTERVIEW_END_INTERVIEW_CLICKED="interview_end_interview_clicked"
export const EVENT_INTERVIEW_INSTRUCTION_PAGE="interview_instruction_page"
export const EVENT_INTERVIEW_INSTRUCTION_PAGE_CONTINUE="interview_instruction_page_continue"
export const EVENT_INTERVIEW_PERMISSION_PAGE_START="interview_permission_page_start"
export const EVENT_INTERVIEW_DEMO_PAGE="interview_demo_page"
export const EVENT_INTERVIEW_PRESCREEN_PAGE="interview_prescreen_page"
export const EVENT_INTERVIEW_PRESCREEN_SKIP="interview_prescreen_skip"
export const EVENT_INTERVIEW_PRESCREEN_SUBMIT="interview_prescreen_submit"
export const EVENT_INTERVIEW_PRESCREEN_ERROR="interview_prescreen_error"
export const EVENT_INTERVIEW_PRESCREEN_SUCCESS="interview_prescreen_success"
export const EVENT_INTERVIEW_DEMO_CONTINUE="interview_demo_continue"
export const EVENT_INTERVIEW_LAUNCH_PAGE="interview_launch_page"
export const EVENT_INTERVIEW_JOB_PAGE_GET_STARTED="interview_job_page_get_started"
export const EVENT_INTERVIEW_JOB_PAGE="interview_job_page"
export const EVENT_INTERVIEW_JOB_PAGE_MOBILE="interview_job_page_mobile"
export const EVENT_INTERVIEW_JOB_PAGE_DESKTOP="interview_job_page_desktop"
export const EVENT_INTERVIEW_CONGRATULATIONS="interview_congratulations"
export const EVENT_SCREEN_RECORDING_NOT_AVAILABLE = "screen_recording_not_available"
export const EVENT_APPLY_JOB_PAGE_VIEW = "apply_job_page_view"
export const EVENT_APPLY_JOB_APPLY_CLICK="apply_job_apply_click"
export const EVENT_APPLY_JOB_UPLOAD_CLICK="apply_job_upload_click"
export const EVENT_APPLY_JOB_RESUME_ERROR="apply_job_resume_error"
export const EVENT_APPLY_JOB_RESUME_SUCCESS="apply_job_resume_success"
export const EVENT_APPLY_JOB_RESUME_FILE_PDF="apply_job_resume_file_pdf"
export const EVENT_APPLY_JOB_RESUME_FILE_DOC="apply_job_resume_file_doc"
export const EVENT_APPLY_JOB_RESUME_FILE_DOCX="apply_job_resume_file_docx"
export const EVENT_APPLY_JOB_EMAIL_SUBMIT="apply_job_email_submit"
export const EVENT_APPLY_JOB_EMAIL_ERROR="apply_job_email_error"
export const EVENT_APPLY_JOB_EMAIL_SUCCESS="apply_job_email_success"
export const EVENT_APPLY_JOB_PRESCREENING_SUCCESS="apply_job_prescreening_success"
export const EVENT_APPLY_JOB_LOAD_ERROR="apply_job_load_error"
export const EVENT_APPLY_JOB_WS_DIRECT_INTERVIEW="apply_job_ws_direct_interview"
export const EVENT_APPLY_JOB_WS_NO_MESSAGE="apply_job_ws_no_message"
export const EVENT_APPLY_JOB_WS_SHORTLISTED="apply_job_ws_shortlisted"
export const EVENT_APPLY_JOB_WS_NOT_SHORTLISTED="apply_job_ws_not_shortlisted"
export const EVENT_APPLY_JOB_TAKE_INTERVIEW="apply_job_take_interview"
export const EVENT_APPLY_JOB_SCHEDULE_INTERVIEW = "apply_job_schedule_interview"
export const EVENT_APPLY_JOB_PRESCREENING_LOAD_ERROR="apply_job_prescreening_load_error"
export const EVENT_APPLY_JOB_PRESCEEENING_SUBMIT="apply_job_presceening_submit"
export const EVENT_APPLY_JOB_PRESCREENING_SUBMIT_ERROR="apply_job_prescreening_submit_error"
export const EVENT_APPLY_JOB_PRESCREENING_SUBMIT_SUCCESS="apply_job_prescreening_submit_success"
export const EVENT_APPLY_JOB_APPLICATION_SUBMISSION_PAGE="apply_job_application_submission_page"
export const EVENT_APPLY_JOB_SHORTLIST_PAGE="apply_job_shortlist_page"
export const EVENT_APPLY_JOB_PRESCREEINIG_PAGE="apply_job_prescreeinig_page"
export const EVENT_APPLY_JOB_RESUME_PARSE_FAIL_PAGE="apply_job_resume_parse_fail_page"
export const EVENT_SHORTLINK_LOAD_ERROR="shortlink_load_error"





