import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, Stack, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SvgColor from 'src/components/svg-color/svg-color';
import PropTypes from 'prop-types';
import { useQuery } from 'src/hooks/use-query';
import { useBoolean } from 'src/hooks/use-boolean';
import Loader from 'src/components/loader';
import { uploadVideoToS3 } from 'src/services/aws.service';
import { RecordingWaveAnimation } from 'src/assets/animations/components/recording-wave';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';
import {
  getMessages,
  partialInterview,
  sendMessage,
  submitInterview,
} from 'src/services/two-way-interview.service';
import useSpeechToText from 'react-hook-speech-to-text';
import Image from 'src/components/image';
import Webcamera from '../../components/webcam';
import StopWatch from '../../components/stop-watch';
import SubmittingPage from '../submitting-page';
import { CONGRATULATIONS_PAGE } from '../constants';
import { useMediaRecorder } from '../../hooks/use-media-recorder';
import { EndTimer } from './end-timer';
import VideoTimer from './video-timer';

const Questions = ({
  questions,
  currentQuestion,
  isQuestionSubmitting,
  onNextQuestion,
  totalQuestions,
  isQuestionsLoading,
  webCamHidden,
}) => {
  // functions
  const handleOnClick = () => {
    onNextQuestion();
  };

  const mdUp = useResponsive('up', 'md');

  return isQuestionsLoading.value ? (
    <Loader />
  ) : (
    <Stack
      justifyContent="space-between"
      height="100%"
      sx={{
        gap: webCamHidden ? '330px' : '90px',
        transition: theme =>
          theme.transitions.create(['gap'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
          }),
      }}
    >
      <Stack>
        <Typography
          sx={{ lineHeight: 'normal' }}
          variant="subheading1"
          color={{
            xs: 'content.primaryWhite',
            md: 'content.primaryBlack1',
          }}
        >
          {questions[currentQuestion].question}
        </Typography>
      </Stack>
    </Stack>
  );
};

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  isQuestionSubmitting: PropTypes.object.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  isQuestionsLoading: PropTypes.object.isRequired,
  webCamHidden: PropTypes.bool,
};

const BottomDrawer = ({ open, webCamHidden, children }) => (
  <Drawer
    sx={{
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        transition: theme =>
          theme.transitions.create(['min-height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
          }),
        boxSizing: 'border-box',
        zIndex: 1,
      },
    }}
    PaperProps={{
      style: {
        background: `linear-gradient(
          180deg,
          rgba(22.03, 22.03, 22.03, 0.68) 0%,
          rgba(25.98, 25.98, 25.98, 0.61) 32%,
          rgba(30.8, 30.8, 30.8, 0.45) 54.5%,
          rgba(95.66, 95.66, 95.66, 0.17) 76.5%,
          rgba(0, 0, 0, 0) 92.5%,
          rgba(255, 255, 255, 0) 100%
        )`,
        border: 'none',
      },
    }}
    variant="persistent"
    anchor="top"
    open={open}
  >
    {open && children}
  </Drawer>
);

BottomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  webCamHidden: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const InterviewRunPage = ({
  setPageState,
  companyLogo,
  interviewId = '6683e77a0d56fdf8b928fc65',
}) => {
  // refs
  const mobileSubmitBtnRef = useRef(null);

  // hooks
  const searchParams = useQuery();
  const mdUp = useResponsive('up', 'md');
  const token = searchParams.get('token');

  // refs
  const stopwatchRef = useRef(null);
  const webcamRef = useRef(null);

  // states
  const [webCamHidden, setWebCamHidden] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const isQuestionsLoading = useBoolean(true);
  const isQuestionSubmitting = useBoolean(false);
  const [videoRecordingUrl, setVideoRecordingUrl] = useState('');
  const [videoRecordings, setVideoRecordings] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingInterview, setIsSubmittingInterview] = useState(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState(true);
  const [currMessage, setCurrMessage] = useState(null);
  const [speechStatus, setSpeechStatus] = useState('');
  const [shouldEndInterview, setShouldEndInterview] = useState(false);
  const [showEndTimer, setShowEndTimer] = useState(false);

  // constants
  const videoWidth = mdUp ? 640 : 426;
  const videoHeight = mdUp ? 360 : 240;
  const aspectRatio = mdUp ? 1.778 : 0.5625;

  // functions
  const getVideoUrl = useCallback(
    async (videoBlob, _currentQuestionId) => {
      try {
        const folder = `two-way-interview/${interviewId}`;
        return uploadVideoToS3(videoBlob, folder);
      } catch (err) {
        console.log(err);
      }

      return null;
    },
    [interviewId],
  );

  const addVideoRecording = videoUrl => {
    setVideoRecordings(prevProps => [...prevProps, videoUrl]);
  };

  const onStopVideo = async (videoBlob, currentQuestionIndex) => {
    try {
      const videoUrl = await getVideoUrl(videoBlob, currentQuestionIndex + 1);

      if (videoUrl) {
        setVideoRecordingUrl(videoUrl);
        addVideoRecording(videoUrl);
      }
    } catch (err) {
      console.log('Error in processing video blob');
      console.log(err);
    }
  };

  const { startRecording, stopRecording, permission } = useMediaRecorder({
    video: {
      width: videoWidth,
      height: videoHeight,
      aspectRatio,
      frameRate: 12,
      facingMode: 'user',
    },
    onVideoStop: async (videoBlob, _currentQuestionIndex) => {
      onStopVideo(videoBlob, _currentQuestionIndex);
    },
  });

  const {
    error,
    interimResult,
    isRecording,
    results,
    setResults,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  // functions
  const onSpeechStart = useCallback(() => {
    setSpeechStatus('started');
  }, []);

  const onSpeechEnd = useCallback(() => {
    setSpeechStatus('stopped');
    setTimeout(() => {
      startSpeechToText();
    }, 1000);
  }, [startSpeechToText]);

  const speakTextNative = useCallback(
    text => {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.onstart = onSpeechStart;
      utterance.onend = onSpeechEnd;

      const synth = window.speechSynthesis;
      synth.speak(utterance);
    },
    [onSpeechStart, onSpeechEnd],
  );

  const speakTextOpenAI = useCallback(
    audioBase64 => {
      const audio = new Audio(audioBase64);

      audio.addEventListener('play', onSpeechStart);

      audio.addEventListener('ended', onSpeechEnd);

      audio.play();
    },
    [onSpeechEnd, onSpeechStart],
  );

  const fetchMessages = useCallback(async () => {
    try {
      const response = await getMessages(interviewId, token);

      if (response.success) {
        const lastMessage = response.data[0];

        if (lastMessage?.role === 'assistant') {
          const { audio: audioContent, ...messageContent } = lastMessage;
          setCurrMessage(messageContent);

          if (audioContent) {
            speakTextOpenAI(audioContent);
          } else {
            speakTextNative(messageContent.value);
          }

          if (
            lastMessage.value
              .toLowerCase()
              .includes('Your interview is completed'.toLowerCase())
          ) {
            setShowEndTimer(true);
          }
        }
      } else throw new Error(response.message);
    } catch (err) {
      console.log(err);
    } finally {
      setIsMessagesLoading(false);
    }
  }, [interviewId, token]);

  const sendMessages = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        message: results.map(result => result.transcript).join(' '),
        role: 'user',
      };

      stopSpeechToText();
      setResults([]);

      const response = await sendMessage(interviewId, payload, token);

      if (response.success) {
        const { audio: audioContent, ...messageContent } = response.data;
        setCurrMessage(messageContent);

        if (audioContent) {
          speakTextOpenAI(audioContent);
        } else {
          speakTextNative(messageContent.value);
        }

        if (
          response.data.value
            .toLowerCase()
            .includes('Your interview is completed'.toLowerCase())
        ) {
          setShowEndTimer(true);
        }
      } else throw new Error(response.message);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = useCallback(
    (_currentQuestionIndex, isPartialSubmit = false) => {
      if (!isPartialSubmit) {
        setIsSubmittingInterview(true);
      }
      stopRecording(_currentQuestionIndex, isPartialSubmit);
    },
    [setIsSubmittingInterview, stopRecording],
  );

  const endCodingInterview = useCallback(
    async (isPartialSubmit = false) => {
      try {
        const payload = {
          video_recordings: videoRecordings,
        };

        let response = null;

        if (isPartialSubmit) {
          response = await partialInterview(interviewId, payload, token);
        } else {
          response = await submitInterview(interviewId, payload, token);
        }

        if (response?.success) {
          console.log(response);
          if (!isPartialSubmit) {
            setPageState(CONGRATULATIONS_PAGE);
          }
        } else throw new Error(response?.message);
      } catch (err) {
        console.log(err);
      } finally {
        setIsSubmitting(false);
        setVideoRecordingUrl('');
      }
    },
    [interviewId, setPageState, token, videoRecordings],
  );

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    if (permission) {
      startRecording(0);
    }
  }, [permission]);

  useEffect(() => {
    if (shouldEndInterview) {
      onSubmit(0, false);
    }
  }, [shouldEndInterview]);

  useEffect(() => {
    if (videoRecordingUrl && videoRecordings.length > 0) {
      endCodingInterview(!shouldEndInterview);
    }
  }, [videoRecordingUrl, videoRecordings, shouldEndInterview]);

  console.log('permission', permission);

  return isSubmittingInterview ? (
    <SubmittingPage />
  ) : (
    <>
      <VideoTimer
        onSubmit={onSubmit}
        setShouldEndInterview={setShouldEndInterview}
        shouldEndInterview={shouldEndInterview}
      />
      <Stack
        sx={{
          marginTop: { xs: '0px', md: '0px' },
          height: { xs: '100vh', md: '100vh' },
          maxHeight: { xs: '100vh', md: '100vh' },
          width: '100%',
          position: 'relative',
          flexDirection: 'row',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: { xs: '100%', md: '50%' },
            height: '100%',
            bgcolor: theme => theme.palette.background.primaryBlack1,
          }}
        >
          <Stack
            sx={{
              width: '100%',
              position: 'absolute',
              display: { xs: 'none', md: 'flex' },
              padding: '16px',
              zIndex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack direction="row" alignItems="center" spacing="12px">
              <Stack>
                <SvgColor
                  sx={{
                    bgcolor: theme => theme.palette.content.primaryRed1,
                    width: '33px',
                    height: '33px',
                  }}
                  src="/assets/icons/interview-page/recording-red.svg"
                />
                <StopWatch />
              </Stack>
              {!webCamHidden && <RecordingWaveAnimation size="medium" />}
            </Stack>

            <IconButton
              sx={{
                bgcolor: mdUp ? '#50505029' : 'none',
                backdropFilter: mdUp ? 'blur(19.18px)' : 'none',
              }}
              onClick={() => {
                setWebCamHidden(prev => !prev);
              }}
            >
              <Iconify
                color="content.primaryWhite"
                width={32}
                icon={`tabler:picture-in-picture-${
                  webCamHidden ? 'off' : 'on'
                }`}
              />
            </IconButton>
          </Stack>

          {mdUp && webCamHidden && (
            <Stack
              position="absolute"
              height="100%"
              width="100%"
              alignItems="center"
              justifyContent="center"
              top="0"
              left="0"
              bottom="0"
              right="0"
            >
              <RecordingWaveAnimation size="large" />
            </Stack>
          )}
          <Webcamera
            width={videoWidth}
            height={videoHeight}
            videoPreview={!webCamHidden}
            webcamRef={webcamRef}
          />
        </Stack>
        <Stack
          position="absolute"
          id="mobile-submit-btn"
          display={{
            xs: 'flex',
            md: 'none',
          }}
          bottom={0}
          width="100%"
          paddingX="16px"
          paddingY="24px"
        >
          <LoadingButton
            loading={isQuestionSubmitting.value}
            loadingPosition="start"
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              setTimeout(() => {
                sendMessages();
              }, 1000);
            }}
            sx={{
              background: theme => ({
                xs: theme.palette.background.primaryAccent1,
                md: theme.palette.content.primaryBlack1,
              }),
            }}
          >
            Submit
          </LoadingButton>
        </Stack>

        <Stack
          position="absolute"
          display={{
            xs: 'flex',
            md: 'none',
          }}
          bottom={0}
          width="100%"
          paddingX="16px"
          paddingY="24px"
        >
          <LoadingButton
            ref={mobileSubmitBtnRef}
            loading={isQuestionsLoading.value || isQuestionSubmitting.value}
            loadingPosition="start"
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              setTimeout(() => {
                sendMessages();
              }, 1000);
            }}
            sx={{
              background: theme => ({
                xs: theme.palette.background.primaryAccent1,
                md: theme.palette.content.primaryBlack1,
              }),
            }}
          >
            {isQuestionsLoading.value && 'Loading...'}
            {!isQuestionsLoading.value &&
              (currentQuestion + 1 < totalQuestions
                ? 'Next Question'
                : 'Submit')}
          </LoadingButton>
        </Stack>
        {mdUp && (
          <Stack
            sx={{ width: { xs: '100%', md: '50%' }, height: '100%' }}
            paddingY="40px"
            paddingX="16px"
            spacing="16px"
            position="relative"
            bgcolor={theme => theme.palette.background.primaryGrey2}
          >
            <Stack direction="row" alignItems="center" spacing="8px">
              {(!speechStatus || isMessagesLoading || isSubmitting) && (
                <>
                  <Image
                    sx={{
                      width: '28px',
                      height: '28px',
                    }}
                    src="/assets/icons/interview-page/listening.svg"
                    alt="listening"
                  />
                  <Typography
                    variant="subheading1"
                    color="content.accent1"
                    fontSize={18}
                  >
                    Thinking
                  </Typography>
                </>
              )}
              {speechStatus && !isMessagesLoading && !isSubmitting && (
                <>
                  <RecordingWaveAnimation size="medium" />
                  <Typography
                    variant="subheading1"
                    color="content.accent1"
                    fontSize={18}
                  >
                    {speechStatus === 'started' ? 'Speaking' : 'Listening'}
                  </Typography>
                </>
              )}
            </Stack>

            {isMessagesLoading ? (
              <Loader />
            ) : (
              <Typography
                sx={{ lineHeight: 'normal' }}
                variant="subheading1"
                color={{
                  xs: 'content.primaryWhite',
                  md: 'content.primaryBlack1',
                }}
              >
                {currMessage?.value}
              </Typography>
            )}
            {showEndTimer ? (
              <EndTimer
                showEndTimer={showEndTimer}
                setShouldEndInterview={setShouldEndInterview}
              />
            ) : (
              <>
                <Stack
                  height="100%"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  spacing="12px"
                >
                  <IconButton
                    variant="outlined"
                    disabled={
                      isMessagesLoading ||
                      isSubmitting ||
                      speechStatus === 'started'
                    }
                    onClick={() => {
                      if (isRecording) {
                        stopSpeechToText();
                      } else {
                        startSpeechToText();
                      }
                    }}
                    sx={{
                      width: '76px',
                      height: '76px',
                      borderRadius: '50%',
                      ...(!(isMessagesLoading || isSubmitting) && {
                        border: theme =>
                          isRecording
                            ? `1px solid ${theme.palette.content.primaryBlack3}`
                            : `1px solid ${theme.palette.content.primaryRed1}`,
                        background: theme =>
                          isRecording
                            ? theme.palette.content.primaryWhite1
                            : theme.palette.content.primaryRed1,
                        color: theme =>
                          isRecording
                            ? theme.palette.content.primaryBlack1
                            : theme.palette.content.primaryWhite1,

                        '&:hover': {
                          background: theme =>
                            isRecording
                              ? theme.palette.content.primaryWhite1
                              : theme.palette.content.primaryRed1,
                        },
                      }),
                      boxShadow: theme => '0px 10px 15px -3px rgba(0,0,0,0.1)',

                      '&:disabled': {
                        background: theme => theme.palette.content.primaryRed1,
                        color: theme => theme.palette.content.primaryWhite1,
                        border: theme =>
                          `1px solid ${theme.palette.content.primaryRed1}`,
                      },
                    }}
                  >
                    {isRecording && (
                      <Iconify width={38} icon="tabler:microphone" />
                    )}
                    {!isRecording && (
                      <Iconify
                        width={38}
                        color={theme => theme.palette.content.primaryWhite}
                        icon="tabler:microphone-off"
                      />
                    )}
                  </IconButton>
                  <Typography
                    variant="subheading1"
                    color="content.primaryBlack1"
                    height="32px"
                  >
                    {isRecording && 'Please answer, then click "Submit"'}
                    {!isRecording &&
                      !isMessagesLoading &&
                      !isSubmitting &&
                      speechStatus === 'stopped' &&
                      'To Speak, Please unmute'}
                    {!isRecording &&
                      (isMessagesLoading ||
                        isSubmitting ||
                        !speechStatus ||
                        speechStatus === 'started') &&
                      'Please wait...'}
                  </Typography>
                </Stack>
                <Stack
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  paddingX="16px"
                  paddingY="24px"
                >
                  <LoadingButton
                    variant="contained"
                    disabled={isMessagesLoading || isSubmitting}
                    sx={{
                      background: theme => ({
                        xs: theme.palette.background.primaryAccent1,
                        md: theme.palette.content.primaryBlack1,
                      }),
                      '&:disabled': {
                        background: theme => theme.palette.background.primaryGrey3,
                      },
                    }}
                    onClick={() => {
                      setTimeout(() => {
                        sendMessages();
                      }, 1000);
                    }}
                    loading={isSubmitting}
                    loadingPosition="end"
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </LoadingButton>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Stack>
      {!mdUp && (
        <BottomDrawer open={drawerOpen} webCamHidden={webCamHidden}>
          <Stack
            sx={{
              width: '100%',
              padding: '8px 16px',
              zIndex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#161616ad',
              border: 'none',
            }}
          >
            <Stack direction="row" alignItems="center" spacing="12px">
              <Stack>
                <SvgColor
                  sx={{
                    bgcolor: theme => theme.palette.content.primaryRed1,
                    width: '27px',
                    height: '27px',
                  }}
                  src="/assets/icons/interview-page/recording-red.svg"
                />
                <StopWatch ref={stopwatchRef} />
              </Stack>
              <RecordingWaveAnimation size="small" width={14} height={14} />
            </Stack>
            {!mdUp && (
              <IconButton
                onClick={() => {
                  setWebCamHidden(prev => !prev);
                }}
              >
                <Iconify
                  color="#E8E8E8"
                  width={24}
                  icon={`tabler:picture-in-picture-${
                    webCamHidden ? 'off' : 'on'
                  }`}
                />
              </IconButton>
            )}
          </Stack>
          <Stack
            sx={{
              border: 'none',
              background: `linear-gradient(
                180deg,
                rgba(22.03, 22.03, 22.03, 0.68) 0%,
                rgba(25.98, 25.98, 25.98, 0.61) 32%,
                rgba(30.8, 30.8, 30.8, 0.45) 54.5%,
                rgba(95.66, 95.66, 95.66, 0.17) 76.5%,
                rgba(0, 0, 0, 0) 92.5%,
                rgba(255, 255, 255, 0) 100%
              )`,
              minHeight: '140px',
              maxHeight: '280px',
              overflowY: 'auto',
              paddingX: '16px',
              paddingTop: '20px',
              paddingBottom: '20%',
            }}
          >
            {/* <Questions
              questions={questions}
              currentQuestion={currentQuestion}
              totalQuestions={totalQuestions}
              isQuestionsLoading={isQuestionsLoading}
              isQuestionSubmitting={isQuestionSubmitting}
              onNextQuestion={onNextQuestion}
              webCamHidden={webCamHidden}
            /> */}
          </Stack>
        </BottomDrawer>
      )}
    </>
  );
};

InterviewRunPage.propTypes = {
  setPageState: PropTypes.func.isRequired,
  companyLogo: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default InterviewRunPage;
