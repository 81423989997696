import React, { useEffect, useMemo, useState } from 'react';
import { getPublicJobDetails } from 'src/services/role.service';
import { useNavigate } from 'react-router';
import {
  JOB_APPLY_APPLICATION_SUBMITTED,
  JOB_APPLY_JOB_DESCRIPTION,
  JOB_APPLY_PRESCREENING,
  JOB_APPLY_RESUME_PARSING_FAILED,
  JOB_APPLY_RESUME_UPLOAD,
  JOB_APPLY_SHORTLISTED,
} from 'src/sections/apply-for-job/view/constants';
import { WEB_SOCKET_URL } from 'src/config-global';
import useWebSocket from 'src/hooks/use-websocket';
import logger from 'src/utils/logger/logger.js';
import NotFoundPage from 'src/pages/404';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_APPLY_JOB_LOAD_ERROR,
  EVENT_APPLY_JOB_WS_DIRECT_INTERVIEW,
  EVENT_APPLY_JOB_WS_NOT_SHORTLISTED,
  EVENT_APPLY_JOB_WS_NO_MESSAGE,
  EVENT_APPLY_JOB_WS_SHORTLISTED,
} from 'src/analytics/trackevents';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import ResumeParsingFailed from '../resume-parsing-failed';
import ResumeUpload from './resume-upload';
import Loader from '../loader';
import JobDescription from './job-description';
import PrescreeningPage from './prescreening-view';
import Layout from './layout';
import ApplicationSubmitted from './application-submitted';
import ApplicationShortlisted from './application-shortlisted';

const WEB_SOCKET_NO_MESSAGE = 'WEB_SOCKET_NO_MESSAGE';
const WEB_SOCKET_SHORTLISTED = 'WEB_SOCKET_SHORTLISTED';
const WEB_SOCKET_NOT_SHORTLISTED = 'WEB_SOCKET_NOT_SHORTLISTED';

const ApplyForJobView = ({ orgId, jobId }) => {
  // states
  const [loader, setLoader] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState('Loading...');
  const [company, setCompany] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [jobPublished, setJobPublished] = useState(false);
  const [pageState, setPageState] = useState(JOB_APPLY_JOB_DESCRIPTION);
  const [resumeUrl, setResumeUrl] = useState('');
  const [clientId, setClientId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [interviewUrl, setInterviewUrl] = useState(null);
  const [afterPreScreenTrigger, setAfterPreScreenTrigger] = useState(false);
  const [webSocketStatus, setWebSocketStatus] = useState(null);
  const [pageError, setPageError] = useState(false);

  // hooks
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // variables
  const wsUrl = useMemo(() => {
    if (clientId && WEB_SOCKET_URL) {
      return `${WEB_SOCKET_URL}?client_id=${clientId}`;
    }
    return null;
  }, [clientId]);

  logger.debug(`REACT_APP_WEB_SOCKET_URL is ${WEB_SOCKET_URL}`);
  logger.debug(`Client Id is ${clientId}`);
  logger.debug(`Web socket url is ${wsUrl}`);

  const { socketMessage, noMessage } = useWebSocket(wsUrl);

  // effects
  useEffect(() => {
    if (interviewUrl) {
      setWebSocketStatus(WEB_SOCKET_SHORTLISTED);
      trackEvent(EVENT_APPLY_JOB_WS_DIRECT_INTERVIEW, {});
    } else if (noMessage === true) {
      setWebSocketStatus(WEB_SOCKET_NO_MESSAGE);
      trackEvent(EVENT_APPLY_JOB_WS_NO_MESSAGE, {});
    } else if (socketMessage) {
      if (socketMessage.message.profile_request_status == 'AI_INTERVIEW') {
        console.log('Shortlisted');
        setWebSocketStatus(WEB_SOCKET_SHORTLISTED);
        setInterviewUrl(socketMessage.message.interview_url);
        trackEvent(EVENT_APPLY_JOB_WS_SHORTLISTED, {});
      } else {
        console.log('Not shortlisted');
        setWebSocketStatus(WEB_SOCKET_NOT_SHORTLISTED);
        trackEvent(EVENT_APPLY_JOB_WS_NOT_SHORTLISTED, {});
      }
    }
  }, [socketMessage, noMessage, interviewUrl]);

  useEffect(() => {
    if (afterPreScreenTrigger) {
      if (webSocketStatus !== null) {
        setLoader(false);
        if (
          webSocketStatus === WEB_SOCKET_NOT_SHORTLISTED ||
          webSocketStatus === WEB_SOCKET_NO_MESSAGE
        ) {
          setPageState(JOB_APPLY_APPLICATION_SUBMITTED);
        } else if (webSocketStatus === WEB_SOCKET_SHORTLISTED) {
          setPageState(JOB_APPLY_SHORTLISTED);
        }
      }
    }
  }, [afterPreScreenTrigger, webSocketStatus]);

  useEffect(() => {
    const callerFunc = async () => {
      setLoader(true);
      getPublicJobDetails(orgId, jobId)
        .then(res => {
          if (res.success) {
            setCompany(res.body.organization_details.name);
            setCompanyLogo(res.body.organization_details.logo_url);
            setJobRole(res.body.job_opening_details.name);
            setJobDescription(res.body.job_opening_details.job_description);
            setJobPublished(
              res.body.job_opening_details.public_apply_link_enabled,
            );
            setLoader(false);
          } else {
            enqueueSnackbar({
              variant: 'error',
              message: 'Could not fetch job details !!',
            });
            trackEvent(EVENT_APPLY_JOB_LOAD_ERROR, {});
          }
        })
        .catch(error => {
          console.log(error);
          trackEvent(EVENT_APPLY_JOB_LOAD_ERROR, {});
        });
    };
    callerFunc();
  }, [jobId]);

  return (
    <>
      {pageError && <NotFoundPage />}
      {!pageError &&
        (loader ? (
          <Loader
            companyLogo={companyLogo}
            loader={loader}
            setLoader={setLoader}
            loaderMessage={loaderMessage}
          />
        ) : (
          <Layout companyLogo={companyLogo}>
            {pageState === JOB_APPLY_JOB_DESCRIPTION && (
              <JobDescription
                jobDescription={jobDescription}
                jobRole={jobRole}
                company={company}
                jobPublished={jobPublished}
                setPageState={setPageState}
                jobId={jobId}
                orgId={orgId}
              />
            )}
            {pageState === JOB_APPLY_RESUME_UPLOAD && (
              <ResumeUpload
                jobRole={jobRole}
                jobId={jobId}
                orgId={orgId}
                setLoader={setLoader}
                setPageState={setPageState}
                setResumeUrl={setResumeUrl}
                setClientId={setClientId}
                setTokenId={setTokenId}
                setInterviewUrl={setInterviewUrl}
                setLoaderMessage={setLoaderMessage}
                setPageError={setPageError}
              />
            )}
            {pageState === JOB_APPLY_RESUME_PARSING_FAILED && (
              <ResumeParsingFailed
                setInterviewUrl={setInterviewUrl}
                jobId={jobId}
                resumeUrl={resumeUrl}
                jobRole={jobRole}
                setPageState={setPageState}
                setClientId={setClientId}
                setTokenId={setTokenId}
                setLoader={setLoader}
                setLoaderMessage={setLoaderMessage}
                setPageError={setPageError}
              />
            )}
            {pageState === JOB_APPLY_PRESCREENING && (
              <PrescreeningPage
                jobRole={jobRole}
                jobId={jobId}
                tokenId={tokenId}
                setLoader={setLoader}
                setAfterPreScreenTrigger={setAfterPreScreenTrigger}
                setLoaderMessage={setLoaderMessage}
                setPageError={setPageError}
              />
            )}
            {pageState === JOB_APPLY_APPLICATION_SUBMITTED && (
              <ApplicationSubmitted jobRole={jobRole} />
            )}
            {pageState === JOB_APPLY_SHORTLISTED && (
              <ApplicationShortlisted
                company={company}
                jobRole={jobRole}
                interviewUrl={interviewUrl}
              />
            )}
          </Layout>
        ))}
    </>
  );
};

export default ApplyForJobView;

ApplyForJobView.propTypes = {
  jobId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
};
