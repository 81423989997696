// TODO: screen recording ui code
import React from 'react';
import PropTypes from 'prop-types';
import PermissionChecksLayout from './layout';

const ScreenRecordingPermissions = ({ askedPermission, permissionAllowed }) => (
  <PermissionChecksLayout
    description="Screen Recording permission is required to record your audio responses."
    title="Screen Recording"
    icon="tabler:device-imac"
    success={permissionAllowed}
    isLoading={!askedPermission}
  />
);
export default ScreenRecordingPermissions;

ScreenRecordingPermissions.propTypes = {
  askedPermission: PropTypes.bool,
  permissionAllowed: PropTypes.bool,
};
