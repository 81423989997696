import { useQuery } from 'src/hooks/use-query';
import { useParams } from 'src/routes/hooks';
import TwoWayInterviewView from 'src/sections/two-way-interview/view';

export default function TwoWayInterviewClientPage() {
  // hooks
  const params = useParams();
  const { id } = params;
  const queryparams = useQuery();
  const tokenId = queryparams.get('token');

  return <TwoWayInterviewView interviewId={id} tokenId={tokenId} />;
}
