import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
import RunInterviewPage from 'src/pages/interview-client/run-interview';
import RunInterviewPage2 from 'src/pages/interview-client2/run-interview';
import StartInterviewPage2 from 'src/pages/interview-client2/start-interview';
import ApplyForJob from 'src/pages/apply-job/apply-for-job';
import PublicInterviewScreening from 'src/pages/interview-screening/interview-screening';
import RedirectPublicPage from 'src/pages/public-pages/redirect-public-page';
import InterviewPage from 'src/pages/interview-client-v3/interview-page';
import Congratulations from 'src/sections/interview-v3/sections/congratulations';
import CodingInterviewClientPage from 'src/pages/coding-interview-client';
import { CodingInterviewProvider } from 'src/sections/coding-interview/context/coding-interview-provider';
import TwoWayInterviewClientPage from 'src/pages/two-way-interview-client';

// ----------------------------------------------------------------------

export const plainRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'interview-client-v1',
        children: [
          { element: <StartInterviewPage2 />, index: true },
          { path: ':id/start', element: <StartInterviewPage2 /> },
          { path: ':id/run', element: <RunInterviewPage /> },
          { path: ':id/run2', element: <RunInterviewPage2 /> },
        ],
      },
      {
        path: 'take-interview',
        children: [
          { element: <StartInterviewPage2 />, index: true },
          { path: ':id', element: <StartInterviewPage2 /> },
        ],
      },
      {
        path: 'interview-client',
        children: [
          { element: <Navigate to=":id" />, index: true },
          {
            path: ':id',
            children: [
              { element: <Navigate to="start" />, index: true },
              { path: 'start', element: <InterviewPage /> },
            ],
          },
        ],
      },
      {
        path: 'coding-interview-client',
        children: [
          { element: <Navigate to=":id" />, index: true },
          {
            path: ':id',
            children: [
              { element: <Navigate to="start" />, index: true },
              {
                path: 'start',
                element: (
                  <CodingInterviewProvider>
                    <CodingInterviewClientPage />
                  </CodingInterviewProvider>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'two-way-interview-client',
        children: [
          { element: <Navigate to=":id" />, index: true },
          {
            path: ':id',
            children: [
              { element: <Navigate to="start" />, index: true },
              { path: 'start', element: <TwoWayInterviewClientPage /> },
            ],
          },
        ],
      },
      {
        path: 'p',
        children: [
          { element: <RedirectPublicPage />, index: true },
          { path: ':id', element: <RedirectPublicPage /> },
        ],
      },
      {
        path: 'job/apply',
        children: [
          { element: <ApplyForJob />, index: true },
          { path: ':orgId/:jobId', element: <ApplyForJob /> },
        ],
      },
      {
        path: 'job/interview-screen',
        children: [
          { element: <PublicInterviewScreening />, index: true },
          { path: ':orgId/:jobId', element: <PublicInterviewScreening /> },
        ],
      },
      {
        path: 'congratulations',
        children: [{ element: <Congratulations />, index: true }],
      },
    ],
  },
];
