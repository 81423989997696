import { Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_APPLY_JOB_APPLICATION_SUBMISSION_PAGE } from 'src/analytics/trackevents';
import animationData from 'src/assets/application-submitted.json'; // Replace with your animation file path

const useStyles = makeStyles(theme => ({
    root: {
        height:"100%",
        minHeight:"60vh",
        [theme.breakpoints.down("sm")]:{
            minHeight:"65vh"
        }
    },
    centerDiv:{
        margin: "auto",
        gap: 16
    },
    jobRole: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]:{
            display:"block"
        }
    },
    content: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontWeight: 700
        }
    },
    description:{
        textAlign:"center",
        color:theme.palette.content.primaryBlack3,
        fontWeight:400
    }
}))

const ApplicationSubmitted = ({ jobRole }) => {
    const classes = useStyles();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    useEffect(() => {
        trackEvent(EVENT_APPLY_JOB_APPLICATION_SUBMISSION_PAGE, {} );
    },[jobRole])

    return (
        <Stack className={classes.root}>
            <Typography variant="subheading1" className={classes.jobRole}>{jobRole}</Typography>
            <Stack className={classes.centerDiv}>
                <Lottie options={defaultOptions} height={100} width={100} />
                <Typography variant="subheading1" className={classes.content}>Application Submitted Successfully</Typography>
                <Typography variant="subtitle1" className={classes.description}>Thank you for your interest. Our team will soon reach out to you with further updates via email.</Typography>
            </Stack>
        </Stack>
    )
}

export default ApplicationSubmitted