import { Stack, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import PropTypes from 'prop-types';
import Webcamera from 'src/sections/coding-interview/components/webcam';

export default function LeftPanel({ question }) {
  const { testCases, text } = question;

  return (
    <Stack spacing="10px">
      <Stack
        width="100%"
        borderRadius="10px"
        border="1px solid #4f4f4f52"
        bgcolor="#202020"
        maxHeight="72vh"
        minHeight="72vh"
        height="72vh"
      >
        <Scrollbar
          sx={{
            height: '100%',
            maxHeight: '72vh',
            overflow: 'auto',
          }}
        >
          {/* Header */}
          <Stack
            direction="row"
            spacing="20px"
            alignItems="center"
            borderBottom="1px solid #4f4f4f52"
            px="12px"
            py="10.5px"
            width="100%"
          >
            <Stack direction="row" spacing="8px" alignItems="center">
              <Iconify icon="tabler:file-text" color="content.primaryWhite" />
              <Typography
                color="content.primaryWhite"
                variant="subheading1"
                fontSize={18}
                fontWeight={500}
              >
                Question
              </Typography>
            </Stack>
          </Stack>

          {/* Question */}
          <Stack
            px="12px"
            py="24px"
            color="content.primaryWhite"
            spacing="24px"
          >
            <Stack spacing="32px">
              <Typography
                variant="paragraph"
                lineHeight="normal"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </Stack>

            {/* Example Test Cases */}
            <Stack spacing="12px">
              <Typography variant="subheading" fontSize={16} fontWeight={600}>
                Example
              </Typography>
              <Stack spacing="6px">
                <Stack direction="row" alignItems="center" spacing="6px">
                  <Typography variant="paragraph" fontWeight={600}>
                    Input:{' '}
                  </Typography>
                  <Typography variant="paragraph">
                    {testCases[0]?.input}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing="6px">
                  <Typography variant="paragraph" fontWeight={600}>
                    Output:{' '}
                  </Typography>
                  <Typography variant="paragraph">
                    {testCases[0]?.output}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Scrollbar>
      </Stack>
      <Stack width="127px" height="89px">
        <Webcamera videoPreview />
      </Stack>
    </Stack>
  );
}

LeftPanel.propTypes = {
  question: PropTypes.object,
};
