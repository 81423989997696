export const boilerplateCodes = [
  {
    language: 'python',
    code: `# Write your code here
# To take input, use the input() function
# user_input = input("Enter something: ")

print("Hello World")`,
  },
  {
    language: 'cpp',
    code: `#include <iostream>
using namespace std;

int main() {
  // Write your code here
  // To take input, use cin
  // int x; 
  // cin >> x;
  
  cout << "Hello World";
  return 0;
}`,
  },
  {
    language: 'java',
    code: `import java.util.Scanner;

public class Main {
  // Write your code here
  public static void main(String []args) {
    // To take input, use Scanner
    // Scanner scanner = new Scanner(System.in);
    // String userInput = scanner.nextLine();
    
    System.out.println("Hello World");
  }
}`,
  },
  {
    language: 'javascript',
    code: `// To take inputs, use process arguments
// Enclose strings in "" for input when running the script
// const inputs = process.argv.slice(2);
// // Iterate over each input argument
// inputs.forEach((input, index) => {
//     // Here, input represents each argument provided in the command line
//     console.log(input);
// });`,
  },
  {
    language: 'c',
    code: `#include <stdio.h>

int main() {
  // Write your code here
  // To take input, use scanf
  // int x;
  // scanf("%d", &x);
  
  printf("Hello World\\n");
  return 0;
}`,
  },
  {
    language: 'ruby',
    code: `# Write your code here
# To take input , use gets.chomp
# user_input = gets.chomp
  
  puts "Hello World"`,
  }
];
