import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import ApplyForJobView from 'src/sections/apply-for-job/view/apply-for-job-view';

// ----------------------------------------------------------------------

export default function ApplyForJob() {
  const params = useParams();
  const { jobId, orgId } = params;

  return (
    <>
      <Helmet>
        <title>HireIntel.AI - Job Application</title>
      </Helmet>
      <ApplyForJobView jobId={jobId} orgId={orgId} />
    </>
  );
}
