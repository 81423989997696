import { Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import PropTypes from 'prop-types';
import { startCodingInterview } from 'src/services/coding-interview.service';
import { INTERVIEW_RUN_PAGE } from '../constants';
import InterviewHeader from '../sections/interview-header';
import useCodingInterviewContext from '../hooks/use-coding-interview-context';

const Timer = ({ setPageState, companyLogo, interviewId, tokenId }) => {
  // hooks
  const {
    setQuestions,
    setLanguages,
    setLanguage,
    setCurrentQuestionIndex,
    setCodingInterviewId,
  } = useCodingInterviewContext();

  // variables
  const time = new Date();
  time.setSeconds(time.getSeconds() + 3);
  const timer = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setPageState(INTERVIEW_RUN_PAGE);
    },
  });

  const startInterview = useCallback(async () => {
    try {
      const response = await startCodingInterview(interviewId, tokenId);

      if (response?.success) {
        const _interviewDetails = {
          id: response?.body?.coding_interview_id,
          languages: response?.body?.coding_languages,
          questions: Object.values(response?.body?.coding_questions).map(
            question => {
              const testCases = question?.input_output_list;
              const text = question?.question_text;
              const timeLimit = question?.time_limit_minutes;

              return {
                testCases,
                text,
                timeLimit,
              };
            },
          ),
        };

        setQuestions(_interviewDetails.questions);
        setLanguages(_interviewDetails.languages);
        setLanguage(_interviewDetails.languages[0]);
        setCurrentQuestionIndex(0);
      } else {
        throw new Error(response?.message);
      }
    } catch (err) {
      console.log('err:', err);
    }
  }, [
    interviewId,
    setCurrentQuestionIndex,
    setLanguage,
    setLanguages,
    setQuestions,
    tokenId,
  ]);

  // effects
  useEffect(() => {
    startInterview();
  }, [startInterview]);

  return (
    <>
      <InterviewHeader companyLogo={companyLogo} />
      <Stack sx={{ height: 'calc(100vh - 80px)' }}>
        <Stack
          sx={{ margin: 'auto' }}
          alignItems="center"
          justifyContent="center"
          spacing="16px"
        >
          <Typography sx={{ fontWeight: 600, fontSize: '40px' }} variant="h1">
            {timer?.seconds}
          </Typography>
          {/* <Typography variant="paragraph">Interview starting...</Typography> */}
        </Stack>
      </Stack>
    </>
  );
};

export default Timer;

Timer.propTypes = {
  setPageState: PropTypes.func.isRequired,
  companyLogo: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
};
