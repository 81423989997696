import { Helmet } from 'react-helmet-async';
import { useQuery } from 'src/hooks/use-query';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import InterviewView from 'src/sections/interview-v3/view/interview-view';

// ----------------------------------------------------------------------

export default function InterviewPage() {
  // hooks
  const params = useParams();
  const { id } = params;
  const queryparams = useQuery();
  const tokenId = queryparams.get('token');

  return (
    <>
      <Helmet>
        <title>HireIntel.AI - AI Interview</title>
      </Helmet>

      <InterviewView interviewId={id} tokenId={tokenId} />
    </>
  );
}
