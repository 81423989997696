import { Stack, Typography } from '@mui/material';
import Animation from 'src/assets/animations/components/animation-wrapper';
import uploadingFilesAnimation from 'src/assets/animations/json/uploading-files.json';
import { useResponsive } from 'src/hooks/use-responsive';

export default function SubmittingPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <Stack
      zIndex={2}
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor="background.primaryWhite1"
    >
      <Stack
        spacing={{ xs: '32px', md: '36px' }}
        alignItems="center"
        justifyItems="center"
      >
        <Animation
          width={mdUp ? 150 : 100}
          height={mdUp ? 150 : 100}
          animationData={uploadingFilesAnimation}
        />
        <Stack
          spacing={{ xs: '12px', md: '16px' }}
          alignItems="center"
          justifyItems="center"
        >
          <Typography variant={mdUp ? 'h2' : 'h4'}>
            Submitting the interview
          </Typography>
          <Typography
            variant={mdUp ? 'paragraph' : 'input'}
            fontWeight={500}
            color="content.primaryBlack3"
          >
            Please wait for a while and do not close the tab.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
