import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fData } from 'src/utils/format-number';
//
import Iconify from '../iconify';
import { varFade } from '../animate';
import FileThumbnail, { fileData } from '../file-thumbnail';
import Image from '../image';
import Scrollbar from '../scrollbar';

// ----------------------------------------------------------------------

export default function MultiFilePreview({ thumbnail, files, onRemove, sx }) {
  return (
    <AnimatePresence initial={false}>
      <Scrollbar sx={{ maxHeight: '213px' }}>
        <Stack spacing="16px" py="20px">
          {files?.map(file => {
            const { key, name = '', size = 0 } = fileData(file);

            console.log(typeof file);

            const isNotFormatFile = typeof file === 'string';

            if (thumbnail) {
              return (
                <Stack
                  key={key}
                  component={m.div}
                  {...varFade().inUp}
                  alignItems="center"
                  display="inline-flex"
                  justifyContent="center"
                  sx={{
                    m: 0.5,
                    width: 80,
                    height: 20,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    ...sx,
                  }}
                >
                  <Iconify width={24} icon="tabler:file" />

                  {onRemove && (
                    <IconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        p: 0.5,
                        top: 4,
                        right: 4,
                        position: 'absolute',
                        color: 'common.white',
                        bgcolor: theme => alpha(theme.palette.grey[900], 0.48),
                        '&:hover': {
                          bgcolor: theme =>
                            alpha(theme.palette.grey[900], 0.72),
                        },
                      }}
                    >
                      <Iconify icon="mingcute:close-line" width={14} />
                    </IconButton>
                  )}
                </Stack>
              );
            }

            return (
              <Stack
                key={key}
                component={m.div}
                {...varFade().inUp}
                direction="row"
                alignItems="center"
                sx={{
                  px: '20px',
                  ...sx,
                }}
              >
                <Iconify width={24} sx={{ mr: '6px' }} icon="tabler:file" />

                <ListItemText
                  primary={isNotFormatFile ? file : name}
                  primaryTypographyProps={{
                    typography: 'input',
                  }}
                  // secondary={isNotFormatFile ? '' : fData(size)}
                  secondaryTypographyProps={{
                    component: 'span',
                    typography: 'caption',
                  }}
                />

                {onRemove && (
                  <IconButton size="small" onClick={() => onRemove(file)}>
                    <Iconify icon="tabler:x" width={24} />
                  </IconButton>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Scrollbar>
    </AnimatePresence>
  );
}

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  sx: PropTypes.object,
  thumbnail: PropTypes.bool,
};
