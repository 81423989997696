import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AudioAnalyser from 'react-audio-analyser';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useStopwatch } from 'react-timer-hook';
import SpeakAnimation from 'src/components/SpeakAnimation';
import { getAudioProps } from 'src/utils/recorder';
import { setLoading } from 'src/store/modalSlice';
import { postEndInterviewData } from 'src/services/interview.service';
import { useQuery } from 'src/hooks/use-query';
import SpeakAnimation2 from 'src/components/SpeakAnimation/SpeakAnimation2';
import { LoadingScreen } from 'src/components/loading-screen';
import { uploadVideoToS3 } from 'src/services/aws.service';
import { useSnackbar } from 'notistack';
// import { useReactMediaRecorder } from "react-media-recorder";
import { putScreenRecordData } from 'src/services/interviewclient.service';
import {
  RecorderErrors,
  useReactMediaRecorder,
} from 'src/hooks/useMediaRecorder';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_INTERVIEW_END_INTERVIEW_CLICKED,
  EVENT_INTERVIEW_NEXT_QUESTION_CLICKED,
  EVENT_SCREEN_RECORDING_NOT_AVAILABLE,
} from 'src/analytics/trackevents';
import WebCam from './web-cam';
import Timer from './Timer';
import classes from './styles.module.css';

const RunInterview2 = ({ interviewId }) => {
  console.log('in run interview page . Interview Id', interviewId);
  let screenRecordingAvailable = !!navigator.mediaDevices.getDisplayMedia;
  console.log('screen record available is ', screenRecordingAvailable);
  let startRecording;
  let stopRecording;
  let previewStream;
  let mediaBlobUrl;
  let error;
  let resumeRecording;
  let pauseRecording;
  let displayMonitor = null;
  const [userStoppedScreenRecording, setUserStopedScreenRecording] =
    useState(false);

  // hooks
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useQuery();
  const SR = params.get('SR');
  console.log('SR -- ', SR);
  if (SR === 'NO') {
    screenRecordingAvailable = false;
  }

  const interviewQuestions = useSelector(state => state.questions.questions);
  console.log('interview questions are ', interviewQuestions);
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch();

  // states
  const [btndisabled, setBtnDisabled] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionId, setQuestionId] = useState(
    interviewQuestions[0].question_id,
  );
  const [complete, setComplete] = useState(false);
  const [status, setStatus] = useState('');
  const [audioSrc, setAudioSrc] = useState(null);
  const [camImageUrls, setCamImageUrls] = useState([]);
  const [screenRecordingLoading, setScreenRecordingLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(
    'Your interview is getting ready ...',
  );
  // variables
  const token_id = params.get('token');
  console.log('token - ', token_id);
  console.log('question id is ', questionId);
  const audioProps = getAudioProps(
    status,
    audioSrc,
    setAudioSrc,
    questionId,
    interviewId,
    questionIndex + 1 === interviewQuestions.length,
    token_id,
    camImageUrls,
    setCamImageUrls,
  );

  // functions
  const controlAudio = _status => {
    setStatus(_status);
  };
  // console.log(status)

  if (screenRecordingAvailable) {
    try {
      const MediaData = useReactMediaRecorder({
        screen: true,
        controlAudio,
        setLoading,
        interviewId,
        token_id,
        setComplete,
        setUserStopedScreenRecording,
      });
      console.log(MediaData);

      startRecording = MediaData.startRecording;
      stopRecording = MediaData.stopRecording;
      previewStream = MediaData.previewStream;
      mediaBlobUrl = MediaData.mediaBlobUrl;
      error = MediaData.error;
      if (
        error === RecorderErrors.NotReadableError ||
        error === RecorderErrors.InvalidAccessError
      ) {
        console.log('error in acquiring', error);
        screenRecordingAvailable = false;
      } else {
        resumeRecording = MediaData.resumeRecording;
        pauseRecording = MediaData.pauseRecording;

        if (previewStream !== null) {
          displayMonitor = previewStream
            .getVideoTracks()[0]
            .getSettings().displaySurface;
        }

        // if (previewStream) {
        //     console.log(previewStream.getVideoTracks()[0])
        //     console.log(previewStream.getVideoTracks()[0].getConstraints())
        // }
        console.log('displayMonitor - ', displayMonitor);
        // if(!displayMonitor)
        // {
        //     screenRecordingAvailable=false;
        // }
      }
    } catch (exception) {
      onsole.log('error getting screen recording ', exception);
      screenRecordingAvailable = false;
    }
  }

  const handleMoveToNextQuestion = async () => {
    try {
      setBtnDisabled(true);
      if (screenRecordingAvailable) pauseRecording();
      controlAudio('inactive');

      setTimeout(() => {
        setQuestionIndex(prev => prev + 1);
        setQuestionId(interviewQuestions[questionIndex + 1].question_id);
        controlAudio('recording');
        setBtnDisabled(false);
      }, 1000);
      trackEvent(EVENT_INTERVIEW_NEXT_QUESTION_CLICKED, {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndInterview = async () => {
    try {
      console.log('end interview called for Id ', interviewId);
      controlAudio('inactive');
      dispatch(setLoading(true));

      postEndInterviewData(interviewId, token_id).then(data => {
        console.log('Images = ', camImageUrls);
        console.log('End interview data = ', data);
        setComplete(true);
      });
      if (screenRecordingAvailable) stopRecording();
      trackEvent(EVENT_INTERVIEW_END_INTERVIEW_CLICKED, {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (screenRecordingAvailable && !screenRecordingLoading && mediaBlobUrl) {
      if (questionIndex + 1 === interviewQuestions.length) {
        setScreenRecordingLoading(true);
        setLoadingText('Please wait, your interview is getting uploaded ...');
      }
      console.log('mediaBlobURl -----', mediaBlobUrl);
      resumeRecording();
      fetch(mediaBlobUrl)
        .then(response => response.blob())
        .then(blob => {
          const newVidBlob = new Blob([blob], { type: 'video/mp4' });
          console.log('screen record Blob --', newVidBlob);
          uploadVideoToS3(newVidBlob).then(vidAwsUrl => {
            console.log(vidAwsUrl);
            const payload = {
              screen_recording_url: vidAwsUrl,
            };
            console.log('questionID ----', questionId);
            putScreenRecordData(
              interviewId,
              payload,
              token_id,
              questionId,
            ).then(res => {
              console.log('screenVidRes -------', res);
              if (res.success) setScreenRecordingLoading(false);
            });
          });
        });
    }
  }, [mediaBlobUrl]);

  // effects
  useEffect(() => {
    if (screenRecordingAvailable) {
      try {
        startRecording()
          .then(data => {
            console.log(
              'data ----------------------------------------------------------------------- ',
              data,
            );
          })
          .catch(err => {
            enqueueSnackbar({
              message:
                'Cannot share screen, please check your screen recording permissions in your browser...',
              variant: 'error',
            });
            console.log(error);
          });
      } catch (error) {
        enqueueSnackbar({
          message:
            'Cannot share screen, please check your screen recording permissions in your browser...',
          variant: 'error',
        });
        console.log(error);
      }
    }
    if (!screenRecordingAvailable) {
      trackEvent(EVENT_SCREEN_RECORDING_NOT_AVAILABLE, {});
    }
  }, [screenRecordingAvailable]);

  useEffect(() => {
    console.log('display monitor changed', displayMonitor);
    if (screenRecordingAvailable && displayMonitor) {
      if (displayMonitor === 'monitor') {
        setScreenRecordingLoading(false);
        enqueueSnackbar({
          message: 'All the best...',
        });
        start();
        controlAudio('');
        setTimeout(() => {
          controlAudio('recording');
          console.log('Starting recording');
        }, 1000);
      } else {
        enqueueSnackbar({
          message: 'Please share the entire screen...',
          variant: 'error',
        });
        stopRecording();
        startRecording()
          .then(data => {
            console.log(
              'dateeeee ----------------------------------------------------------------------- ',
              data,
            );
          })
          .catch(err => {
            enqueueSnackbar({
              message:
                'Cannot share screen, please check the screen recording permissions in your browser...',
              variant: 'error',
            });
            console.log(err);
          });
      }
    }
  }, [displayMonitor]);

  useEffect(() => {
    if (!screenRecordingAvailable) {
      enqueueSnackbar({
        message: 'All the best...',
      });
      start();
      controlAudio('');
      setTimeout(() => {
        controlAudio('recording');
        console.log('Starting recording');
      }, 1000);
    }
  }, [screenRecordingAvailable]);

  useEffect(() => {
    if (screenRecordingAvailable && error == 'permission_denied') {
      enqueueSnackbar({
        message: 'Screen sharing is required for the interview...',
        variant: 'error',
      });
      stopRecording();
      startRecording().then(data => {
        console.log(
          'ttaaaata ----------------------------------------------------------------------- ',
          data,
        );
      });
    }
  }, [error]);

  return (
    <div className={classes.container}>
      {screenRecordingAvailable && screenRecordingLoading === true && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div className={classes.interview_getting_ready}>
            <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
              {loadingText}
            </h2>
            <LoadingScreen />
          </div>
        </div>
      )}
      {((screenRecordingAvailable && screenRecordingLoading === false) ||
        !screenRecordingAvailable) &&
        (complete === true ? (
          userStoppedScreenRecording === true ? (
            <div className={classes.interview_complete_div}>
              <h2
                style={{ color: 'red' }}
                className={classes.interview_complete_text}
              >
                Screen recording was stoped during interview. Interview failed,
                please contact HR team for further updates.
              </h2>
            </div>
          ) : (
            <div className={classes.interview_complete_div}>
              <h2 className={classes.interview_complete_text}>
                Your interview is completed. Please contact HR team for further
                updates.
              </h2>
            </div>
          )
        ) : (
          <>
            <div className={classes.recorder}>
              <AudioAnalyser {...audioProps} />
            </div>

            <div className={classes.questionContainer}>
              {`Q${questionIndex + 1}. ${
                interviewQuestions[questionIndex].text
              }`}
            </div>
            <div className={classes.voice}>
              {/* <SpeakAnimation /> */}
              <SpeakAnimation2 />
              <Timer seconds={seconds} minutes={minutes} />
            </div>
            <div className={classes.question_button}>
              {questionIndex === interviewQuestions.length - 1 ? (
                <Button
                  type="primary"
                  variant="contained"
                  onClick={handleEndInterview}
                  disabled={btndisabled === true}
                >
                  End Interview
                </Button>
              ) : (
                <Button
                  // type={'default'}
                  onClick={handleMoveToNextQuestion}
                  variant="contained"
                  disabled={btndisabled === true}
                >
                  Next Question
                </Button>
              )}
            </div>
            <WebCam
              totalSeconds={totalSeconds}
              setCamImageUrls={setCamImageUrls}
            />
          </>
        ))}
    </div>
  );
};

export default RunInterview2;
