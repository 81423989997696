import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import Logo from 'src/components/logo';
import Image from 'src/components/image';

const Header = () => (
  <Stack
    alignItems="center"
    justifyItems="center"
    borderBottom={theme => `1px solid ${theme.palette.border.primaryBlack3}`}
    position="fixed"
    top="0"
    width="100%"
  >
    <Logo
      sx={{
        width: '100px',
        height: '56px',
      }}
    />
  </Stack>
);

export default function MobileView() {
  return (
    <Stack
      height="100vh"
      alignItems="center"
      justifyContent="center"
      py="16px"
      px="16px"
    >
      <Header />
      <Stack alignItems="center" justifyItems="center" spacing="24px">
        <Box
          borderRadius="34px"
          bgcolor="background.primaryAccent2"
          p="30px"
          height="200px"
          width="200px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src="/assets/illustrations/desktop.svg" alt="desktop" />
        </Box>
        <Typography variant="subheading2" align="center">
          Please use laptop or desktop for coding interview
        </Typography>
      </Stack>
    </Stack>
  );
}
