import { Stack, Typography, Box } from '@mui/material';
import Iconify from 'src/components/iconify';
import Loader from 'src/components/loader';
import PropTypes from 'prop-types';
import { useResponsive } from 'src/hooks/use-responsive';

export default function PermissionChecksLayout({
  icon,
  title,
  description,
  isLoading,
  success,
}) {
  // hooks
  const mdUp = useResponsive('up', 'md');

  return (
    <Stack
      direction="row"
      spacing="18px"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      bgcolor={theme => theme.palette.background.primaryGrey1}
      borderRadius={{ xs: '6px', md: '8px' }}
      padding={{
        xs: '12px 12px',
        md: '16px 12px',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={{ xs: '8px', md: '12px' }}
        width="95%"
      >
        <Box
          sx={{
            width: { xs: '28px', md: '44px' },
            height: { xs: '28px', md: '44px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            border: theme => `1px solid ${theme.palette.border.primaryBlack3}`,
            padding: {
              xs: '4.67px',
              md: '8.8px',
            },
            bgcolor: theme => theme.palette.background.primaryWhite1,
          }}
        >
          <Iconify icon={icon} width={{ xs: '20px', md: '44px' }} />
        </Box>
        <Stack spacing={{ xs: '4px', md: '4px' }}>
          <Typography
            variant={mdUp ? 'subheading2' : 'paragraph'}
            fontWeight={600}
          >
            {title}
          </Typography>
          <Typography
            variant={mdUp ? 'input' : 'caption'}
            color="content.primaryBlack3"
          >
            {description}
          </Typography>
        </Stack>
      </Stack>
      <Stack width="5%">
        {isLoading && <Loader height={24} width={24} />}
        {!isLoading &&
          (success ? (
            <Iconify
              width={24}
              height={24}
              color="background.primaryGreen1"
              icon="tabler:circle-check-filled"
            />
          ) : (
            <Iconify
              width={24}
              height={24}
              color="background.primaryRed1"
              icon="tabler:circle-x-filled"
            />
          ))}
      </Stack>
    </Stack>
  );
}

PermissionChecksLayout.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
};
