import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTimer } from 'react-timer-hook';
import Iconify from 'src/components/iconify';

export const VoiceMessageTimer = () => {
  // states
  const [showVoiceMessage, setShowVoiceMessage] = useState(true);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 5);

  const timer = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setShowVoiceMessage(false);
    },
  });

  return (
    showVoiceMessage && (
      <Stack spacing="16px" alignItems="center">
        <Stack
          width="68px"
          height="68px"
          alignItems="center"
          justifyContent="center"
          border={theme => `1px solid ${theme.palette.border.primaryBlack2}`}
          borderRadius="100%"
        >
          <Iconify width={38} icon="tabler:microphone" />
        </Stack>
        <Typography
          variant="subheading1"
          color="content.primaryBlack1"
          height="32px"
        >
          Please Answer, I am listening
        </Typography>
      </Stack>
    )
  );
};
