import { Stack, Typography } from '@mui/material'
import React from 'react'

const JobNotPublished = () => {
    return (
        <Stack sx={{ height: "55vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4">The job is no longer available...</Typography>
        </Stack>
    )
}

export default JobNotPublished