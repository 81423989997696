import React from 'react';
import styles from "./styles.module.css"

function Timer({seconds,minutes}){
  return (
    <div style={{textAlign: 'center'}}>
      <div className={styles.interview_timer}>
        <span>{minutes < 10 ? "0" + minutes:minutes}</span>:<span>{seconds < 10 ? "0" + seconds:seconds}</span>
      </div>
    </div>
  );
}
export default Timer;