import { createSelector } from 'reselect';

const { createSlice } = require('@reduxjs/toolkit');

const selectProgressQueue = state => state.progressQueue.progressQueue;

const selectProgressById = id =>
  createSelector(
    [selectProgressQueue],
    progressQueue =>
      progressQueue.find(progress => progress.bulkRequestId === id) || null,
  );

const progressQueueSlice = createSlice({
  name: 'progressQueue',
  initialState: {
    progressQueue: [],
    refetchFunction: null,
  },
  reducers: {
    setProgressQueue: (state, action) => {
      state.progressQueue = action.payload;
    },
    updateProgress: (state, action) => {
      const { id, status } = action.payload;

      const progressIndex = state.progressQueue.findIndex(
        item => item.id === id,
      );

      if (progressIndex !== -1) {
        state.progressQueue[progressIndex].status = status;
      }
    },
    onCloseProgressQueue: (state, action) => {
      const currProgressQueue = state.progressQueue;
      const filteredProgressQueue = currProgressQueue.filter(
        item => item.status === '',
      );

      state.progressQueue = [];

      localStorage.setItem(
        `${action.payload.user.user_id}_progressQueue`,
        JSON.stringify(filteredProgressQueue),
      );
    },
    registerRefetchFunction: (state, action) => {
      state.refetchFunction = action.payload;
    },
    clearProgressQueue: state => {
      state.progressQueue = [];
    },
  },
});

const {
  setProgressQueue,
  updateProgress,
  clearProgressQueue,
  onCloseProgressQueue,
  registerRefetchFunction,
} = progressQueueSlice.actions;

export {
  setProgressQueue,
  updateProgress,
  clearProgressQueue,
  onCloseProgressQueue,
  selectProgressById,
  registerRefetchFunction,
};

export default progressQueueSlice.reducer;
