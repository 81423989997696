import { Helmet } from 'react-helmet-async';
// routes
import { useParams } from 'src/routes/hooks';
// sections
import { StartInterviewView2 } from 'src/sections/interview-client/view';
import InterviewView from 'src/sections/interview-client/view/interview-view';

// ----------------------------------------------------------------------

export default function StartInterviewPage2() {
  const params = useParams();

  const { id } = params;
  // console.log(params)

  return (
    <>
      <Helmet>
        <title>HireIntel.AI - Interview Launch Page V2</title>
      </Helmet>

      <InterviewView interview_id={id} />
    </>
  );
}
