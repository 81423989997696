import { Button, Stack, Typography, Box, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Iconify from 'src/components/iconify/iconify';
import PropTypes from 'prop-types';
import Image from 'src/components/image';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
import { isMobile } from 'react-device-detect';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_INTERVIEW_JOB_PAGE,
  EVENT_INTERVIEW_JOB_PAGE_DESKTOP,
  EVENT_INTERVIEW_JOB_PAGE_GET_STARTED,
  EVENT_INTERVIEW_JOB_PAGE_MOBILE,
} from 'src/analytics/trackevents';
import InterviewHeader from '../interview-header';
import BottomDrawer from './drawer';
import CombatibilityCheckModal from './combatibility-check-dialog';
import { INSTRUCTIONS_PAGE } from './constants';
import useBrowserCheck from '../hooks/use-browser-check';
import useOSCheck from '../hooks/use-os-check';

const useStyles = makeStyles(theme => ({
  location: {
    color: theme.palette.content.primaryBlack3,
  },
}));

const JobDetailsPage = ({
  company,
  companyLogo,
  jobRole,
  location,
  candidateName,
  founderMessage,
  setPageState,
}) => {
  // hooks
  const theme = useTheme();
  const classes = useStyles();
  const mdUp = useResponsive('up', 'md');

  // states
  const openCombatibilityCheck = useBoolean(false);
  const isCompatibilityChecking = useBoolean(true);

  const {
    browserCompatible,
    setBrowserCompatible,
    isBrowserCompatibilityChecked,
    isBrowserCompatibilityChecking,
  } = useBrowserCheck({ deviceType: isMobile ? 'mobile' : 'desktop' });

  const {
    osCompatible,
    setOSCompatible,
    isOSCompatibilityChecked,
    isOSCompatibilityChecking,
  } = useOSCheck({ deviceType: isMobile ? 'mobile' : 'desktop' });

  useEffect(() => {
    trackEvent(EVENT_INTERVIEW_JOB_PAGE, {});
    if (isMobile) {
      trackEvent(EVENT_INTERVIEW_JOB_PAGE_MOBILE, {});
    } else {
      trackEvent(EVENT_INTERVIEW_JOB_PAGE_DESKTOP, {});
    }
  }, [isMobile]);

  return (
    // <div>
    //   <h2>Video Recorder</h2>
    //   <main>
    //     <div className="video-controls">
    //       {!permission ? (
    //         <button onClick={getCameraPermission} type="button">
    //           Get Camera
    //         </button>
    //       ) : null}
    //       {permission && recordingStatus === 'inactive' ? (
    //         <button onClick={startRecording} type="button">
    //           Start Recording
    //         </button>
    //       ) : null}
    //       {recordingStatus === 'recording' ? (
    //         <button onClick={stopRecording} type="button">
    //           Stop Recording
    //         </button>
    //       ) : null}
    //     </div>
    //   </main>

    //   <div className="video-player">
    //     {/* {!recordedVideo ? (
    //       <video ref={liveVideoFeed} autoPlay className="live-player"></video>
    //     ) : null} */}
    //     {recordedVideo ? (
    //       <div className="recorded-player">
    //         <video className="recorded" src={recordedVideo} controls></video>
    //         <a download href={recordedVideo}>
    //           Download Recording
    //         </a>
    //       </div>
    //     ) : null}
    //   </div>
    // </div>
    <>
      <InterviewHeader companyLogo={companyLogo} />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'center', md: 'center' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        marginTop={{ xs: '60px', md: '80px' }}
        width="100%"
        paddingX={{
          xs: '16px',
          md: '20px',
        }}
        paddingBottom={{
          xs: '20px',
        }}
        paddingTop={{
          xs: '24px',
        }}
        height={{ xs: 'calc(100vh - 80px - 76px)', md: 'calc(100vh - 80px)' }}
        maxHeight={{
          xs: 'calc(100vh - 80px - 76px)',
          md: 'calc(100vh - 80px)',
        }}
        spacing="22px"
        sx={{
          overflowY: 'auto',
        }}
      >
        <Stack
          sx={{
            width: { xs: 'auto', md: '50%' },
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            src="/assets/illustrations/lady-waving.svg"
            alt="lady-waving"
          />
        </Stack>
        <Stack
          spacing={{ xs: '34px', md: '32px' }}
          width={{ xs: 'auto', md: '50%' }}
        >
          <Stack spacing="8px">
            <Typography variant={mdUp ? 'h3' : 'h4'} fontWeight={600}>
              {jobRole}
            </Typography>
            {/* {location && (
              <Stack direction="row" spacing="6px" alignItems="center">
                <Iconify
                  icon="tabler:map-pin"
                  color={theme.palette.content.primaryBlack3}
                />
                <Typography
                  variant={mdUp ? 'paragraph' : 'input'}
                  className={classes.location}
                >
                  {location}
                </Typography>
              </Stack>
            )} */}
            {company && (
              <Stack direction="row" spacing="6px" alignItems="center">
                <Iconify
                  icon="tabler:briefcase-2"
                  color={theme.palette.content.primaryBlack3}
                />
                <Typography
                  variant={mdUp ? 'paragraph' : 'input'}
                  className={classes.location}
                >
                  {company}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack spacing={{ xs: '10px', md: '12px' }}>
            <Typography variant={mdUp ? 'h2' : 'subheading2'}>
              Hi {candidateName}
            </Typography>
            <Typography
              fontSize={{ xs: 16, md: 20 }}
              color="content.primaryBlack2"
              variant="paragraph"
              fontWeight={400}
              sx={{ lineHeight: 'normal' }}
            >
              Thank you for showing your interest at Hireintel and
              congratulations for being shortlisted for {jobRole}.
            </Typography>
            <Typography
              fontSize={{ xs: 16, md: 20 }}
              color="content.primaryBlack2"
              variant="paragraph"
              fontWeight={400}
              sx={{ lineHeight: 'normal' }}
            >
              I want you to take this AI interview which will help us understand
              your skill set and learn more about you. We look forward to
              meeting you in the next round.
            </Typography>
            <Stack>
              <Typography
                variant="paragraph"
                fontSize={{ xs: 16, md: 16 }}
                color="content.primaryBlack2"
                fontWeight={400}
                sx={{ lineHeight: 'normal' }}
              >
                All the best,
              </Typography>
              <Typography
                variant="paragraph"
                fontSize={{ xs: 16, md: 16 }}
                color="content.primaryBlack2"
                fontWeight={400}
                sx={{ lineHeight: 'normal' }}
              >
                {company}
              </Typography>
            </Stack>
          </Stack>

          {founderMessage && (
            <Stack>
              <Typography variant="subtitle1">
                Founder message to candidate
              </Typography>
              <Stack>message</Stack>
            </Stack>
          )}

          {mdUp && (
            <>
              <Box flexGrow={1} />
              <Stack>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    if (browserCompatible && osCompatible) {
                      setPageState(INSTRUCTIONS_PAGE);
                    } else {
                      openCombatibilityCheck.onTrue();
                    }
                    trackEvent(EVENT_INTERVIEW_JOB_PAGE_GET_STARTED, {});
                  }}
                >
                  Get Started
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {!mdUp && (
        <Stack px="16px" py="20px" width="100%" position="fixed" bottom="0">
          <Button
            fullWidth
            size="medium"
            variant="contained"
            onClick={() => {
              if (browserCompatible && osCompatible) {
                setPageState(INSTRUCTIONS_PAGE);
              } else {
                openCombatibilityCheck.onTrue();
              }
              trackEvent(EVENT_INTERVIEW_JOB_PAGE_GET_STARTED, {});
            }}
          >
            Get Started
          </Button>
        </Stack>
      )}
      {mdUp ? (
        <CombatibilityCheckModal
          setPageState={setPageState}
          open={openCombatibilityCheck.value}
          onClose={openCombatibilityCheck.onFalse}
          browserCompatible={browserCompatible}
          osCompatible={osCompatible}
          isBrowserCompatibilityChecking={isBrowserCompatibilityChecking}
          isOSCompatibilityChecking={isOSCompatibilityChecking}
        />
      ) : (
        <BottomDrawer
          setPageState={setPageState}
          open={openCombatibilityCheck.value}
          setOpen={openCombatibilityCheck.onTrue}
          browserCompatible={browserCompatible}
          isBrowserCompatibilityChecking={isBrowserCompatibilityChecking}
        />
      )}
    </>
  );
};

export default JobDetailsPage;

JobDetailsPage.propTypes = {
  company: PropTypes.string,
  companyLogo: PropTypes.string,
  jobRole: PropTypes.string,
  location: PropTypes.string,
  candidateName: PropTypes.string,
  founderMessage: PropTypes.string,
  setPageState: PropTypes.func,
};
