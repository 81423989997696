const logger = {
    debug: function (message) {
        if (process.env.REACT_APP_ENVIRONMENT === "PREPROD") {
            console.log(message);
        }
    },
    error: function (message) {
        console.log(message);
    }
};

export default logger;