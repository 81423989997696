import { Button, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_APPLY_JOB_SHORTLIST_PAGE, EVENT_APPLY_JOB_TAKE_INTERVIEW } from 'src/analytics/trackevents';

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      minHeight: "80vh"
    }
  },
  mainFirst: {
    gap: 16,
  },
  img: {
    marginBottom: 24,
    width: "100%",
    // height: 305,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 180,
      flexShrink: 0,
    }
  },
  congratulationsDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16
  },
  congratulations: {
    color: theme.palette.content.accent1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  role: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  },
  horizontalLine: {
    width: 754,
    height: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  screeningDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
  },
  nextUp: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  nextUpText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  nextUpTextDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 12,
  },
  btnDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 12,
    width: "100%",
    marginBottom:"2rem"
  },
  linkStyles: {
    textDecoration: 'none',
    color: 'inherit',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  btn: {
    width: 754,
    height: 48,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 44
    }
  }
}))


const ApplicationShortlisted = ({ jobRole, interviewUrl, company }) => {
  const classes = useStyles();
  const navigate = useNavigate();

const handleInterviewClick=async()=>{
  navigate(interviewUrl)
  trackEvent(EVENT_APPLY_JOB_TAKE_INTERVIEW, {});
}
 
useEffect(() => {
    trackEvent(EVENT_APPLY_JOB_SHORTLIST_PAGE, {});
}, [jobRole])

  return (
    <Stack className={classes.main}>
      <Stack className={classes.mainFirst}>
        <img src="/assets/illustrations/apply_shortlisted.png" className={classes.img}></img>
        <Stack className={classes.congratulationsDiv}>
          <Typography variant="h3" className={classes.congratulations}>Congratulations!! you got shortlisted for the next round</Typography>
          <Typography variant="paragraph" className={classes.role}>Role - {jobRole}</Typography>
        </Stack>

        <Stack className={classes.horizontalLine}>
          <Divider orientation="horizontal" flexItem />
        </Stack>

        <Stack className={classes.screeningDiv}>
          <Typography variant="subheading1" className={classes.nextUp}>Next up!! Screening Interview</Typography>
          <Stack className={classes.nextUpTextDiv}>
            <Typography variant="paragraph" className={classes.nextUpText}>Screening interview can be taken any time at your comfort. Please make sure you are in a silent environment. </Typography>
            <Typography variant="paragraph" className={classes.nextUpText}>You can schedule the interview  any time within the next 48 hours. </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack className={classes.btnDiv}>
        <Button onClick={() => handleInterviewClick()} variant="contained" className={classes.btn} size="large">Start Interview</Button>
        <Link className={classes.linkStyles} to={`https://www.google.com/calendar/event?action=TEMPLATE&text=${company}%20${jobRole}%20AI%20Interview%20&location=${process.env.REACT_APP_INTERVIEW_CLIENT_URL + interviewUrl}&details=Here%20is%20interview%20link%20${process.env.REACT_APP_INTERVIEW_CLIENT_URL + interviewUrl}`}>
          <Button variant="outlined" className={classes.btn} size="large">Schedule</Button>
        </Link>
      </Stack>

    </Stack>
  )
}

export default ApplicationShortlisted