import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import PropTypes from 'prop-types';
import useCodingInterviewContext from '../hooks/use-coding-interview-context';

export default function ConfirmSubmitDialog({ open, setOpen }) {
  // hooks
  const { onSubmit, currentQuestionIndex } = useCodingInterviewContext();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
      <DialogContent>
        <Stack spacing="16px" maxWidth="400px">
          <Iconify
            icon="tabler:alert-triangle"
            color="content.primaryRed1"
            width={48}
          />
          <Stack spacing="10px">
            <Typography variant="h4">Do you want to submit code ?</Typography>
            <Typography variant="caption" color="content.primaryBlack3">
              Are you sure you want to submit your code for evaluation? Once
              submitted, you won&apos;t be able to make further changes?
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" spacing="12px" width="100%">
          <Button onClick={() => setOpen(false)} variant="soft" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              onSubmit(currentQuestionIndex);
            }}
            variant="contained"
            fullWidth
          >
            Yes, submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ConfirmSubmitDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
