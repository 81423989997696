import { postData, putData } from 'src/utils/axios/index';
import { urlMap } from 'src/utils/urls';

export const postEvaluateSolution = async (
  solutionData,
  question_id,
  interview_id,
  token_id,
) => {
  console.log("interview id is ",interview_id);
  console.log("question id is ",question_id);
  const url = `${urlMap.evaluateSolution}`
    .replace('{interview-id}', interview_id)
    .replace('{question-id}', question_id);
  console.log('url = ', url);
  return postData(url, solutionData, token_id);
};

export const putScreenRecordData = async (interview_id,screenRecordData,token_id,question_id) => {
  const url = `${urlMap.putScreenRecord}`.replace('{interview-id}', interview_id).replace("{question-id}",question_id);
  return postData(url, screenRecordData, token_id);
};