import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import PropTypes from 'prop-types';
import { postStartInterviewData } from 'src/services/interview.service';
import InterviewHeader from '../interview-header';
import { INTERVIEW_RUN_PAGE } from '../sections/constants';

const Timer = ({
  setPageState,
  companyLogo,
  setInterviewDetails,
  interview_id,
  token_id,
}) => {
  // variables
  const time = new Date();
  time.setSeconds(time.getSeconds() + 3);
  const timer = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setPageState(INTERVIEW_RUN_PAGE);
    },
  });

  // effects
  useEffect(() => {
    postStartInterviewData(interview_id, token_id)
      .then(res => {
        setInterviewDetails(res?.body);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <InterviewHeader companyLogo={companyLogo} />
      <Stack sx={{ height: 'calc(100vh - 80px)' }}>
        <Stack
          sx={{ margin: 'auto' }}
          alignItems="center"
          justifyContent="center"
          spacing="16px"
        >
          <Typography sx={{ fontWeight: 600, fontSize: '40px' }} variant="h1">
            {timer?.seconds}
          </Typography>
          {/* <Typography variant="paragraph">Interview starting...</Typography> */}
        </Stack>
      </Stack>
    </>
  );
};

export default Timer;

Timer.propTypes = {
  setPageState: PropTypes.func.isRequired,
  companyLogo: PropTypes.string.isRequired,
  setInterviewDetails: PropTypes.func.isRequired,
  interview_id: PropTypes.string.isRequired,
  token_id: PropTypes.string.isRequired,
};
