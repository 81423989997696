import { useState } from 'react';

export const INITIAL_AUDIO_STATUS = '';
export const INACTIVE_AUDIO_STATUS = 'inactive';
export const RECORDING_AUDIO_STATUS = 'recording';
export const PAUSED_AUDIO_STATUS = 'paused';

const useAudioAnalyzer = () => {
  // states
  const [audioStatus, setAudioStatus] = useState(INITIAL_AUDIO_STATUS);
  const [currentQuestionAudio, setCurrentQuestionAudio] = useState('');

  // functions
  const updateCurrentQuestionAudio = url => {
    setCurrentQuestionAudio(url);
  };

  return {
    audioStatus,
    setAudioStatus,
    currentQuestionAudio,
    updateCurrentQuestionAudio,
  };
};

export default useAudioAnalyzer;
