import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Image from 'src/components/image';
import SvgColor from 'src/components/svg-color';
import { memo } from 'react';
import StopWatch from '../../components/stop-watch';
import SubmitButton from '../../components/submit-button';

function Header({ companyLogo }) {
  return (
    <Stack
      width="100%"
      height="54px"
      direction="row"
      bgcolor="#000000"
      px="12px"
    >
      {companyLogo && companyLogo != null ? (
        <Image
          sx={{
            width: '120px',
            objectFit: 'contain',
          }}
          src={companyLogo}
          alt="Company Logo"
        />
      ) : null}
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" alignItems="center" spacing="12px">
        <SvgColor
          sx={{
            bgcolor: theme => theme.palette.content.primaryRed1,
            width: '20px',
            height: '20px',
          }}
          src="/assets/icons/interview-page/recording-red.svg"
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          width="110px"
          height="36px"
          bgcolor="#1a1a1a"
          borderRadius="6px"
          color="content.primaryBlack3"
        >
          <StopWatch />
        </Stack>
        <SubmitButton />
      </Stack>
    </Stack>
  );
}

export default memo(Header);

Header.propTypes = {
  companyLogo: PropTypes.string,
};
