import { loadingButtonClasses } from '@mui/lab/LoadingButton';
import Loader from 'src/components/loader';

// ----------------------------------------------------------------------

export function loadingButton(theme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const isSmallSize = ownerState.size === 'small';
          const isLoading = ownerState.loading;

          const defaultStyle = {
            paddingLeft: '12px',
            paddingRight: '12px',
          };

          const startEndIconStyle = {
            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              paddingLeft: '8px',
            },
          };
          const loadingIndicatorStyle = {
            [`& .${loadingButtonClasses.loadingIndicatorStart}`]: {
              left: '-6px',
              paddingLeft: '8px',
            },
            [`& .${loadingButtonClasses.loadingIndicatorEnd}`]: {
              right: '14px',
              position: 'static',
              paddingLeft: '8px',
            },
          };

          return {
            ...defaultStyle,
            paddingTop: '8px',
            paddingBottom: '8px',
            startEndIconStyle,
            ...loadingIndicatorStyle,
          };
        },
      },
      defaultProps: {
        loadingIndicator: <Loader />,
      },
    },
  };
}
