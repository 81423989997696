import React, { useEffect, useState } from 'react';
import { useContext } from 'react';

import {
  Avatar,
  Button,
  Container,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { LoadingScreen } from 'src/components/loading-screen';
import {
  getInterviewDetails,
} from 'src/services/interview.service';
import { InterviewContext } from './view/interview-context';
import { useQuery } from 'src/hooks/use-query';
import { useSettingsContext } from 'src/components/settings';
import classes from './styles.module.css';
import { uploadVideoToS3 } from 'src/services/aws.service';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_EXPIRED, EVENT_INTERVIEW_LOAD_ERROR, EVENT_INTERVIEW_START_BUTTON_CLICKED } from 'src/analytics/trackevents';
import { INTERVIEW_PAGE_PRESCREEN } from './view/interview-view';


const InstructionsPage = ({ setPageState }) => {
  // states
  const [loader, setLoader] = useState(true);

  const [company, setCompany] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [interviewStartError, setInterviewStartError] = useState('');
  const {interviewId,setEnableScreenRecording,setJobTitle,setJobOpeningId } = useContext(InterviewContext);

  
  // hooks
  const params = useQuery();

  // variables
  const tokenId = params.get('token');

  // context
  const settings = useSettingsContext();

  const onStartClick = async () => {
    trackEvent(EVENT_INTERVIEW_START_BUTTON_CLICKED, {});
    setPageState(INTERVIEW_PAGE_PRESCREEN);
  }

  // functions

  // effects
  useEffect(() => {
    const callerFunc = async () => {
      try {
        const res = await getInterviewDetails(interviewId, tokenId);
        console.log(res);
        if (res?.success) {
          setCompany(res.body.org_name);
          setCompanyLogo(res.body.org_logo_url);
          setCandidateName(res.body.candidate_name);
          setJobRole(res.body.job_name);
          if(res.body?.enable_screen_recording){
            setEnableScreenRecording(true);
          }
          setJobOpeningId(res.body.job_opening_id);
        }
        else {
          trackEvent(EVENT_INTERVIEW_EXPIRED, { "error": res.message });
          setInterviewStartError("This interview is expired, please reach out to your HR.")
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        trackEvent(EVENT_INTERVIEW_LOAD_ERROR, { "error": error });
        setInterviewStartError("Error starting the interview.")
        console.log(error);
      }
    };
    callerFunc();
  }, [interviewId, tokenId,setJobTitle,setJobOpeningId,setEnableScreenRecording]);

  return (
    <main className={classes.container}>
      <div className={classes.innerContainer}>
        {loader && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              height: '90%',
            }}
          >
            <div className={classes.interview_getting_ready}>
              <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
                Your interview is getting ready ...
              </h2>
              <LoadingScreen />
            </div>
          </div>
        )}
        {!loader && interviewStartError==='' && (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            maxWidth={settings.themeStretch ? false : 'lg'}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                left: { md: 24 },
                bottom: { md: 24 },
                zIndex: { md: 10 },
                padding: '2rem',
                mb: '1rem',
              }}
            >
              <Avatar
                src={companyLogo}
                // alt={"Company Logo"}
                sx={{
                  mx: 'auto',
                  width: { xs: 64, md: 128 },
                  height: { xs: 64, md: 128 },
                }}
              />
              <ListItemText
                sx={{
                  mt: 3,
                  ml: { md: 3 },
                  textAlign: { xs: 'center', md: 'unset' },
                }}
                primary={company}
                secondary={jobRole}
                primaryTypographyProps={{
                  typography: 'h3',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  color: 'inherit',
                  component: 'span',
                  // typography: 'body1',
                  sx: { opacity: 0.48, fontWeight: 'bold', fontSize: '1rem' },
                }}
              />
            </Stack>
            <Stack spacing={3} className={classes.instructionsDiv}>
              <Typography variant="h6">Dear candidate,</Typography>

              <Typography variant="h6">
                Please follow the instructions below for taking the interview.
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography varient="h6">
                  1. Click the "Start" button when you're ready to begin the interview.
                </Typography>
                <Typography varient="h6">
                  2. Grant permissions for the camera and microphone to record the video.
                </Typography>
                <Typography varient="h6">
                  3. Allow screen sharing to capture the entire screen during your interview.
                </Typography>
                <Typography varient="h6">
                  4. Click the "Next" button to move to the next interview question.
                </Typography>
                <Typography varient="h6">
                  5. Your responses will be automatically recorded since audio recording is enabled throughout the interview.
                </Typography>
              </div>
            </Stack>
            <Button
              type="primary"
              variant="contained"
              sx={{ width: '6rem', margin: 'auto', marginTop: '2.5rem' }}
              onClick={() => onStartClick()}
            >
              Start
            </Button>

          </Container>
        )}
        {interviewStartError !== '' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              height: '90%',
            }}
          >
            <div className={classes.interview_getting_ready}>
              <h2 style={{ textAlign: 'center', marginTop: '0px' }}>
                {interviewStartError}
              </h2>
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default InstructionsPage;
