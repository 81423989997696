// ----------------------------------------------------------------------

export function select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 'auto',
        },
        icon: {
          right: 8,
          width: 16,
          height: 16,
          top: 'calc(50% - 9px)',
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          right: 8,
          width: 16,
          height: 16,
          top: 'calc(50% - 9px)',
        },
      },
    },
  };
}
