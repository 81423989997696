import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

export function EndTimer({ showEndTimer, setShouldEndInterview }) {
  // variables
  const time = new Date();
  time.setSeconds(time.getSeconds() + 5);

  // hooks
  const { seconds: timerSeconds, start: startTimer } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setShouldEndInterview(true);
    },
    autoStart: true,
  });

  // effects
  useEffect(() => {
    if (showEndTimer) {
      startTimer();
    }
  }, [showEndTimer, startTimer]);

  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        sx={{ fontWeight: 600, fontSize: '40px' }}
        variant="h1"
        color="content.primaryBlack1"
      >
        {timerSeconds}
      </Typography>
    </Stack>
  );
}

EndTimer.propTypes = {
  showEndTimer: PropTypes.bool,
  setShouldEndInterview: PropTypes.func,
};
