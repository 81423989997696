import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import Image from 'src/components/image';
import PropTypes from 'prop-types';
import InterviewHeader from '../interview-header';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_CONGRATULATIONS } from 'src/analytics/trackevents';

export default function Congratulations({ companyLogo }) {
  useEffect(() => { 
    trackEvent(EVENT_INTERVIEW_CONGRATULATIONS,{});
  } , []);

  return (
    <>
      <InterviewHeader/>
        <Stack
          spacing="28px"
          alignItems="center"
          height="calc(100vh - 80px)"
          justifyContent="center"
          px={{ xs: '16px', md: '20px' }}
          mt="80px"
          direction={{ xs: 'column', md: 'row' }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            width={{ xs: '100%', md: '50%' }}
          >
            <Image
              sx={{
                width: { xs: '220px', md: '300px' },
              }}
              src="/assets/illustrations/man-holding-trumpet.svg"
            />
          </Stack>
          <Stack spacing="12px" width={{ xs: '100%', md: '50%' }}>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '32px' },
                fontWeight: 600,
                textAlign: { xs: 'center', md: 'start' },
              }}
              color="content.accent1"
            >
            Congratulations!! You have submitted interview successfully
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '20px' },
                fontWeight: 400,
                textAlign: { xs: 'center', md: 'start' },
              }}
              color="content.primaryBlack2"
            >
            You&apos;ll receive further updates in the mail
            </Typography>
          </Stack>
        </Stack>
    </>
  );
}

Congratulations.propTypes = {
  companyLogo: PropTypes.string.isRequired,
};
