import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentOrganization: null,
  organizations: [],
  organizationId: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setCurrentOrganizationInfo: (state, action) => {
      state.currentOrganization = action.payload.organization;
    },
    setOrganizationId: (state, action) => {
      state.organizationId = action.payload;
    },
  },
});

export const {
  setOrganizations,
  setCurrentOrganizationInfo,
  setOrganizationId,
} = organizationSlice.actions;

export default organizationSlice.reducer;
