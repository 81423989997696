import { iconButtonClasses } from '@mui/material/IconButton';

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

export function iconButton(theme) {
  const lightMode = theme.palette.mode === 'light';

  const rootStyles = ownerState => {
    const outlinedVariant = ownerState.variant === 'outlined';

    const outlinedStyle = {
      backgroundColor: theme.palette.background.primaryWhite1,
      border: `1px solid ${theme.palette.border.primaryBlack3}`,
      borderRadius: '6px',
      '&:hover': {
        backgroundColor: theme.palette.background.primaryGrey1,
      },
    };

    const defaultStyle = {
      color: 'currentColor',
      ...(outlinedVariant && outlinedStyle),
    };

    const rippleStyles = {
      [`& .${iconButtonClasses.touch}`]: {
        backgroundColor: theme.palette.background.primaryGrey1,
        ...(outlinedVariant && { borderRadius: '6px' }),
      },
    };

    const colorStyle = COLORS.map(color => ({
      // You can customize styles for specific colors here if needed
    }));

    const disabledState = {
      [`&.${iconButtonClasses.disabled}`]: {
        // Styles for the disabled state
        backgroundColor: 'transparent',
      },
    };

    const typography = {
      // Typography styles can be customized here
    };

    const size = {
      // Size-related styles can be customized here
    };

    return [
      { alignItems: 'center' },
      defaultStyle,
      ...colorStyle,
      disabledState,
      size,
      typography,
      rippleStyles,
    ];
  };

  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => rootStyles(ownerState),
      },
    },
  };
}
