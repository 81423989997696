import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createInterviewLinkPublic } from 'src/services/role.service';
import { trackEvent } from 'src/analytics/datatrack';
import {
  EVENT_APPLY_JOB_EMAIL_ERROR,
  EVENT_APPLY_JOB_EMAIL_SUBMIT,
  EVENT_APPLY_JOB_EMAIL_SUCCESS,
  EVENT_APPLY_JOB_RESUME_PARSE_FAIL_PAGE,
} from 'src/analytics/trackevents';
import { JOB_APPLY_PRESCREENING } from './view/constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  resumeParsingFailed: {
    width: '100%',
    justifyContent: 'center',
  },
  formInsideDiv: {
    gap: 20,
  },
  jobRole: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  detailsDiv: {
    gap: 20,
    width: '100%',
  },
  inputDiv: {
    gap: 16,
  },
  btnDiv: {
    marginTop: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: '2rem',
      width: '92vw',
      margin: 0,
    },
  },
  btn: {
    width: '100%',
  },
}));

const ResumeParsingFailed = ({
  setInterviewUrl,
  setPageState,
  resumeUrl,
  jobId,
  jobRole,
  setClientId,
  setTokenId,
  setLoader,
  setLoaderMessage,
  setPageError,
}) => {
  const classes = useStyles();
  const defaultValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      phonenumber: '',
      email: '',
    }),
    [],
  );

  const ResumeSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phonenumber: Yup.number()
      .required('Phone number is required')
      .typeError('Phone number must be a valid number'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
  });

  const ResumeMethods = useForm({
    resolver: yupResolver(ResumeSchema),
    defaultValues: defaultValues,
  });

  const [methods, setMethods] = useState(ResumeMethods);

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data, event) => {
    console.log(data);
    setLoader(true);
    setLoaderMessage('Submitting...');
    trackEvent(EVENT_APPLY_JOB_EMAIL_SUBMIT, { jobId: jobId });
    const payload = {
      name: data.firstName + ' ' + data.lastName,
      email: data.email,
      phone_no: data.phonenumber,
      resume_url: resumeUrl,
    };
    console.log('payload - ', payload);
    createInterviewLinkPublic(payload, jobId)
      .then(res => {
        console.log(res);
        if (res.success) {
          trackEvent(EVENT_APPLY_JOB_EMAIL_SUCCESS, { jobId: jobId });
          setClientId(res.body.user_id);
          setTokenId(res.body.user_token_id);
          setPageState(JOB_APPLY_PRESCREENING);
          if (res.body.interview_url) {
            setInterviewUrl(res.body.interview_url);
          }
        } else {
          trackEvent(EVENT_APPLY_JOB_EMAIL_ERROR, { jobId: jobId });
        }
        setLoader(false);
      })
      .catch(err => {
        console.log(err);
        trackEvent(EVENT_APPLY_JOB_EMAIL_ERROR, { jobId: jobId });
        setPageError(false);
      });
  });

  useEffect(() => {
     trackEvent(EVENT_APPLY_JOB_RESUME_PARSE_FAIL_PAGE, { });
  }, [jobId])

  return (
    <Stack className={classes.resumeParsingFailed}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack className={classes.formInsideDiv}>
          <Typography variant="h3" className={classes.jobRole}>
            {jobRole}
          </Typography>
          <Stack className={classes.detailsDiv}>
            <Typography variant="subheading1">Basic details</Typography>
            <Stack className={classes.inputDiv}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle1">First name</Typography>
                <RHFTextField name="firstName" />
              </Stack>
              <Stack spacing={0.5}>
                <Typography variant="subtitle1">Last name</Typography>
                <RHFTextField name="lastName" />
              </Stack>
              <Stack spacing={0.5}>
                <Typography variant="subtitle1">Phone number</Typography>
                <RHFTextField name="phonenumber" />
              </Stack>
              <Stack spacing={0.5}>
                <Typography variant="subtitle1">Email address</Typography>
                <RHFTextField name="email" type="email" />
              </Stack>
            </Stack>
            <Stack className={classes.btnDiv}>
              <Button
                className={classes.btn}
                variant="contained"
                type="submit"
                size="large"
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </Stack>
  );
};

export default ResumeParsingFailed;
