import voiceAnimationData from 'src/assets/animations/json/voice.json';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Animation from './animation-wrapper';

export const RecordingWaveAnimation = ({ size = 'medium' }) => {
  const getPadding = () => {
    if (size === 'small') return '5px';
    if (size === 'medium') return '5px';
    if (size === 'large') return '15px';
    return '5px';
  };

  const getWidth = () => {
    if (size === 'small') return 28;
    if (size === 'medium') return 28;
    if (size === 'large') return 60;
    return 28;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: theme =>
          size === 'small'
            ? '#38383875'
            : theme.palette.background.primaryAccent1,
        borderRadius: '50%',
        padding: getPadding(),
        width: getWidth(),
        height: getWidth(),
      }}
    >
      <Animation
        animationData={voiceAnimationData}
        height={null}
        width={null}
      />
    </Box>
  );
};

RecordingWaveAnimation.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
