import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AudioAnalyser from 'react-audio-analyser';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useStopwatch } from 'react-timer-hook';
import SpeakAnimation from 'src/components/SpeakAnimation';
import { getAudioProps } from 'src/utils/recorder';
import { setLoading } from 'src/store/modalSlice';
import { postEndInterviewData } from 'src/services/interview.service';
import { useQuery } from 'src/hooks/use-query';
import SpeakAnimation2 from 'src/components/SpeakAnimation/SpeakAnimation2';
import classes from './styles.module.css';
import Timer from './Timer';
import WebCam from './web-cam';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_END_INTERVIEW_CLICKED, EVENT_INTERVIEW_NEXT_QUESTION_CLICKED } from 'src/analytics/trackevents';

const RunInterview = ({ interviewId }) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useQuery();
  const interviewQuestions = useSelector(state => state.questions.questions);
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({autoStart:true});


  // states
  const [btndisabled, setBtnDisabled] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [complete, setComplete] = useState(false);
  const [status, setStatus] = useState('');
  const [audioSrc, setAudioSrc] = useState(null);
  const [questionId, setQuestionId] = useState(
    interviewQuestions[0].question_id,
  );
  const [camImageUrls, setCamImageUrls] = useState([]);

  // variables
  const token_id = params.get('token');
  const audioProps = getAudioProps(
    status,
    audioSrc,
    setAudioSrc,
    questionId,
    interviewId,
    questionIndex + 1 === interviewQuestions.length,
    token_id,
    camImageUrls,
    setCamImageUrls,
  );

  // functions
  const controlAudio = _status => {
    setStatus(_status);
  };

  const handleMoveToNextQuestion = async () => {
    try {
      setBtnDisabled(true);
      controlAudio('inactive');

      setTimeout(() => {
        setQuestionIndex(prev => prev + 1);
        setQuestionId(interviewQuestions[questionIndex + 1].question_id);
        controlAudio('recording');
        setBtnDisabled(false);
      }, 1000);
      trackEvent(EVENT_INTERVIEW_NEXT_QUESTION_CLICKED,{});
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndInterview = async () => {
    try {
      controlAudio('inactive');
      dispatch(setLoading(true));

      postEndInterviewData(interviewId, token_id).then(data => {
        console.log('Images = ', camImageUrls);
        console.log('End interview data = ', data);
        setComplete(true);
      });
      trackEvent(EVENT_INTERVIEW_END_INTERVIEW_CLICKED,{});
    } catch (error) {
      console.log(error);
    }
  };

  // effects
  useEffect(() => {
    try {        
      controlAudio('recording');
      console.log('Starting recording');
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className={classes.container}>
      {complete && (
        <div className={classes.interview_complete_div}>
          <h2 className={classes.interview_complete_text}>
            Your interview is completed. Please contact HR team for further
            updates.
          </h2>
        </div>
      )}
      {!complete && (
        <>
          <div className={classes.recorder}>
            <AudioAnalyser {...audioProps} />
          </div>

          <div className={classes.questionContainer}>
            {`Q${questionIndex + 1}. ${interviewQuestions[questionIndex].text}`}
          </div>
          <div className={classes.voice}>
            {/* <SpeakAnimation /> */}
            <SpeakAnimation2 />
            <Timer seconds={seconds} minutes={minutes} />
          </div>
          <div className={classes.question_button}>
            {questionIndex === interviewQuestions.length - 1 ? (
              <Button
                type="primary"
                variant="contained"
                onClick={handleEndInterview}
                disabled={btndisabled === true ? true : false}
              >
                End Interview
              </Button>
            ) : (
              <Button
                // type={'default'}
                onClick={handleMoveToNextQuestion}
                variant="contained"
                disabled={btndisabled === true ? true : false}
              >
                Next Question
              </Button>
            )}
          </div>
          <WebCam
            totalSeconds={totalSeconds}
            setCamImageUrls={setCamImageUrls}
          />
        </>
      )}
    </div>
  );
};

export default RunInterview;
