import { useEffect, useState } from 'react';
import { trackEvent } from 'src/analytics/datatrack';
import { EVENT_INTERVIEW_PERMISSION_DENIED } from 'src/analytics/trackevents';

const useCameraMicrophone = ({ audio = true, video = true }) => {
  const [allowed, setAllowed] = useState(null);
  const [errors, setErros] = useState(null);
  const [askedPermission, setAskedPermission] = useState(false);

  useEffect(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
      navigator.getUserMedia(
        {
          audio,
          video,
        },
        stream => {
          console.log('Cam and Mic permissions granted');
          setAllowed(true);
          setAskedPermission(true);
        },
        err => {
          console.log('Premissions denied');
          console.log(err);
          trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, { err: err.message });
          setAllowed(false);
          setErros(true);
          setAskedPermission(true);
        },
      );
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio,
          video,
        })
        .then(stream => {
          console.log('Cam and Mic permissions granted');
          setAllowed(true);
          setAskedPermission(true);
        })
        .catch(err => {
          console.log('Premissions denied');
          console.log(err);
          trackEvent(EVENT_INTERVIEW_PERMISSION_DENIED, { err: err.message });
          setAllowed(false);
          setErros(true);
          setAskedPermission(true);
        });
    }
  }, []);

  return { allowed, errors, askedPermission };
};

export default useCameraMicrophone;
